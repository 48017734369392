import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Footer from "../Footer";
import useModal from "../modal/useModal";
import { HcpWelcomeValSchema } from "../ValidationForm/validationSchema";
import { getSMSText } from "../../common/utilities";
import { ABSOLUTE_URL } from "../../common/constants";
import Loader from "./../../common/Loader";
import smsTemplateService from "../../services/smsTemplateService";
import $ from 'jquery'; // Import jQuery
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import { currentDate } from "../../common/commonFun";
import SupportHeader from "../SupportHeader";
import SupportPatientService from "../../services/SupportPatientService";
import SupportHcpEnrollThankyouModal from "../modal/SupportHcp/SupportHcpEnrollThankyouModal";

const SupportHcpWelcome = () => {
  const form = useRef();
  const supporthcpId = localStorage.getItem("supporthcpId");
  const [loading, setLoading] = useState(false);
  const { isShowing: isShowingThankyou, toggle: toggleThankyou } = useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset, clearErrors
  } = useForm({
    resolver: yupResolver(HcpWelcomeValSchema),
    defaultValues: { supportHcpId: supporthcpId },
  });

  useEffect(() => {
    // Initialize datepicker
    $('#dob').datepicker({
      format: 'dd/mm/yyyy', 
      startView: 2,
      startDate: '01/01/1900', 
      endDate: currentDate(),
      autoclose: true,
      defaultViewDate: { year: 1955, month: 4, day: 25 },
    }).on('changeDate', function (e) {
      const formattedDate = e.format('yyyy-mm-dd');
      setValue("dob", formattedDate); 
      if (errors.dob) {
        clearErrors("dob");
      }
    });

    // Set default value from URL params if available
    const urlParams = new URLSearchParams(window.location.search);
    const birthday = urlParams.get('birthday');
    if (birthday) {
      setValue("dob", birthday);
    }
  }, [setValue, errors, clearErrors]);


  const handleEnroll = async (data) => {
    setLoading(true);
    SupportPatientService.createPatientEnroll(data).then(
      async (response) => {
        setLoading(false);
        reset();
        toggleThankyou();

        const patientDetail = response.data.patientDetail;
        try {
          let insert_link = ABSOLUTE_URL + "support-patient/" + patientDetail.id;

          let smsText = await getSMSText("sms_enrol_support_hcp_patient", [
            { patient_first_name: patientDetail.firstname },
            { insert_link: insert_link },
          ]);

          if (
            smsText !== null &&
            smsText !== undefined &&
            patientDetail.smsEnabled
          ) {
            // smsTemplateService.sendSMS({to:"+918511367064", msg:smsText,userType:"patient",userId:patientDetail.id});
             smsTemplateService.sendSMS({ to: patientDetail.mobile, msg: smsText, userType: "patient", userId: patientDetail.id, });
          }
        } catch (error) {
          console.error("Error while fetching SMS text:", error);
          toast.error("Error while fetching SMS text:", error);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <SupportHcpEnrollThankyouModal
        isShowing={isShowingThankyou}
        hide={toggleThankyou}
      />
      <SupportHeader />
      {loading && <Loader />}
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-uppercase">Patient Details</h1>
              <div className="spacer-2"></div>
            </div>
          </div>
          <div className="row gx-3 justify-content-between">
            <div className="width-lg-53">
              <form
                autoComplete="off"
                className="custom-form patient-support"
                onSubmit={handleSubmit(handleEnroll)}
                ref={form}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      name="firstname"
                      {...register("firstname")}
                    />
                    {errors.firstname && (
                      <span className="text-primary">{errors.firstname.message}</span>
                    )}
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      name="lastname"
                      {...register("lastname")}
                    />
                    {errors.lastname && <span className="text-primary">{errors.lastname.message}</span>}
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row">
                  <div className="width-lg-22 d-flex align-items-center">
                    <label className="form-label mb-md-0 fw-bold">
                      Date of Birth
                    </label>
                  </div>
                  <div className="width-lg-78">
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="dd/mm/yyyy"
                          id="dob"
                          name="dob"
                          readOnly // Ensure the input field is read-only
                          {...register("dob")}
                        />
                      </div>
                      {errors.dob && <span className="text-primary">{errors.dob.message}</span>}
                    </div>
                  </div>
                </div>
                <div className="spacer-2"></div>
                <div className="row">
                  <div className="width-lg-22">
                    <label className="form-label mb-md-0 fw-bold">Gender</label>
                  </div>
                  <div className="width-lg-78">
                    <div>
                      <div className="form-check form-check-inline d-inline-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          name="gender"
                          id="inlineRadio1"
                          value="Male"
                          {...register("gender")}
                        />

                        <label
                          className="form-label fs-16 mb-0 ps-1 text-wrap lh-1"
                          htmlFor="inlineRadio1"
                        >
                          Male
                        </label>
                      </div>
                      <div className="form-check form-check-inline d-inline-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          name="gender"
                          id="inlineRadio2"
                          value="Female"
                          {...register("gender")}
                        />

                        <label
                          className="form-label fs-16 mb-0 ps-1 text-wrap lh-1"
                          htmlFor="inlineRadio2"
                        >
                          Female
                        </label>
                      </div>
                      <div className="form-check form-check-inline d-inline-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="radio"
                          name="gender"
                          id="inlineRadio3"
                          value="other"
                          {...register("gender")}
                        />

                        <label
                          className="form-label fs-16 mb-0 ps-1 text-wrap lh-1"
                          htmlFor="inlineRadio3"
                        >
                          Other
                        </label>
                      </div>
                    </div>
                    {errors.gender && <span className="text-primary">{errors.gender.message}</span>}
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <input
                      type="text"
                      className="form-control fs-18"
                      placeholder="Mobile number"
                      name="mobile"
                      {...register("mobile")}
                    />

                    <div className="form-text text-dark-gray" id="basic-addon4">
                      This can be the patient’s mobile or their support person’s
                      mobile number.{" "}
                    </div>
                    {errors.mobile && <span className="text-primary">{errors.mobile.message}</span>}
                  </div>
                  <div className="col-sm-12 mb-3">
                    <select
                      name="indication"
                      className="form-select drop-blue" 
                      {...register("indication")}
                    >
                      <option value="Physiotherapist" selected> Physiotherapist</option>
                      <option value="Dietician">Dietician</option>
                      <option value="Occupational Therapist">Occupational Therapist</option>
                    </select>
                    {errors.indication && (
                      <span className="text-primary">{errors.indication.message}</span>
                    )}
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="mb-2 fs-18 fw-bold text-dark-gray">
                      Patient Consent
                    </h4>
                    <div className="form-check d-flex align-items-center">
                      <input
                        className="form-check-input mt-0"
                        type="radio"
                        name="Consent"
                        id="Consent"
                        {...register("Consent")}
                      />

                      <label
                        className="form-label fs-16 mb-0 ps-2 text-wrap lh-1"
                        htmlFor="flexRadioDisabled"
                      >
                        I have consent from my patient to provide their personal
                        details and enrol them in the Program
                      </label>
                    </div>
                    {errors.Consent && <span className="text-primary">{errors.Consent.message}</span>}
                    <div className="form-check d-flex align-items-center mt-3">
                      <input
                        className="form-check-input mt-0"
                        type="radio"
                        name="flexRadioDisabled"
                        id="flexRadioCheckedDisabled"
                        {...register("flexRadioDisabled")}
                      />

                      <label
                        className="form-label fs-16 mb-0 ps-2 text-wrap lh-1"
                        htmlFor="flexRadioCheckedDisabled"
                      >
                        I have explained to my patient that the Program runs in conjunction with x treatment. If and when treatment ceases, the support offered by the program ceases 
                      </label>
                    </div>
                    {errors.flexRadioDisabled && (
                      <span className="text-primary">{errors.flexRadioDisabled.message}</span>
                    )}
                  </div>
                </div>
                <div className="spacer-5"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <button className="btn btn-blue btn-default btn-block d-md-inline-block">
                      Enrol
                    </button>
                  </div>
                </div>
                <div className="spacer-5"></div>
              </form>
            </div>
            <div className="width-lg-43">
              <div className="card bg-blue rounded-0 border-0 text-center p-4 h-100 d-flex justify-content-center">
                <p className="mb-0 fs-24 fw-bold text-white lh-sm">
                  Topline overview of the <br /> Program and Benefits
                </p>
              </div>
            </div>
          </div>
        </section>
        <section></section>
      </main>
      <Footer />
    </>
  );
};

export default SupportHcpWelcome;
