import React from "react";
import { Link } from "react-router-dom";
import LogoImg from "../images/logo.svg";
import Footer from "./Footer";

const PageNotFoundComponent = () => {
  return (
    <>
      <header>
        <div className="spacer-5"></div>
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              {/* <img src={LogoImg} className="w-50 img-fluid logo-main" alt="" /> */}

              <Link to="/">
                <img
                  src={LogoImg}
                  className="w-50 img-fluid logo-main"
                  alt=""
                />
              </Link>

              {/* <img
                  src={LogoImg}
                  className="w-50 img-fluid logo-main"
                  alt=""
                /> */}
            </div>
          </div>
        </section>
      </header>
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="card rounded-0 border-0 text-center p-4 h-100 d-flex justify-content-center"
                style={{ minHeight: "300px" }}
              >
                <h1 className="text-uppercase text-dark">404</h1>
                <p className="mb-0 fs-24 fw-bold text-dark lh-sm">
                  Page Not Found
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default PageNotFoundComponent;
