import React, { useEffect, useState } from "react";
import PatientService from "../../services/PatientService";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import LogoInnerImg from "../../images/logo-inner.svg";
import Loader from "./../../common/Loader";

const CoPayPatientVerifyPassword = () => {
  let { tokenId } = useParams();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await PatientService.verifyPatientPassword(tokenId);
        setLoading(false);
        setMessage(response.data.message);
      } catch (error) {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    };

    fetchData();
  }, [tokenId]);

  return (
    <>
      <header className="inner fixed-top">
        <nav className="navbar navbar-expand-lg bg-white">
          <section className="container">
            <div className="navigation d-flex align-items-end w-100">
              <a href="/" className="w-50 logo-inner ms-auto">
                <img src={LogoInnerImg} className="img-fluid" alt="" />
              </a>
            </div>
          </section>
        </nav>
      </header>
      {loading && <Loader />}
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="card bg-teal rounded-0 border-0 text-center p-4 h-100 d-flex justify-content-center"
                style={{ minHeight: "500px" }}
              >
                <p className="mb-0 fs-24 fw-bold text-white lh-sm">
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default CoPayPatientVerifyPassword;
