import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import consumerWebsiteCampaignImage from "../images/consumer-website-campaign.png";
import screenToolImage from "../images/screening-tool-img.jpg";
import clinicalDiscussionImage from "../images/clinician-discussion-img.jpg";
import channelAnalysisImage from "../images/channel-analysis-img.png";
import youtubeImage from "../images/youtube.png";
import metaImage from "../images/meta.png";
import linkedinImage from "../images/linkedin.png";
import googleAdsImage from "../images/google-ads.png";
import metricReportImage from "../images/metric-report.png";
import campignActivationOneImage from "../images/campign-activation-img-1.jpg";
import campignActivationTwoImage from "../images/campign-activation-img-2.jpg";
import campignActivationThreeImage from "../images/campign-activation-img-3.jpg";
import announcementIconImage from "../images/annoucement-icon.svg";
import influenceChartImage from "../images/influence-chart.png";
import paidMediaImage from "../images/paid-media-img.png";
import socialImpressionIconImage from "../images/social-impression-icon.svg";
import videoIconImage from "../images/video-icon.svg";
import chatIconImage from "../images/chat-icon.svg";
import infuluencerIconImage from "../images/influencer-icon.svg";
import messageIconImage from "../images/message-icon.svg";
import infographyStepImage from "../images/infography-step.png";
import communityManagementIconImage from "../images/community-management-icon.svg";

const DirectToConsumerComponent = () => {
  return (
    <>
      <Header />{" "}
      <main className="inner-page">
        <section className="container">
          <div className="row justify-content-center">
            <div className="width-lg-95 text-center">
              <h1 className="text-uppercase">
                Our Integrated Direct to Consumer Formula
              </h1>
              <p className="fs-20 lh-sm">
                At Solutionary Health, we've crafted a formula for direct to
                consumer campaigns that is sophisticated yet straightforward,
                targeted and tailored, and comprehensively monitored and
                measured.
              </p>
              <div className="spacer-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">1</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Consumer Website Underpins the Campaign
                    </h2>
                    <p className="fs-20 lh-sm">
                      Directs targeted consumers to the campaign site for
                      disease education, aligned with your campaign objectives.
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-image webiste-campaign">
                    <img
                      src={consumerWebsiteCampaignImage}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">2</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Targeted Consumer Disease Risk Screening Tool{" "}
                    </h2>
                    <p className="fs-20 lh-sm">
                      Support targeted consumers to assess their disease risk
                      using short and simplified screeners based on validated
                      assessment tools, eg Dermatology Life Quality Index
                      (DLQI).{" "}
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-image">
                    <img src={screenToolImage} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">3</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Support for Consumer and Clinician Discussion
                    </h2>
                    <p className="fs-20 lh-sm">
                      Remove ambiguity for consumers through guided support for
                      discussions with their clinician related to the disease
                      and their individual risk screener results.{" "}
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-image webiste-campaign">
                    <img
                      src={clinicalDiscussionImage}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">4</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Channel Analysis
                    </h2>
                    <p className="fs-20 lh-sm">
                      Assessment and recommendation of effective channels based
                      on your campaign objectives and target audience.
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-image webiste-campaign">
                    <img
                      src={channelAnalysisImage}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">5</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Channel and Consumer Targeting Strategy{" "}
                    </h2>
                    <p className="fs-20 lh-sm">
                      Formulation of channel and consumer targeting strategy,
                      specific to your campaign objectives.
                    </p>
                  </div>
                  <div className="spacer-lg-4"></div>
                  <div className="entry-info">
                    <div className="row gx-lg-7">
                      <div className="width-md-20 text-center border-md-none border-end border-2 py-4 py-lg-0">
                        <div className="entry-icon mb-4">
                          <div className="spacer-2"></div>
                          <img
                            src={youtubeImage}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="entry-content">
                          <p className="mb-0 fs-20 fw-bold lh-sm">
                            Build awareness via video ads
                          </p>
                        </div>
                      </div>
                      <div className="width-md-30 text-center border-md-none border-end border-2 py-4 py-lg-0">
                        <div className="entry-icon mb-4">
                          <div className="spacer-2"></div>
                          <img src={metaImage} className="img-fluid" alt="" />
                        </div>
                        <div className="entry-content">
                          <p className="mb-0 fs-20 fw-bold lh-sm">
                            Drive awareness, website traffic & completed
                            screeners via Facebook & Instagram{" "}
                          </p>
                        </div>
                      </div>
                      <div className="width-md-25 text-center border-md-none border-end border-2 py-4 py-lg-0">
                        <div className="entry-icon mb-4">
                          <div className="spacer-2"></div>
                          <img
                            src={linkedinImage}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="entry-content">
                          <p className="mb-0 fs-20 fw-bold lh-sm">
                            Build awareness & website traffic with known HCPs{" "}
                          </p>
                        </div>
                      </div>
                      <div className="width-md-25 text-center py-4 py-lg-0">
                        <div className="entry-icon mb-4">
                          <img
                            src={googleAdsImage}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="entry-content">
                          <p className="mb-0 fs-20 fw-bold lh-sm">
                            Drive website traffic & completed screener from
                            users searching about the disease state via Search
                            ads
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">6</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm mb-3">
                      Campaign Activation
                    </h2>
                    <h3 className="fs-26 fw-bold lh-sm">Content Development</h3>
                    <p className="fs-20 lh-sm">
                      Develop tailored content to each platform, considering the
                      format, tone, and preferences of users. Utilise a mix of
                      visuals, videos, infographics, and written content to
                      convey information effectively.
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-image">
                    <div className="row gx-2">
                      <div className="col-sm-5">
                        <img
                          src={campignActivationOneImage}
                          className="img-fluid w-100"
                          alt=""
                        />
                      </div>
                      <div className="col">
                        <img
                          src={campignActivationTwoImage}
                          className="img-fluid w-100"
                          alt=""
                        />
                      </div>
                      <div className="col">
                        <img
                          src={campignActivationThreeImage}
                          className="img-fluid w-100"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="entry-title text-center text-lg-start mt-3">
                        <h3 className="fs-26 fw-bold lh-sm">
                          Social Media Amplification
                        </h3>
                      </div>
                      <div className="entry-content d-flex flex-column flex-lg-row align-items-center">
                        <div className="entry-icon announcement d-flex align-items-center my-4 my-lg-0">
                          <img
                            src={announcementIconImage}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <p className="fs-20 lh-sm ps-lg-4 text-center text-lg-start brmedium">
                          Utilise the targeting options provided by social media
                          platforms to reach specific demographics, interests,
                          and behaviours relevant to your campaign. <br />
                          <br />
                          Undertake AB testing of copy and visuals and tweak
                          asset spend to deliver campaign metrics. We do not
                          ‘set and forget’ amplification spend.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-lg-3"></div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="entry-title text-center text-lg-start mt-3">
                        <h3 className="fs-26 fw-bold lh-sm">Influencers</h3>
                        <p className="fs-20 lh-sm">
                          Identify and ensure influencers’ values and content
                          align with your campaign objectives using our
                          identification, scoring and briefing process. Engage
                          and brief influencers.
                        </p>
                      </div>
                    </div>
                    <div className="spacer-3"></div>
                    <div className="entry-image">
                      <img
                        src={influenceChartImage}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="entry-title text-center text-lg-start mt-3">
                        <h3 className="fs-26 fw-bold lh-sm">
                          Paid and Earned Media{" "}
                        </h3>
                        <p className="fs-20 lh-sm">
                          Work alongside PR agencies to generate media
                          opportunities and amplify for organic impact.
                        </p>
                      </div>
                      <div className="spacer-3"></div>
                      <div className="entry-image text-center">
                        <img
                          src={paidMediaImage}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-2 my-5" />
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">7</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Metric Setting
                    </h2>
                    <p className="fs-20 lh-sm">
                      Set goals for each of the targeted activities by channel,
                      inclusive of budget considerations.
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-image">
                    <img src={metricReportImage} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">8</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Metric Reporting (Weekly/Monthly)
                    </h2>
                    <p className="fs-20 lh-sm">
                      Monitor the performance of your campaign and analyse data
                      to optimise results. In-depth end of campaign reports
                      identifies learnings, opportunities and inform
                      recommendations.
                    </p>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="entry-info">
                    <div className="row gx-lg-7">
                      <div className="width-md-35 border-md-none border-end lt-gray border-2">
                        <h2 className="fs-28 fw-bold mb-3 text-orange text-center text-lg-start">
                          REACH
                        </h2>
                        <div className="row gx-3">
                          <div className="col-lg-auto text-center text-lg-start mb-3 mb-lg-0">
                            <img
                              src={socialImpressionIconImage}
                              className="img-fluid media"
                              alt=""
                            />
                          </div>
                          <div className="col-lg d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div className="media-content text-center text-lg-start">
                              <h3 className="fs-26 fw-bold mb-0 text-orange">
                                X
                              </h3>
                              <p className="fs-20 fw-bold lh-sm mb-0">
                                social impressions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="spacer-5"></div>
                        <div className="row gx-3">
                          <div className="col-lg-auto text-center text-lg-start mb-3 mb-lg-0">
                            <img
                              src={videoIconImage}
                              className="img-fluid media"
                              alt=""
                            />
                          </div>
                          <div className="col-lg d-flex flex-column align-items-center align-items-lg-start">
                            <div className="media-content text-center text-lg-start">
                              <h3 className="fs-26 fw-bold mb-0 text-orange">
                                X +
                              </h3>
                              <p className="fs-20 fw-bold lh-sm">
                                30 second video views
                              </p>
                            </div>
                            <div className="media-content text-center text-lg-start">
                              <h3 className="fs-26 fw-bold mb-0 text-orange">
                                X +
                              </h3>
                              <p className="fs-20 fw-bold lh-sm mb-0">
                                video completions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="spacer-5 spacer-lg-0"></div>
                      </div>
                      <div className="width-md-33 border-md-none border-end lt-gray border-2">
                        <h2 className="fs-28 fw-bold mb-3 text-orange text-center text-lg-start">
                          CONVERSATIONS
                        </h2>
                        <div className="row gx-3">
                          <div className="col-lg-auto text-center text-lg-start mb-3 mb-lg-0">
                            <img
                              src={chatIconImage}
                              className="img-fluid media"
                              alt=""
                            />
                          </div>
                          <div className="col-lg d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div className="media-content text-center text-lg-start">
                              <h3 className="fs-26 fw-bold mb-0 text-orange">
                                X
                              </h3>
                              <p className="fs-20 fw-bold lh-sm mb-0">
                                people interacted with the social posts{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="spacer-5"></div>
                        <div className="row gx-3">
                          <div className="col-lg-auto text-center text-lg-start mb-3 mb-lg-0">
                            <img
                              src={infuluencerIconImage}
                              className="img-fluid media"
                              alt=""
                            />
                          </div>
                          <div className="col-lg d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div className="media-content text-center text-lg-start">
                              <h3 className="fs-26 fw-bold mb-0">Influencer</h3>
                              <p className="fs-20 fw-bold lh-sm mb-0">
                                <span className="text-orange">{"<$X"}</span> per
                                positive risk screener
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="spacer-5 spacer-lg-0"></div>
                      </div>
                      <div className="width-md-32">
                        <h2 className="fs-28 fw-bold mb-3 text-orange text-center text-lg-start">
                          CONVERTS
                        </h2>
                        <div className="row gx-3">
                          <div className="col-lg-auto text-center text-lg-start mb-3 mb-lg-0">
                            <img
                              src={messageIconImage}
                              className="img-fluid media-message"
                              alt=""
                            />
                          </div>
                          <div className="col-lg d-flex flex-column align-items-center align-items-lg-start">
                            <div className="media-content text-center text-lg-start mb-0 mb-lg-3">
                              <h3 className="fs-26 fw-bold mb-0 text-orange">
                                X
                              </h3>
                              <p className="fs-20 fw-bold lh-sm mb-0">
                                click SEND MESSAGE
                              </p>
                            </div>
                            <div className="media-content text-center text-lg-start">
                              <h3 className="fs-26 fw-bold mb-0 text-orange">
                                X%
                              </h3>
                              <p className="fs-20 fw-bold lh-sm mb-0">
                                of users click <br /> GET STARTED
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">9</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3">
                    <h2 className="fw-bold text-orange lh-sm">
                      Evaluate Impact
                    </h2>
                    <p className="fs-20 lh-sm mb-0">
                      Assess the overall impact of your campaign by tracking key
                      performance indicators against initial objectives. Measure
                      changes in awareness, perception, and behaviour related to
                      the disease to gauge success and inform future strategies.
                    </p>
                  </div>
                  <div className="spacer-3 spacer-lg-5"></div>
                  <div className="entry-info d-inline-flex">
                    <div className="entry-image infographic-steps d-none d-xl-flex">
                      <img src={infographyStepImage} alt="" />
                    </div>
                    <div className="steps">
                      <div className="spacer-1"></div>
                      <div className="setp step-1 d-flex">
                        <div className="indicator me-1">
                          <span className="number d-flex d-xl-none"></span>
                          <span className="dot"></span>
                        </div>
                        <div className="content">
                          <p className="fs-20 mb-0 lh-sm">
                            Number of people exposed to campaign messaging
                          </p>
                          <p className="mb-0 text-orange fw-bold fs-22 lh-sm">
                            Tracked via website analytics
                          </p>
                        </div>
                      </div>
                      <div className="spacer-5"></div>
                      <div className="setp step-2 d-flex">
                        <div className="indicator me-1">
                          <span className="number d-flex d-xl-none"></span>
                          <span className="dot"></span>
                        </div>
                        <div className="content">
                          <h3 className="fw-bold fs-20 mb-0">
                            Primary KPI (REACH)
                          </h3>
                          <p className="fs-20 mb-0 lh-sm">
                            Number of unique visitors to the website/site link
                            clicks
                          </p>
                          <p className="mb-0 text-orange fw-bold fs-22 lh-sm">
                            Tracked via website analytics
                          </p>
                        </div>
                      </div>
                      <div className="spacer-4"></div>
                      <div className="setp step-3 d-flex">
                        <div className="indicator me-1">
                          <span className="number d-flex d-xl-none"></span>
                          <span className="dot"></span>
                        </div>
                        <div className="content">
                          <h3 className="fw-bold fs-20 mb-0">
                            Primary KPIs (ENGAGE)
                          </h3>
                          <ul className="mb-0 ps-3 lh-sm">
                            <li className="fs-20">
                              Number of unique visitors completing the screener
                            </li>
                            <li className="fs-20">
                              Number of people with a positive screener result
                            </li>
                            <li className="fs-20">
                              Conversions = Downloads / Print / Email
                            </li>
                          </ul>
                          <p className="mb-0 text-orange fw-bold fs-22 lh-sm">
                            Tracked via website analytics
                          </p>
                        </div>
                      </div>
                      <div className="spacer-4"></div>
                      <div className="setp step-4 d-flex">
                        <div className="indicator me-1">
                          <span className="number d-flex d-xl-none"></span>
                          <span className="dot"></span>
                        </div>
                        <div className="content">
                          <h3 className="fw-bold fs-20 mb-0">
                            Secondary KPIs (ACT)
                          </h3>
                          <ul className="mb-0 ps-3 lh-sm">
                            <li className="fs-20">
                              Number of people visiting a GP
                            </li>
                            <li className="fs-20">
                              Number of people referred to a Specialist
                            </li>
                          </ul>
                          <p className="mb-0 text-orange fw-bold fs-22 lh-sm">
                            Tracked via assumptions and research
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="border-2 my-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="entry-number fs-35">10</div>
                <div className="entry-content ps-lg-3">
                  <div className="entry-title text-center text-lg-start mt-3 mb-4">
                    <h2 className="fw-bold text-orange lh-sm">
                      Community Management Moderation and Engagement
                    </h2>
                  </div>
                  <div className="entry-info d-flex flex-column flex-lg-row align-items-center">
                    <div className="entry-icon management my-4 my-lg-0">
                      <img
                        src={communityManagementIconImage}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <p className="fs-20 lh-sm ps-lg-4 text-center text-lg-start brmedium mb-0">
                      Daily monitoring and code compliant moderation of visitor
                      comments and reporting of any adverse events and other
                      safety findings. Resolving comments and questions with
                      sponsor approved comments as appropriate and leveraging
                      any opportunity to engage with your audience to create a
                      positive interaction.
                    </p>
                  </div>
                </div>
              </div>
              <div className="spacer-8"></div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default DirectToConsumerComponent;
