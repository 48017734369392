// Loader.js
import React from "react";

const Loader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-ring"
        style={{
          display: "block",
          margin: "auto",
          width: "40px",
          height: "40px",
        }}
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          strokeLinecap="round"
          r="40"
          strokeWidth="4"
          stroke="#1a1a1a"
          strokeDasharray="62.83185307179586 62.83185307179586"
          transform="rotate(284.271 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  </div>
);

export default Loader;
