import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import { useEffect, useRef, useState } from "react";
import { formatDate, stateOptions } from "../../../common/commonFun";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import IqviaService from "../../../services/IqviaService";
import { AdminInvoceEntrySchema } from "../../ValidationForm/validationSchema";
import AdminService from "../../../services/AdminService";
import { toast } from "react-toastify";
import { useToast } from "../../../common/ToastContext";
import Loader from "../../../common/Loader";
import SupportInvoiceService from "../../../services/SupportInvoiceService";

const AdminPharmacyInvoiceEntryComponent = () => {
  const navigate = useNavigate();
  const supportPatientId = localStorage.getItem("adminSearchSupportPatientId");

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await AdminService.adminGetSupportPatientDetails(supportPatientId);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };

  //alert("hvhb");
  const invoiceDetail = localStorage.getItem("pharmacyInvoiceDetail");
    const form = useRef();
  const [loading, setLoading] = useState(false);
  const [invoiceData] = useState(JSON.parse(invoiceDetail));
  const [pharmacySuburbOptions, setPharmacySuburbOptions] = useState([]);
  const [doctorSuburbOptions, setDoctorSuburbOptions] = useState([]);
  const [pharmacyNameOptions, setPharmacyNameOptions] = useState([]);
  const [doctorNameOptions, setDoctorNameOptions] = useState([]);
  const [selectedTypeDoctor] = useState('Physician');
  const [selectedTypePharmacy] = useState('Pharmacist');
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors }, setValue,
    reset,
    watch,
  } = useForm({ resolver: yupResolver(AdminInvoceEntrySchema) });
  const selectedPharmacyState = watch("pharmacyState");
  const selectedDoctorState = watch("doctorState");
  const selectedPharmacysuburb = watch("pharmacySuburb");
  const selectedDoctorsuburb = watch("doctorSuburb");

  const findByStatePharmacy = (value) => {
    setLoading(true);
    setPharmacySuburbOptions([]);
    IqviaService.findByState({ State: value, selectedType: selectedTypePharmacy }).then(
      (response) => {
        setLoading(false);
        setPharmacySuburbOptions(response.data.Suburbs);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  const findByStateDoctor = (value) => {
    setLoading(true);
    setDoctorSuburbOptions([]);
    IqviaService.findByState({ State: value, selectedType: selectedTypeDoctor }).then(
      (response) => {
        setLoading(false);
        setDoctorSuburbOptions(response.data.Suburbs);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  const selectedSuburbPharmacy = (value) => {
    setLoading(true);
    setPharmacyNameOptions([]);
    IqviaService.findBySuburb({ City: value }).then(
      (response) => {
        setLoading(false);
        setPharmacyNameOptions(response.data.Names);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  const selectedSuburbDoctor = (value) => {
    setLoading(true);
    setDoctorNameOptions([]);
    IqviaService.findBySuburb({ City: value }).then(
      (response) => {
        setLoading(false);
        setDoctorNameOptions(response.data.Names);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };
  useEffect(() => {
    if (selectedPharmacyState) {
      setValue('pharmacySuburb', '')
      setValue('pharmacyName', '')
      findByStatePharmacy(selectedPharmacyState);
    }
  }, [selectedPharmacyState]);

  useEffect(() => {
    if (selectedDoctorState) {
      setValue('doctorSuburb', '')
      setValue('doctorName', '')
      findByStateDoctor(selectedDoctorState);
    }
  }, [selectedDoctorState]);

  useEffect(() => {
    if (selectedPharmacysuburb) {
      selectedSuburbPharmacy(selectedPharmacysuburb);
    }
  }, [selectedPharmacysuburb]);

  useEffect(() => {
    if (selectedDoctorsuburb) {
      selectedSuburbDoctor(selectedDoctorsuburb);
    }
  }, [selectedDoctorsuburb]);

  const handleInvoice = async (data) => {
    data.isSubmitStatus = true;
    data.id = invoiceData.id;
    setLoading(true);

    SupportInvoiceService.updatePharmacy(data).then(
      (response) => {
        reset();
        setLoading(false);
        showToast(response.data.message);
        navigate("/solutionaryhub-admin-support-portal/patient-dashboard");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <AdminHeader />
      {loading && <Loader />}
      {invoiceData ? (
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1 className="text-uppercase">PHARMACY INVOICE ENTRY</h1>
              </div>
            </div>
            <div className="spacer-3 spacer-lg-6"></div>

            <form
              className="custom-form patient-support"
              autoComplete="off"
              onSubmit={handleSubmit(handleInvoice)}
              ref={form}
            >
              <div className="row gx-lg-5">
                <div className="col-md-6 d-flex  align-items-center mb-2 mb-lg-0">
                  <label htmlFor="" className="form-label me-2 mb-0">
                    Invoice Id:
                  </label>
                  <p className="text-dark-gray fw-bold mb-0">
                    {" "}
                    {invoiceData.id}
                  </p>
                </div>
                <div className="col-md-6 d-flex flex-column flex-lg-row align-items-lg-center">
                  <label htmlFor="" className="form-label me-2 mb-md-0">
                    Click to view invoice:
                  </label>
                  <a
                    href={invoiceData.filename}
                    target="_blank" 
                    className="btn btn-default btn-blue btn-block d-md-inline-block"
                  >
                    show
                  </a>
                  <div className="col-sm-12 text-center text-md-start"></div>
                </div>
              </div>
              <div className="spacer-3 spacer-lg-2"></div>
              <div className="row gx-lg-5">
                <div className="col-md-6 d-flex  align-items-center mb-2 mb-lg-0">
                  <label htmlFor="" className="form-label me-2 mb-0">
                    Submission Date:
                  </label>
                  <p className="text-dark-gray fw-bold mb-0">
                    {" "}
                    {formatDate(invoiceData.invoiceDate)}
                  </p>
                </div>
                <div className="col-md-6 d-flex flex-column flex-lg-row align-items-lg-center validated-input">
                  <label htmlFor="" className="form-label me-2 mb-md-0">
                    Supply Date:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="supplyDate"
                    id="supplyDate"
                    {...register("supplyDate")}
                  />
                  {errors.supplyDate && (
                    <span>{errors.supplyDate.message}</span>
                  )}
                </div>
              </div>
              <div className="spacer-3 spacer-lg-2"></div>
              <div className="row gx-lg-5">
                <div className="col-md-6 mb-4 mb-lg-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="fs-22 mb-3">Pharmacy</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <select
                        name="pharmacyState"
                        id="pharmacyState"
                        className="form-select drop-blue"
                        {...register("pharmacyState")}
                      >
                        <option value="">Select State / Territory</option>
                        {stateOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors.pharmacyState && (
                        <span>{errors.pharmacyState.message}</span>
                      )}
                    </div>
                    <div className="spacer-1"></div>
                    <div className="col-sm-12">
                      <select
                        name="pharmacySuburb"
                        id="pharmacySuburb"
                        className="form-select drop-blue"
                        {...register("pharmacySuburb")}
                      >
                        <option value="">Select Suburb</option>
                        {pharmacySuburbOptions.length !== 0
                          ? pharmacySuburbOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                          : ""}
                      </select>
                      {errors.pharmacySuburb && (
                        <span>{errors.pharmacySuburb.message}</span>
                      )}
                    </div>
                    <div className="spacer-1"></div>
                    <div className="col-sm-12">
                      <select
                        name="pharmacyName"
                        id="pharmacyName"
                        className="form-select drop-blue"
                        {...register("pharmacyName")}
                      >
                        <option value="">Select Name</option>
                        {pharmacyNameOptions.length !== 0
                          ? pharmacyNameOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                          : ""}
                      </select>
                      {errors.pharmacyName && (
                        <span>{errors.pharmacyName.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="isProduct"
                          id="isProduct"
                          {...register("isProduct")}
                        />
                        <label
                          className="form-check-label ps-2"
                          htmlFor="isProduct"
                        >
                          Product
                        </label>
                      </div>
                      {errors.isProduct && (
                        <span>{errors.isProduct.message}</span>
                      )}
                    </div>
                    <div className="spacer-2"></div>
                    <div className="col-sm-12">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="isPharmacistSignature"
                          id="isPharmacistSignature"
                          {...register("isPharmacistSignature")}
                        />
                        <label
                          className="form-check-label ps-2"
                          htmlFor="isPharmacistSignature"
                        >
                          Pharmacist Signature
                        </label>
                      </div>
                      {errors.isPharmacistSignature && (
                        <span>{errors.isPharmacistSignature.message}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="fs-22 mb-3">Doctor</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <select
                        name="doctorState"
                        id="doctorState"
                        className="form-select drop-blue"
                        {...register("doctorState")}
                      >
                        <option value="">Select State / Territory</option>
                        {stateOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors.doctorState && (
                        <span>{errors.doctorState.message}</span>
                      )}
                    </div>
                    <div className="spacer-1"></div>
                    <div className="col-sm-12">
                      <select
                        name="doctorSuburb"
                        id="doctorSuburb"
                        className="form-select drop-blue"
                        {...register("doctorSuburb")}
                      >
                        <option value="">Select Suburb</option>
                        {doctorSuburbOptions.length !== 0
                          ? doctorSuburbOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                          : ""}
                      </select>
                      {errors.doctorSuburb && (
                        <span>{errors.doctorSuburb.message}</span>
                      )}
                    </div>
                    <div className="spacer-1"></div>
                    <div className="col-sm-12">
                      <select
                        name="doctorName"
                        id="doctorName"
                        className="form-select drop-blue"
                        {...register("doctorName")}
                      >
                        <option value="">Select Name</option>
                        {doctorNameOptions.length !== 0
                          ? doctorNameOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                          : ""}
                      </select>
                      {errors.doctorName && (
                        <span>{errors.doctorName.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="row">
                    <div className="col-sm-12 d-flex align-items-center validated-input">
                      <label htmlFor="" className="form-label me-2 mb-0">
                        Amount paid:
                      </label>
                      <div className="position-relative w-100 amount-paid">
                        <input
                          type="text"
                          className="form-control ps-4"
                          name="amount"
                          id="amount"
                          {...register("amount")}
                        />
                        {errors.amount && <span>{errors.amount.message}</span>}
                        <span className="position-absolute start-0 top-0 py-2 px-3 text-dark-gray">
                          $
                        </span>
                      </div>
                    </div>
                   
                  </div>
                  <div className="spacer-6"></div>
                  <div className="row">
                    <div className="col-sm-12 text-center text-md-start">
                      <button className="btn btn-default btn-blue btn-block d-md-inline-block">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </main>
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

export default AdminPharmacyInvoiceEntryComponent;
