import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRouteAdmin = ({ children }) => {
  const isAuthenticated = localStorage.getItem("adminAccessToken");
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/solutionaryhub-admin-portal" replace />
  );
};

export default PrivateRouteAdmin;
