import React, { useEffect } from "react";
import LogoImg from "../images/logo.svg";
import Footer from "./Footer";
import Chatbot from "./CardComponent/Chatbot";
import Copay from "./CardComponent/Copay";
import Samples from "./CardComponent/Samples";
import PatientSupport from "./CardComponent/PatientSupport";
import DirectToConsumer from "./CardComponent/DirectToConsumer";
import { useNavigate } from "react-router-dom";

const LandingComponent = () => {
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("accessToken") && localStorage.getItem("solHubPageToRedirect")) {
      // navigate("/co-pay-welcome");
      navigate(localStorage.getItem("solHubPageToRedirect"));
    }

    if (localStorage.getItem("copayPatientToken")) {
      navigate("/co-pay-patient-dashboard");
    }
  }, []);
  return (
    <>
      <header>
        <div className="spacer-5"></div>
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <img src={LogoImg} className="w-50 img-fluid logo-main" alt="" />
            </div>
          </div>
        </section>
        <div className="spacer-2"></div>
      </header>
      <main className="front-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="text-uppercase">
                Welcome to the <br /> Solutionary Health Hub
              </h1>
              <div className="spacer-3"></div>
            </div>
          </div>
          <div className="spacer-2"></div>
          <div className="row gx-3">
            <div className="col-xl col-md-4 mb-3 mb-xl-0">
              <Chatbot />
            </div>
            <div className="col-xl col-md-4 mb-3 mb-xl-0">
              <Copay />
            </div>
            <div className="col-xl col-md-4 mb-3 mb-xl-0">
              <Samples />
            </div>
            <div className="col-xl col-md-4 mb-3 mb-xl-0">
              <PatientSupport />
            </div>
            <div className="col-xl col-md-4 mb-3 mb-xl-0">
              <DirectToConsumer />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default LandingComponent;
