import React from "react";

import CloseBtnImg from "../../../images/Close_Btn.svg";

const SupportMessageModal = ({ isShowing, hide,message }) => {
  return (
    <>
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="thankYou"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-blue border-0 rounded-0">
            <div className="modal-body">
              <div className="spacer-15"></div>
              <span
                type="button"
                className="position-absolute end-0 top-0 m-4"
                onClick={() => hide()}
              >
                <img src={CloseBtnImg} alt="" />
              </span>
              <div className="row justify-content-center">
                <div className="col-sm-9 text-center">
                  <h2 className="fw-bold text-white text-center">{message}
                  </h2>
                  <div className="spacer-7"></div>
                  <span
                    className="btn btn-outline-white btn-default fs-22"
                    onClick={() => hide()}
                  >
                    Done
                  </span>
                </div>
              </div>
              <div className="spacer-15"></div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};
export default SupportMessageModal;
