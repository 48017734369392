// import React, { useState, useEffect, createContext, useCallback } from "react";
// import { jwtDecode } from "jwt-decode";
// import { useNavigate } from "react-router-dom";
// import GlobalLoginModal from "../components/modal/GlobalLoginModal";
// import useModal from "../components/modal/useModal";

// export const AuthContext = createContext();

// function AuthProvider({ children }) {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   const { isShowing, toggle } = useModal();
//   const [currentTokenName, setCurrentTokenName] = useState(null);

//   const removeToken = useCallback((tokenName) => {
//     localStorage.removeItem(tokenName);

//     switch (tokenName) {
//       case "adminAccessToken":
//         localStorage.removeItem("adminId");
//         localStorage.removeItem("adminSearchPatientId");
//         localStorage.removeItem("copayHistoryDetail");
//         localStorage.removeItem("invoiceDetail");
//         break;
//       case "copayhcpToken":
//         localStorage.removeItem("copayhcpId");
//         localStorage.removeItem("copayhcpEmail");
//         break;
//       case "copayPatientToken":
//         localStorage.removeItem("copayPatientMobile");
//         localStorage.removeItem("copayPatientId");
//         break;
//       case "accessToken":
//         break;
//       default:
//         break;
//     }
//     toggle();
//   }, [toggle]);

//   const checkTokenExpiry = useCallback(() => {
//     const tokenNames = [
//       "adminAccessToken",
//       "copayhcpToken",
//       "copayPatientToken",
//       "accessToken",
//     ];

//     let token;
//     let i;

//     for (i = 0; i < tokenNames.length; i++) {
//       token = localStorage.getItem(tokenNames[i]);
//       if (token) break;
//     }

//     if (token) {
//       const decoded = jwtDecode(token);
//       const currentTime = Date.now() / 1000;

//       if (decoded.exp < currentTime) {
//         setIsLoggedIn(false);
//         localStorage.removeItem(tokenNames[i]);
//         setCurrentTokenName(tokenNames[i]);
//         removeToken(tokenNames[i]);

//         if (tokenNames[i] === "copayPatientToken") {
//           navigate("/");
//         }
//       } else {
//         setIsLoggedIn(true);
//         const timeout = (decoded.exp - currentTime) * 1000;
//         const logoutTimer = setTimeout(() => {
//           setIsLoggedIn(false);
//           setCurrentTokenName(tokenNames[i]);
//           localStorage.removeItem(tokenNames[i]);
//           removeToken(tokenNames[i]);

//           if (tokenNames[i] === "copayPatientToken") {
//             navigate("/");
//           }
//         }, timeout);

//         return () => clearTimeout(logoutTimer);
//       }
//     } else {
//       setIsLoggedIn(false);
//     }
//   }, [toggle, navigate, removeToken]);

//   useEffect(() => {
//     checkTokenExpiry();
//   }, [checkTokenExpiry]);

//   const login = (tokenName, token) => {
//     localStorage.setItem(tokenName, token);
//     setCurrentTokenName(tokenName);
//     setIsLoggedIn(true);
//   };

//   const logout = () => {
//     const tokenNames = [
//       "adminAccessToken",
//       "copayhcpToken",
//       "copayPatientToken",
//       "accessToken",
//     ];
//     tokenNames.forEach((tokenName) => {
//       localStorage.removeItem(tokenName);
//       removeToken(tokenName);
//     });

//     setIsLoggedIn(false);
//     navigate("/");
//   };

//   return (
//     <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
//       {children}
//       <GlobalLoginModal
//         isShowing={isShowing}
//         hide={toggle}
//         tokenName={currentTokenName}
//       />
//     </AuthContext.Provider>
//   );
// }

// export default AuthProvider;
import React, { useState, useEffect, createContext, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import GlobalLoginModal from "../components/modal/GlobalLoginModal";
import SessionExpiredModal from "../components/modal/SessionExpiredModal";
import useModal from "../components/modal/useModal";

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { isShowing, toggle } = useModal();
  const [currentTokenName, setCurrentTokenName] = useState(null);
  const [currentSolHubPage, setCurrentSolHubPage] = useState(null);
  const [currentSolHubPageToRedirect, setCurrentSolHubPageToRedirect] = useState(null);
  
  
  const removeToken = useCallback((tokenName) => {
    localStorage.removeItem(tokenName);

    switch (tokenName) {
      case "adminAccessToken":
        localStorage.removeItem("adminId");
        localStorage.removeItem("adminSearchPatientId");
        localStorage.removeItem("copayHistoryDetail");
        localStorage.removeItem("invoiceDetail");
        toggle();
        break;
      case "copayhcpToken":
        localStorage.removeItem("copayhcpId");
        localStorage.removeItem("copayhcpEmail");
        toggle();
        break;
      case "supporthcpToken":
        localStorage.removeItem("supporthcpId");
        localStorage.removeItem("supporthcpEmail");
        toggle();
        break;
      case "copayPatientToken":
        localStorage.removeItem("copayPatientMobile");
        localStorage.removeItem("copayHcp");
        localStorage.removeItem("copayPatientId");
        toggle();
        break;
      case "supportPatientToken":
        localStorage.removeItem("supportPatientMobile");
        localStorage.removeItem("supportHcp")
        localStorage.removeItem("supportPatientId");
        toggle();
        break;
      case "accessToken":        
        localStorage.removeItem("solHubUser");
        localStorage.removeItem("solHubPage");
        localStorage.removeItem("solHubPageToRedirect");
        toggle();
        break;
      default:
        break;
    }
    
  }, [toggle]);

  const checkTokenExpiry = useCallback(() => {
    const tokenNames = [
      "adminAccessToken",
      "copayhcpToken",
      "supporthcpToken",
      "copayPatientToken",
      "supportPatientToken",
      "accessToken",
    ];

    const tokenName = tokenNames.find(name => localStorage.getItem(name));
    const token = localStorage.getItem(tokenName);

    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        setIsLoggedIn(false);
        localStorage.removeItem(tokenName);        
        if(localStorage.getItem('solHubPageToRedirect'))
        {        
          setCurrentSolHubPageToRedirect(localStorage.getItem('solHubPageToRedirect'));  
        }
        if(localStorage.getItem('solHubPage'))
        {        
          setCurrentSolHubPage(localStorage.getItem('solHubPage'));  
        }
        
        setCurrentTokenName(tokenName);
        removeToken(tokenName);

        if (tokenName === "copayPatientToken") {
          //navigate("/");
        }
      } else {
        setIsLoggedIn(true);
        const timeout = (decoded.exp - currentTime) * 1000;
        const logoutTimer = setTimeout(() => {
          setIsLoggedIn(false);
          if(localStorage.getItem('solHubPageToRedirect'))
          {        
            setCurrentSolHubPageToRedirect(localStorage.getItem('solHubPageToRedirect'));  
          }
          if(localStorage.getItem('solHubPage'))
          {        
            setCurrentSolHubPage(localStorage.getItem('solHubPage'));  
          }
          setCurrentTokenName(tokenName);
          localStorage.removeItem(tokenName);
          removeToken(tokenName);

          if (tokenName === "copayPatientToken") {
            //navigate("/");
          }
        }, timeout);

        return () => clearTimeout(logoutTimer);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [toggle, navigate, removeToken]);

  useEffect(() => {
    checkTokenExpiry();
  }, [checkTokenExpiry]);

  const login = (tokenName, token) => {
    localStorage.setItem(tokenName, token);
    setCurrentTokenName(tokenName);
    if(localStorage.getItem('solHubPageToRedirect'))
    {        
      setCurrentSolHubPageToRedirect(localStorage.getItem('solHubPageToRedirect'));  
    }
    if(localStorage.getItem('solHubPage'))
    {        
      setCurrentSolHubPage(localStorage.getItem('solHubPage'));  
    }
    setIsLoggedIn(true);
  };

  const logout = () => {
    const tokenNames = [
      "adminAccessToken",
      "copayhcpToken",
      "supporthcpToken",
      "copayPatientToken",
      "supportPatientToken",
      "accessToken",
    ];
    tokenNames.forEach((tokenName) => {
      localStorage.removeItem(tokenName);
      removeToken(tokenName);
    });

    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
      {/* <GlobalLoginModal
        isShowing={isShowing}
        hide={toggle}
        tokenName={currentTokenName}
        solHubPageToRedirect={currentSolHubPageToRedirect}
        solHubPage={currentSolHubPage}
      /> */}
      <SessionExpiredModal
        isShowing={isShowing}
        hide={toggle}
       
      />
    </AuthContext.Provider>
  );
}

export default AuthProvider;
