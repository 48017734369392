import { Link } from "react-router-dom";
import LogoInnerImg from "../images/logo-inner.svg";

const AdminHeader = () => {
  return (
    <>
      <header className="inner fixed-top">
        <nav className="navbar navbar-expand-lg bg-white">
          <section className="container">
            <div className="navigation d-flex align-items-end w-100">
              <Link
                className="w-50 logo-inner ms-auto"
                to="/solutionaryhub-admin-portal/choice"
              >
                <img src={LogoInnerImg} className="img-fluid" alt="" />
              </Link>
            </div>
          </section>
        </nav>
      </header>
    </>
  );
};
export default AdminHeader;
