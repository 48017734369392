import React from "react";
import { useNavigate } from "react-router-dom";
import CloseBtnImg from "../../images/Close_Btn.svg";

const SessionExpiredModal = ({ isShowing, hide}) => {
  let navigate = useNavigate();

  return (
    <>
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="login"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-primary border-0 rounded-0">
            <div className="modal-body">
              <div className="spacer-12"></div>
              <span
                type="button"
                className="position-absolute end-0 top-0 m-4"
                onClick={() => {
                  hide(); // Hide the modal
                  navigate("/"); // Redirect to "/"
                }}
              >
                <img src={CloseBtnImg} alt="" />
              </span>
              <div className="row justify-content-center">
                <div className="col-sm-9">
                  
                <h2 id="session-expired-label" className="text-center text-white">
                    Your session has been expired!
                  </h2>
                    <div className="row">
                      <div className="spacer-4"></div>
                      <div className="col-sm-6">
                        
                      </div>
                      <div className="col-12 text-center"> {/* Center the button */}
                        <button
                          className="btn btn-outline-white btn-default fs-22 btn-block d-md-inline-block"
                          onClick={() => {
                            hide(); // Hide the modal
                            navigate("/"); // Redirect to "/"
                          }}
                        >
                          OK
                        </button>
                      </div>
                                            
                    </div>
                  
                </div>
              </div>
              <div className="spacer-10"></div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};
export default SessionExpiredModal;
