import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import AdminService from "../../../services/AdminService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AdminAEReportSchema } from "../../ValidationForm/validationSchema";
import { toast } from "react-toastify";
import { useToast } from "../../../common/ToastContext";
import Loader from "../../../common/Loader";

const AdminSupportAEReportComponent = () => {
  const form = useRef();
  const patientId = localStorage.getItem("adminSearchSupportPatientId");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState({});
  const { showToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { supportPatientId: patientId },
    resolver: yupResolver(AdminAEReportSchema),
  });

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await AdminService.adminGetSupportPatientDetails(patientId);
      setPatientData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };

  const handleAddAEReport = (data) => {
    setLoading(true);

    AdminService.adminAddSupportAEReport(data).then(
      (response) => {
        setLoading(false);
        showToast(response.data.message);
        reset();
        navigate("/solutionaryhub-admin-support-portal/patient-overview");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        reset();
        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <AdminHeader />
      {loading && <Loader />}
      {patientData && patientData.supportHcp ? (
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1 className="text-uppercase">Enter AE Report</h1>
              </div>
            </div>
            <div className="spacer-3 spacer-lg-6"></div>
            <form
              action=""
              className="custom-form"
              autoComplete="off"
              onSubmit={handleSubmit(handleAddAEReport)}
              ref={form}
            >
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-6">
                          <label
                            htmlFor=""
                            className="form-label mb-0 fw-bold text-blue"
                          >
                            Patient Id
                          </label>
                        </div>
                        <div className="col-6">
                          <p className="fw-bold text-blue mb-0">
                            {patientData.id}
                          </p>
                        </div>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="" className="form-label mb-0">
                            Name:
                          </label>
                        </div>
                        <div className="col-6">
                          <p className="mb-0 fs-18 text-dark-gray">
                            {patientData.firstname} {patientData.lastname}
                          </p>
                        </div>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="" className="form-label mb-0">
                            Mobile:
                          </label>
                        </div>
                        <div className="col-6">
                          <p className="mb-0 fs-18 text-dark-gray">
                            {patientData.mobile}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-6">
                          <label
                            htmlFor=""
                            className="form-label mb-0 fw-bold text-blue"
                          >
                            AE Reported
                          </label>
                        </div>
                        <div className="col-sm-6"></div>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="" className="form-label mb-md-0">
                            Date:
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="date"
                            className="form-control"
                            autoComplete="false"
                            name="date"
                            {...register("date")}
                          />
                          {errors.date && <span>{errors.date.message}</span>}
                        </div>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="" className="form-label mb-md-0">
                            Source:
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <select
                            className="form-select drop-blue"
                            name="source"
                            {...register("source")}
                          >
                            <option value="">Select Source</option>
                            <option value="nurse phone call">
                              Nurse Phone Call
                            </option>
                          </select>
                          {errors.source && (
                            <span>{errors.source.message}</span>
                          )}
                        </div>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="row">
                        <div className="col-sm-6">
                          <label htmlFor="" className="form-label mb-md-0">
                            Report Number:
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="10-xx-xxXxxx"
                            autoComplete="false"
                            name="reportNumber"
                            {...register("reportNumber")}
                          />
                          {errors.reportNumber && (
                            <span>{errors.reportNumber.message}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-6"></div>
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <button className="btn btn-blue btn-default btn-block w-100">
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <Link
                        className="btn btn-outline-gray btn-default btn-block"
                        to="/solutionaryhub-admin-support-portal/patient-overview"
                      >
                        Close
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </main>
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

export default AdminSupportAEReportComponent;
