import axios from "axios";

const login = data => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/auth/signin", data);
};

const logout = () => {
  localStorage.removeItem("accessToken");
};

const getAll = () => {
  return axios.get(process.env.REACT_APP_API_URL + "/api/tutorials");
};

const get = id => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/tutorials/${id}`);
};

const create = data => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/tutorials", data);
};

const update = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/tutorials/${id}`, data);
};

const remove = id => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/tutorials/${id}`);
};

const removeAll = () => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/tutorials`);
};

const findByTitle = title => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/tutorials?title=${title}`);
};

const CoPayService = {
  login,
  logout,
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle
};

export default CoPayService;