import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children,page }) => {

  if(page === false)
  {
    return  <Navigate to="/" replace />
  }
  else {
    const isAuthenticated = localStorage.getItem("accessToken");
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
  }
    


    
};

export default PrivateRoute;
