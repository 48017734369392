import authHeader from "./auth-header";
import axios from "axios";

const login = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/copay/hcp-signin", data);
};

const logout = () => {
  localStorage.removeItem("copayhcpToken");
  localStorage.removeItem("copayhcpId");
};

const getAll = () => {
  return axios.get(process.env.REACT_APP_API_URL + "/api/tutorials");
};

const get = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/tutorials/${id}`);
};

const create = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/copay/hcp-signup", data);
};

// Auth Token
const update = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/copay/hcp-update/${id}`, data, {
    headers: authHeader(),
  });
};

const remove = (id) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/tutorials/${id}`);
};

const removeAll = () => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/tutorials`);
};

const findByTitle = (title) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/tutorials?title=${title}`);
};

const HcpService = {
  login,
  logout,
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default HcpService;
