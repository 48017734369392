
import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState('');

  const showToast = (message) => {
    setToastMessage(message);
  };

  return (
    <ToastContext.Provider value={{ showToast ,toastMessage}}>
      {children}
      {/* Render ToastContainer here if you want it to be global */}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
