import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="mt-auto">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyrite text-center mb-4 mt-4">
                <p className="mb-0 fs-18 lh-sm">
                  Commercial in Confidence. <br /> © {currentYear} Solutionary
                  Health Pty Ltd. 79/89 Jones Street, Ultimo NSW 2007 Australia.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};
export default Footer;
