import authHeader from "./auth-header";
import axios from "axios";

const getEmail = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/email/getEmail`,data,{ headers: authHeader() });
};
const sendEmail = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/email/sendEmail`,data,{ headers: authHeader() });
};
const emailService = {
  getEmail,
  sendEmail
};

export default emailService;
