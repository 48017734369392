import React, { useState } from "react";
import useModal from "../../modal/useModal";
import CloseBtnImg from "../../../images/Close_Btn.svg";
import InvoiceIcon from "../../../images/invoice-icon-new.png";
import PharmacyInvoiceThankyouModal from "../../modal/Patient/PharmacyInvoiceThankyouModal";
import { MAX_FILE_SIZE } from "../../../common/constants";
import CopayHistoryService from "../../../services/CopayHistoryService";
import { toast } from "react-toastify";
import Loader from "./../../../common/Loader";
import axios from "axios";
import { getEmailText } from "../../../common/utilities";
import emailService from "../../../services/EmailService";

const PharmacyInvoiceModal = ({ isShowing, hide }) => {
  const { isShowing: isShowingThankyou, toggle: toggleThankyou } = useModal();
  const [droppedFile, setDroppedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const copayPatientId = localStorage.getItem("copayPatientId");
  const copayPatientMobile = localStorage.getItem("copayPatientMobile");
  const copayHcp = JSON.parse(localStorage.getItem("copayHcp"));
  // const [uploadProgress, setUploadProgress] = useState(0);
  const handleSubmit = async(e) => {
    e.preventDefault();

    
    if (!droppedFile) {
      toast.error("Please select a file.",{ autoClose: false, closeOnClick: false});
      return;
    }

    try {
      setLoading(true);
      const fileType = droppedFile.type.split('/')[1];
      
      await CopayHistoryService.PreSign({ fileType:fileType ,patientId:copayPatientId}).then(async(response)=>{
      const { presignedUrl } = response.data;
   
      const uploadPromise = axios.put(presignedUrl, droppedFile, {
      headers: {'Content-Type': fileType === 'pdf' ? 'application/pdf': fileType},
      onUploadProgress: (progressEvent) => {
         Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setUploadProgress(progress);
      }
      });

        if( await uploadPromise){
           let emailText = await getEmailText('email_copay_invoice_upload',[{patinet_mobile:copayPatientMobile}])
            if (emailText) {
              emailService.sendEmail({from:'noreply@solutionaryhub.com.au',to:copayHcp.email, subject:emailText.subject,html:emailText.html,emailId:emailText.id});
            }
            hide()
            setDroppedFile(null);
            toggleThankyou();
            setLoading(false);
      }
      })
     
    } catch (err) {
      console.error('Error uploading file', err);
    }

   
    // formData.append("file", droppedFile);
    // formData.append("patientId", copayPatientId);

    // setLoading(true);
    // CopayHistoryService.uploadInvoice(formData).then(
    //   (response) => {
    //     hide();
    //     setDroppedFile(null);
    //     toggleThankyou();
    //     setLoading(false);
    //   },
    //   (error) => {
    //     const resMessage =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();

    //     setLoading(false);
    //     toast.error(resMessage);
    //   }
    // );
  };

  const handleDrop = (e) => {
    const files = e.dataTransfer.files;

    if (files.length > 1) {
      toast.error("Please select only one file.",{ autoClose: false, closeOnClick: false});
      return;
    }

    const file = files[0];
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    // Allowed file types
    const allowedFileTypes = ['png', 'jpeg', 'jpg', 'pdf', 'gif'];

    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error("Invalid file type. Only PNG, JPEG, PDF, and GIF files are allowed.",{ autoClose: false, closeOnClick: false});
      return;
    }

    if (file.size <= MAX_FILE_SIZE) {
      setDroppedFile(file);
    } else {
      toast.error(
        `File "${file.name}" exceeds the maximum allowed size of 20 MB.`,{ autoClose: false, closeOnClick: false}
      );
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    handleDrop({ dataTransfer: { files: [file] } });
  };

  return (
    <>
      <PharmacyInvoiceThankyouModal
        isShowing={isShowingThankyou}
        hide={toggleThankyou}
      />
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="uploadInvoice"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-primary border-0 rounded-0">
            {loading && <Loader />}
            <div className="modal-body">
              <div className="spacer-5"></div>
              <span
                type="button"
                className="position-absolute end-0 top-0 m-4"
                onClick={() => hide()}
              >
                <img src={CloseBtnImg} alt="" />
              </span>
              <div className="row justify-content-center">
                <div className="col-sm-9">
                  <h2 className="fw-bold text-white text-center">
                    Please upload a clear photo or scan of your pharmacy invoice
                  </h2>

                  <div className="spacer-3"></div>
                  <div
                    className="row"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <div className="col-sm-12 text-center">
                      <div className="drag-area">
                        <img
                          src={InvoiceIcon}
                          className="img-fluid"
                          alt=""
                          onClick={handleIconClick}
                        />
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer-3"></div>
              <div className="row justify-content-center">
                <div className="col-sm-9 text-center">
                  <div className="entry-action d-flex flex-column text-center">
                    <span className="mb-0 lh-sm fs-18">
                      Drag and drop a file here or click the icon to upload.
                      <br />
                      Maximum size of file: 20 MB. File types accepted: pdf,
                      jpeg, gif, png
                    </span>
                  </div>
                  <div className="fs-18">
                    {droppedFile && "Filename : "+ droppedFile.name}
                  </div>
                  <div className="spacer-5"></div>
                  <span
                    className="btn btn-outline-white btn-default fs-22"
                    onClick={handleSubmit}
                  >
                    Submit Invoice
                  </span>
                </div>
              </div>
              <div className="spacer-5"></div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};

export default PharmacyInvoiceModal;
