import React from "react";
import CloseBtnImg from "../../../images/Close_Btn.svg";

const SupportPatientContactThankyouModal = ({ isShowing, hide }) => {
  return (
    <>
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="login"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-blue border-0 rounded-0">
            <div className="modal-body">
              <div className="spacer-15"></div>

              <span
                type="button"
                className="position-absolute end-0 top-0 m-4"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => hide()}
              >
                <img src={CloseBtnImg} alt="" />
              </span>
              <div className="row justify-content-center">
                <div className="col-sm-9 text-center">
                  <h2 className="fw-bold text-white text-center">
                    Thank you. we will be in touch within 2 business days.
                  </h2>
                  <div className="spacer-7"></div>
                  <span
                    className="btn btn-outline-white btn-default fs-22 text-uppercase rounded-2"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default behavior of the anchor tag
                      hide(); // Call the hide function
                    }}
                  >
                    Done
                  </span>
                </div>
              </div>
              <div className="spacer-15"></div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};

export default SupportPatientContactThankyouModal;
