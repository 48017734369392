import React from "react";
import PatientSsupportIconImg from "../../images/patient-support-icon.png";
import CoPayLoginModal from "../modal/CoPayLoginModal";
import useModal from "../modal/useModal";

const PatientSupport = () => {
  const { isShowing, toggle } = useModal();
  return (
    <>
      <CoPayLoginModal isShowing={isShowing} hide={toggle} pageToRedirect="/patient-support-welcome" page="isPatientSupport" />
      <div className="card bg-blue rounded-0 border-0 text-center p-4">
        <div className="entry-title">
          <img
            src={PatientSsupportIconImg}
            alt=""
            className="img-fluid entry-icon"
          />
          <h3 className="fw-bold text-white lh-1 mt-2">
            Patient <br />
            Support
          </h3>
        </div>
        <div className="entry-btn mt-auto">
          <a
            className="btn btn-outline-white btn-default fs-18 text-uppercase"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            href="#"
          >
            Enter
          </a>
        </div>
      </div>
    </>
  );
};
export default PatientSupport;
