import React, { useEffect, useState } from "react";
import useModal from "../modal/useModal";
import Footer from "../Footer";
import addIcon from "../../images/add-icon.png";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";
import SupportPatientHeader from "../SupportPatientHeader";
import SupportPharmacyInvoiceModal from "../modal/SupportPatient/SupportPharmacyInvoiceModal";
import SupportInvoiceService from "../../services/SupportInvoiceService";
import moment from 'moment';

const SupportPatientDashboard = () => {
  const { isShowing, toggle } = useModal();
  const [loading, setLoading] = useState(false);
  const [pharmacyInvoiceData, setPharmacyInvoiceData] = useState([]);
  const [alliedInvoiceData, setAlliedInvoiceData] = useState([]);
  const supportPatientId = localStorage.getItem("supportPatientId");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const pharmacyInvoiceDetailResponse =
          await SupportInvoiceService.getSupportInvoiceDetails(supportPatientId);
        if (pharmacyInvoiceDetailResponse.data) {
          setPharmacyInvoiceData(pharmacyInvoiceDetailResponse.data.pharmacyInvoiceData);
          setAlliedInvoiceData(pharmacyInvoiceDetailResponse.data.alliedInvoiceData);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    };

    fetchData();
  }, [supportPatientId]);

  return (
    <>
      <SupportPharmacyInvoiceModal isShowing={isShowing} hide={toggle} />

      <SupportPatientHeader />
      {loading && <Loader />}

      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-uppercase mb-3">ALLIED HEALTH SERVICE HISTORY</h1>
            </div>

            <div className="col-sm-12">
              <div className="table-padding">
                <table className="table table-bordered text-center table-style-2 text-nowrap">
                  <thead className="table-blue">
                    <tr>
                      <th className="text-white lh-sm" valign="middle">
                        Number<br/> &nbsp;
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Invoice<br/> Submitted
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Allied Health <br/>Type
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Practitioner<br/> Name
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Practitioner<br/> Suburb
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Amount<br/> &nbsp;
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Rebate<br/> Amount
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Rebate<br/> Paid
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {alliedInvoiceData.length !== 0 ? (
                      alliedInvoiceData.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.invoiceDate && moment(item.invoiceDate).format('DD/MM/YYYY')}</td>
                            <td>{item.alliedHealthType}</td>
                            <td>{item.practitionerName}</td>
                            <td>{item.practitionerSuburb}</td>
                            <td>${item.amount}</td>
                            <td>{item.rebateAmount !== null?'$' +item.rebateAmount:'N/A'}</td>
                            <td>{item.rebatePaid ? moment(item.rebatePaid).format('DD/MM/YYYY'):'N/A'}</td>
                          </tr>
                        ) 
                      })
                    ) : (
                      <tr>
                        <td className="text-center" valign="middle" colSpan={8}>
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>


            <div className="col-sm-12 pt-5">
              <h1 className="text-uppercase mb-3">PHARMACY HISTORY</h1>
            </div>

            <div className="col-sm-12">
              <div className="table-padding">
                <table className="table table-bordered text-center table-style-2 text-nowrap">
                  <thead className="table-blue">
                    <tr>
                      <th className="text-white lh-sm" valign="middle">
                        Number<br/> &nbsp;
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Invoice<br/> Submitted
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Pharmacy <br/>Name
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Pharmacy <br/>Suburb
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Amount<br/> &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pharmacyInvoiceData.length !== 0 ? (
                      pharmacyInvoiceData.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.invoiceDate && moment(item.invoiceDate).format('DD/MM/YYYY')}</td>
                            <td>{item.pharmacyName}</td>
                            <td>
                              {item.pharmacyState}{" "}
                              {item.pharmacySuburb}
                            </td>
                            <td>${item.amount}</td>
                          </tr>
                        ) 
                      })
                    ) : (
                      <tr>
                        <td className="text-center" valign="middle" colSpan={8}>
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>


            <div className="col-sm-12">
              <div className="btn-group mt-5"
                 onClick={toggle} >
                <span className="add-btn rounded-2 rounded-end-0 bg-blue">
                  <img src={addIcon} alt="" />
                </span>
                <span
                  className="btn btn-outline-blue btn-default text-wrap">
                  {" "}
                  UPLOAD ALLIED HEALTH SERVICE AND PHARMACY INVOICE
                </span>
              </div>
            </div>
          </div>
          
        </section>
      </main>
      <Footer />
    </>
  );
};

export default SupportPatientDashboard;
