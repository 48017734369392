import React, { useEffect, useState } from 'react'
import LogoInnerImg from "../../images/logo-inner.svg";
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import CopayHistoryService from '../../services/CopayHistoryService';
import { toast } from 'react-toastify';
import Loader from '../../common/Loader';
import { CSVLink } from "react-csv";
import moment from 'moment';
const CoPayDashboard = () => {
    const [loading, setLoading] = useState(false);
    const [copayData, setCopayData] = useState([]);


    // const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10 ; // Change this value to adjust the number of items per page



    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const copayHistoryWithPatient = await CopayHistoryService.getCopayHistoryWithPatientData();
                if (copayHistoryWithPatient.data.data.length === 0) {
                    toast.success(copayHistoryWithPatient.data.message);
                } else {
                    setCopayData(copayHistoryWithPatient.data.data);
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error(error);
            }
        };

        fetchData();
    }, []);
    // Calculate the current items to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = copayData.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

   const CsvHeaders = [
        { label: "Patient ID", key: "patientId" },
        { label: "Date Enrolled", key: "DateEnrolled" },
        { label: "Patient DOB", key: "dob" },
        { label: "Patient Gender", key: "gender" },
        { label: "HCP Name", key: "hcpName" },
        { label: "HCP Suburb", key: "hcpSuburb" },
        { label: "HCP State", key: "hcpState" },
        { label: "Pharmacy Name", key: "pharmacyName" },
        { label: "Pharmacy Suburb", key: "pharmacySuburb" },
        { label: "Pharmacy State", key: "pharmacyState" },
        { label: "Invoice ID", key: "invoiceID" },
        { label: "Invoice Date", key: "invoiceDate" },
        { label: "Invoice Amount", key: "invoiceAmount" },
        { label: "Co-Pay Paid", key: "copayPaid" },
      ];
      
    return (
        <>
            {loading && <Loader />}
            <header className="inner fixed-top">
                <nav className="navbar navbar-expand-lg bg-white">
                    <section className="container">
                        <div className="navigation d-flex align-items-end w-100">
                            <div className="w-50 logo-inner ms-auto">
                                <img src={LogoInnerImg} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </section>
                </nav>
            </header>
            <main className="inner-page">
                <section className="container">
                    <div className="row gx-3 justify-content-between">
                        <div className="width-lg-100">
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <h1 className="text-uppercase">Solutionary Health Co-Pay and Private Market Dashboard</h1>
                                </div>
                            </div>
                            <div className="spacer-3"></div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-padding">
                                        <table className="table table-bordered text-center table-style-2 text-nowrap">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th className="text-white lh-sm" valign="middle">Patient<br/> ID</th>
                                                    <th className="text-white lh-sm" valign="middle">Date<br/> Enrolled</th>
                                                    <th className="text-white lh-sm" valign="middle">Patient<br/> DOB</th>
                                                    <th className="text-white lh-sm" valign="middle">Patient <br/>Gender</th>
                                                    <th className="text-white lh-sm" valign="middle">HCP <br/>Name</th>
                                                    <th className="text-white lh-sm" valign="middle">HCP<br/> Suburb</th>
                                                    <th className="text-white lh-sm" valign="middle">HCP<br/> State</th>
                                                    <th className="text-white lh-sm" valign="middle">Pharmacy <br/>Name</th>
                                                    <th className="text-white lh-sm" valign="middle">Pharmacy <br/>Suburb</th>
                                                    <th className="text-white lh-sm" valign="middle">Pharmacy <br/>State</th>
                                                    <th className="text-white lh-sm" valign="middle">Invoice <br/>ID</th>
                                                    <th className="text-white lh-sm" valign="middle">Invoice<br/> Date</th>
                                                    <th className="text-white lh-sm" valign="middle">Invoice<br/> Amount</th>
                                                    <th className="text-white lh-sm" valign="middle">Co-Pay <br/>Paid</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.length !== 0 ? (
                                                    currentItems.map((item, i) => {
                                                        return item.copayHistory !== null ? (
                                                            <tr key={i}>
                                                                <td>{item.patientId}</td>
                                                                <td>{item.DateEnrolled && moment(item.DateEnrolled).format('DD/MM/YYYY')}</td>
                                                                <td>{item.dob && moment(item.dob).format('DD/MM/YYYY')}</td>
                                                                <td>{item.gender}</td>
                                                                <td>{item.hcpName}</td>
                                                                <td>{item.hcpSuburb}</td>
                                                                <td>{item.hcpState}</td>
                                                                <td>{item.pharmacyName}</td>
                                                                <td>{item.pharmacySuburb}</td>
                                                                <td>{item.pharmacyState}</td>
                                                                <td>{item.invoiceID}</td>
                                                                <td>{item.invoiceDate && moment(item.invoiceDate).format('DD/MM/YYYY')}</td>
                                                                <td>{item.invoiceAmount == null? '':'$' + item.invoiceAmount}</td>
                                                                <td>{item.copayPaid === null ? 'N/A' : item.copayPaid && moment(item.copayPaid).format('DD/MM/YYYY')}</td>
                                                            </tr>
                                                        ) : (
                                                            ""
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td className="text-center" valign="middle" colSpan={14}>
                                                            No records found
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="spacer-2"></div>
                                    <div className='d-flex'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                                </li>
                                                {Array.from({ length: Math.ceil(copayData.length / itemsPerPage) }, (_, index) => (
                                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => paginate(index + 1)}>
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                                <li className={`page-item ${currentPage === Math.ceil(copayData.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                                </li>
                                            </ul>
                                            
                                        </nav>                                        
                                         <div className="ms-auto">
                                            <CSVLink data={copayData}  headers={CsvHeaders}  filename={"copay Patient Detail.csv"} className='btn btn-primary btn-default'>Export</CSVLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-10"></div>
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Link to="/co-pay-welcome" className="btn btn-primary btn-default">Close</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default CoPayDashboard