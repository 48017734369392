import authHeader from "./auth-header";
import axios from "axios";


const getCopayHistoryDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/getCopayHistory/${id}`, { headers: authHeader() });
};
const getAdminCopayHistory = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/getAdminCopayHistory/${id}`, { headers: authHeader() });
};

const getCopayHistoryWithPatientData = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/getCopayHistoryWithPatientData`, { headers: authHeader() });
};
const getAdminInvoceFile = (filename) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/getAdminInvoceFile/${filename}`,{ headers: {
      ...authHeader(),
      'Content-Type': 'application/octet-stream',
    }
  });
};

const update = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/copay/update`, data,{ headers: authHeader() });
};

// Auth Token with FormData
// const uploadInvoice = (data) => {
//   return axios.post(`${process.env.REACT_APP_API_URL}/api/copay/upload`, data, {
//     headers: {
//       ...authHeader(),
//       'Content-Type': 'multipart/form-data',
//     }
//   })
// };

const PreSign = (data) => {
  console.log(data,"data")
  return axios.post(`${process.env.REACT_APP_API_URL}/api/copay/generate-presigned-url`, data,);
};


const CopayHistoryService = {
  getCopayHistoryDetails,
  // uploadInvoice,
  getAdminCopayHistory,
  update,
  getAdminInvoceFile,
  PreSign,
  getCopayHistoryWithPatientData
};

export default CopayHistoryService;
