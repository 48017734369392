import React, {  useEffect } from "react";
import { Link } from "react-router-dom";
import closeBtnIconImage from "../../images/Close_Btn.svg";

const ChatBotModal = ({ isShowing, hide, activeTab, setActiveTab }) => {
  const toggleAccordion = (tab) => {
    setActiveTab((prevTab) => (prevTab === tab ? null : tab));
  };

  useEffect(() => {
    if (isShowing) {
      document.body.classList.add("custom-backdrop", "modal-open");
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "20px";
    } else {
      document.body.classList.remove("custom-backdrop", "modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }
    // Clean up function to remove the added classes and styles
    return () => {
      document.body.classList.remove("custom-backdrop", "modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };
  }, [isShowing]);

  return (
    <>
      <div
        className={"modal modal-xl fade" + (isShowing ? " show" : "")}
        id="chatbot"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-lg-down">
          <div className="modal-content bg-turquoise-dark border-0 rounded-0">
            <div className="modal-body custom-tabs p-4 p-lg-5">
              <div className="spacer-8 spacer-lg-3"></div>
              <Link
                type="button"
                className="position-absolute end-0 top-0 m-4"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => hide()}
              >
                <img src={closeBtnIconImage} alt="" />
              </Link>

              <div className="row">
                <div className="width-lg-29">
                  <div
                    className="nav d-none d-lg-flex flex-column nav-pills me-3"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className={
                        "nav-link" +
                        (activeTab === "v-pills-type-tab" ? " active" : "")
                      }
                      id="v-pills-type-tab"
                      onClick={() => setActiveTab("v-pills-type-tab")}
                    >
                      Types of Chatbots
                    </button>

                    <button
                      className={
                        "nav-link" +
                        (activeTab === "v-pills-opportunity-tab"
                          ? " active"
                          : "")
                      }
                      id="v-pills-opportunity-tab"
                      onClick={() => setActiveTab("v-pills-opportunity-tab")}
                    >
                      The opportunity <br /> in healthcare
                    </button>
                    <button
                      className={
                        "nav-link" +
                        (activeTab === "v-pills-solutions-tab" ? " active" : "")
                      }
                      id="v-pills-solutions-tab"
                      onClick={() => setActiveTab("v-pills-solutions-tab")}
                    >
                      Our solutions
                    </button>
                    <button
                      className={
                        "nav-link" +
                        (activeTab === "v-pills-compliance-tab"
                          ? " active"
                          : "")
                      }
                      id="v-pills-compliance-tab"
                      onClick={() => setActiveTab("v-pills-compliance-tab")}
                    >
                      Compliance
                    </button>
                  </div>
                </div>
                <div className="width-lg-60">
                  <div
                    className="tab-content accordion custom-accordian"
                    id="v-pills-tabContent"
                  >
                    <div
                      className={
                        "tab-pane fade accordion-item border-0 bg-turquoise-dark" +
                        (activeTab === "v-pills-type-tab" ? " show active" : "")
                      }
                      id="v-pills-type"
                      attr="v-pills-type-tab"
                      role="tabpanel"
                      aria-labelledby="v-pills-type-tab"
                    >
                      <h2
                        className="accordion-header d-lg-none mb-2"
                        id="headingOne"
                      >
                        <button
                          className={
                            "accordion-button rounded-0" +
                            (activeTab === "v-pills-type-tab"
                              ? " "
                              : " collapsed")
                          }
                          type="button"
                          onClick={() => toggleAccordion("v-pills-type-tab")}
                        >
                          Types of Chatbots
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className={
                          "accordion-collapse collapse d-lg-block bg-turquoise-dark" +
                          (activeTab === "v-pills-type-tab" ? " show" : "")
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#v-pills-tabContent"
                      >
                        <div className="spacer-2 spacer-lg-0"></div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2 className="fw-bold text-white lh-1 mb-2">
                              Types of Chatbots
                            </h2>
                            <p className="fs-18 text-white lh-sm mb-2">
                              <span className="fw-bold">Assistant: </span> provide the user with a predefined answer (like 'Frequently Asked Questions')
                            </p>
                            <p className="fs-18 text-white lh-sm mb-2">
                              <span className="fw-bold">Concierge:</span> provide a contextualised response and facilitate a
                              service to the user. For example, explaining the steps of an action to be taken (like a hotel receptionist)
                            </p>
                          </div>
                          <div className="spacer-2"></div>
                          <div className="col-sm-12">
                            <div className="card p-4 bg-white bg-opacity-30 border-0 rounded-4">
                              <p className="mb-0 fs-20 text-white lh-sm px-3">
                                <span className="fw-bold">Advisor:</span> use
                                natural language processing to integrate
                                customised answers to complex requests with
                                automated processes to perform certain actions
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="spacer-3 spacer-lg-0"></div>
                      </div>
                    </div>
                    <div
                      className={
                        "tab-pane fade accordion-item border-0 bg-turquoise-dark" +
                        (activeTab === "v-pills-opportunity-tab"
                          ? " show active"
                          : "")
                      }
                      id="v-pills-opportunity"
                      attr="v-pills-opportunity-tab"
                      role="tabpanel"
                      aria-labelledby="v-pills-type-tab"
                    >
                      <h2
                        className="accordion-header d-lg-none mb-2"
                        id="headingOne"
                      >
                        <button
                          className={
                            "accordion-button rounded-0" +
                            (activeTab === "v-pills-opportunity-tab"
                              ? " "
                              : " collapsed")
                          }
                          type="button"
                          onClick={() =>
                            toggleAccordion("v-pills-opportunity-tab")
                          }
                        >
                          The opportunity in healthcare
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className={
                          "accordion-collapse collapse d-lg-block bg-turquoise-dark" +
                          (activeTab === "v-pills-opportunity-tab"
                            ? " show"
                            : "")
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#v-pills-tabContent"
                      >
                        <div className="spacer-2 spacer-lg-0"></div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2 className="fw-bold text-white lh-1 mb-2">
                              The opportunity in healthcare
                            </h2>
                            <p className="fs-18 text-white lh-sm mb-0">
                              Chatbots in healthcare can improve patient
                              experience, increase efficiency, and contribute to
                              better health outcomes by providing convenient,
                              timely, and personalised support and
                              information. Early and ongoing education is key to
                              driving intrinsic motivation for patients to stay
                              on treatment​ and Chatbots provide uniquely and
                              impactful humanised interactions. With the use of
                              an avatar specifically created to suit your needs,
                              patients can access information and education
                              without the need for HCP involvement, thus
                              convenient and timely.
                            </p>
                          </div>
                          <div className="spacer-2"></div>
                          <div className="col-sm-12">
                            <h3 className="fs-18 fw-bold text-white mb-1">
                              For you:
                            </h3>
                            <ul className="mb-0 ps-3 lh-sm text-white fs-18">
                              <li>
                                To be the innovative leader in the digital
                                integration of health conversations and
                                engagement, providing efficient and effective
                                education and support for your patients
                              </li>
                              <li>
                                An additional communication channel to engage
                                users who are disengaged with print or static
                                website
                              </li>
                            </ul>
                          </div>
                          <div className="spacer-2"></div>
                          <div className="col-sm-12">
                            <h3 className="fs-18 fw-bold text-white mb-1">
                              For users:
                            </h3>
                            <ul className="mb-0 ps-3 lh-sm text-white fs-18">
                              <li>
                                To receive quality health education and
                                information they can interact and engage with
                              </li>
                              <li>
                                To be empowered to manage their own health and
                                education
                              </li>
                              <li>
                                Useful for users who may have been unable to
                                immerse themselves in technology in the past
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="spacer-3 spacer-lg-0"></div>
                      </div>
                    </div>
                    <div
                      className={
                        "tab-pane fade accordion-item border-0 bg-turquoise-dark" +
                        (activeTab === "v-pills-solutions-tab"
                          ? " show active"
                          : "")
                      }
                      id="v-pills-solutions"
                      attr="v-pills-solutions-tab"
                      role="tabpanel"
                      aria-labelledby="v-pills-solutions-tab"
                    >
                      <h2
                        className="accordion-header d-lg-none mb-2"
                        id="headingOne"
                      >
                        <button
                          className={
                            "accordion-button rounded-0" +
                            (activeTab === "v-pills-solutions-tab"
                              ? " "
                              : " collapsed")
                          }
                          type="button"
                          onClick={() =>
                            toggleAccordion("v-pills-solutions-tab")
                          }
                        >
                          Our solutions
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className={
                          "accordion-collapse collapse d-lg-block bg-turquoise-dark" +
                          (activeTab === "v-pills-solutions-tab" ? " show" : "")
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#v-pills-tabContent"
                      >
                        <div className="spacer-2 spacer-lg-0"></div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2 className="fw-bold text-white lh-1 mb-2">
                              Our solutions
                            </h2>
                            <p className="fs-18 text-white lh-sm mb-2">
                            We develop customised Chatbots to provide virtual and individualised disease education and patient support. Fluid and 
                            responsive dialogue allows users to choose their own adventure when interacting with our Chatbots. Users can navigate the 
                            discussion to suit their individual information and support needs and they can engage with our Chatbots at their convenience. 
                            </p>
                            <p className="fs-18 text-white lh-sm mb-2">
                            We say customised as our Chatbots are developed based on your user needs. Be it disease or medication information, disease risk 
                            screening, appointment or medication reminders, links to third party support and
                            information, or even redirection to human support such as the ability to contact a nurse.
                            </p>
                            <p className="fs-18 text-white lh-sm mb-2">
                              <span className="fw-bold">
                                Disease education -
                              </span> our Chatbot can talk users through risk screening tools, educate on disease and has even provided recipes 
                            </p>
                            <p className="fs-18 text-white lh-sm mb-2">
                              <span className="fw-bold">Patient support - </span>our Chatbot engages with patients on therapy to share information about
                               their disease and medication, they can show MOA videos, talk through side effects and direct to safety support if a user 
                               mentions an adverse event
                            </p>
                          </div>
                        </div>
                        <div className="spacer-3 spacer-lg-0"></div>
                      </div>
                    </div>
                    <div
                      className={
                        "tab-pane fade accordion-item border-0 bg-turquoise-dark" +
                        (activeTab === "v-pills-compliance-tab"
                          ? " show active"
                          : "")
                      }
                      id="v-pills-compliance"
                      attr="v-pills-compliance-tab"
                      role="tabpanel"
                      aria-labelledby="v-pills-compliance-tab"
                    >
                      <h2
                        className="accordion-header d-lg-none mb-2"
                        id="headingOne"
                      >
                        <button
                          className={
                            "accordion-button rounded-0" +
                            (activeTab === "v-pills-compliance-tab"
                              ? " "
                              : " collapsed")
                          }
                          type="button"
                          onClick={() =>
                            toggleAccordion("v-pills-compliance-tab")
                          }
                        >
                          Compliance
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className={
                          "accordion-collapse collapse d-lg-block bg-turquoise-dark" +
                          (activeTab === "v-pills-compliance-tab"
                            ? " show"
                            : "")
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#v-pills-tabContent"
                      >
                        <div className="spacer-2 spacer-lg-0"></div>
                        <div className="row">
                          <div className="col-sm-12">
                            <h2 className="fw-bold text-white lh-1 mb-2">
                              Compliance
                            </h2>
                          </div>
                          <div className="col-sm-12">
                            <ul className="mb-0 ps-3 lh-sm text-white fs-18">
                              <li>MA Code compliant</li>
                              <li>
                                All content is 'controlled' - no off-piste 
                              </li>
                              <li>Ongoing learning</li>
                              <li>
                                All engagement is monitored for AE, OSF and
                                product complaints per client safety
                                requirements
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="spacer-3 spacer-lg-0"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show  " /> : ""}
    </>
  );
};
export default ChatBotModal;
