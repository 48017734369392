import authHeader from "./auth-header";
import axios from "axios";

const login = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/patient-support/hcp-signin", data);
};

// const logout = () => {
//   localStorage.removeItem("copayhcpToken");
//   localStorage.removeItem("copayhcpId");
// };



const create = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/patient-support/hcp-signup", data);
};

// Auth Token
const update = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/patient-support/hcp-update/${id}`, data, {
    headers: authHeader(),
  });
};



const SupportHcpService = {
  login,
  // logout,
  create,
  update,
};

export default SupportHcpService;
