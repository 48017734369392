import React, { useState, useRef } from "react";
import PatientService from "../../services/PatientService";
import CopayPatientHeader from "../CopayPatientHeader";
import PatientContactThankyouModal from "../modal/Patient/PatientContactThankyouModal";
import Footer from "../Footer";
import useModal from "../modal/useModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PatienteContactUsValSchema } from "../ValidationForm/validationSchema";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";

const CoPayPatientContactus = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const copayPatientId = localStorage.getItem("copayPatientId");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(PatienteContactUsValSchema),
    defaultValues: { patientId: copayPatientId, query:"If you have any queries about the Solutionary Health Co-pay please complete this form and we will be in touch."},
  });

  const { isShowing: isShowingThankyou, toggle: toggleThankyou } = useModal();

  const handlePatientContactUs = (data) => {
    setLoading(true);

    PatientService.PatientContactUs(data).then(
      (response) => {
        reset();
        setValue("patientId", data.patientId);
        setLoading(false);

        toggleThankyou();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);

        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <PatientContactThankyouModal
        isShowing={isShowingThankyou}
        hide={toggleThankyou}
      />
      <CopayPatientHeader />
      {loading && <Loader />}
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-lg-10">
              <h1 className="text-uppercase">Contact Us</h1>
              <div className="spacer-1"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <form
                onSubmit={handleSubmit(handlePatientContactUs)}
                ref={form}
                className="custom-form"
              >
                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <textarea
                      cols="100%"
                      rows="5"
                      className="form-control fs-18 text-dark-gray"
                      placeholder="If you have any queries about the Solutionary Health Co-pay please complete this form and we will be in touch."
                      name="query"
                      readOnly
                      {...register("query")}
                    ></textarea>
                    {errors.query && <span>{errors.query.message}</span>}
                  </div>
                  <div className="col-sm-12">
                    <select
                      className="form-select mb-2 fs-18 text-dark-gray"
                      name="queryAbout"
                      {...register("queryAbout")}
                    >
                      <option value="">Please contact me to discuss:</option>
                      <option value="Quesstion about my Rebate">
                        {" "}
                        Question about my Rebate{" "}
                      </option>
                      <option value="Question about the Program">
                        Question about the Program{" "}
                      </option>
                      <option value="Question about the Product">
                        Question about the Product{" "}
                      </option>
                      <option value="Other">Other</option>
                    </select>
                    {errors.queryAbout && (
                      <span>{errors.queryAbout.message}</span>
                    )}
                  </div>
                </div>
                <div className="spacer-3 spacer-lg-5"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <button className="btn btn-primary btn-default btn-block d-md-inline-block">
                      Send
                    </button>
                  </div>
                </div>
                <div className="spacer-5"></div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default CoPayPatientContactus;
