import authHeader from "./auth-header";
import axios from "axios";


const getSupportInvoiceDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/support/getSupportInvoice/${id}`, { headers: authHeader() });
};
const getAdminSupportInvoice = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/support/getAdminSupportInvoice/${id}`, { headers: authHeader() });
};

// const getCopayHistoryWithPatientData = (id) => {
//   return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/getCopayHistoryWithPatientData`, { headers: authHeader() });
// };


const updatePharmacy = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/support/updatePharmacy`, data,{ headers: authHeader() });
};

const updateAllied = (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/support/updateAllied`, data,{ headers: authHeader() });
};

const PreSign = (data) => {
  console.log(data,"data")
  return axios.post(`${process.env.REACT_APP_API_URL}/api/support/generate-presigned-url`, data,);
};


const SupportInvoiceService = {
  getSupportInvoiceDetails,
  getAdminSupportInvoice,
  updatePharmacy,
  updateAllied,
  PreSign,
  // getCopayHistoryWithPatientData
};

export default SupportInvoiceService;
