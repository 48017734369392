import React, { useEffect } from 'react'
import Header from "../Header";
import Footer from "../Footer";
import HealthcareSupportProffestionalImg from "../../images/support-healthcare-proffestional.jpg";
import SupportPatientImg from "../../images/support-patient.jpg";
import SupportHcpSignupModal from '../modal/SupportHcp/SupportHcpSignupModal';
import useModal from '../modal/useModal';
import { Link, useNavigate } from 'react-router-dom';
import SupportHcpLoginModal from '../modal/SupportHcp/SupportHcpLoginModal';
import SupportPatientLoginModal from '../modal/SupportHcp/SupportPatientLoginModal';
const PatientSupportWelcome = () => {
  const { isShowing: isShowingLogin, toggle: toggleLogin } = useModal();
  const { isShowing: isShowingRegister, toggle: toggleRegister } = useModal();
  const { isShowing: isShowingPatientLogin, toggle: togglePatinetLogin } = useModal();

  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("supporthcpToken")) {
      navigate("/support-hcp-enrol");
    }

    if (localStorage.getItem("supportPatientToken")) {
      navigate("/support-patient-dashboard");
    }
  }, []);
  return (
    <>
      <SupportHcpLoginModal isShowing={isShowingLogin} hide={toggleLogin} />
      <SupportHcpSignupModal isShowing={isShowingRegister} hide={toggleRegister} />
      <SupportPatientLoginModal isShowing={isShowingPatientLogin} hide={togglePatinetLogin} />
    <Header />
    <main className="inner-page co-pay">
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="text-uppercase">WELCOME TO OUR TRULY PATIENT-CENTRIC SUPPORT MODEL</h1>
              <div className="spacer-2"></div>
              <p className="fs-20 lh-sm">For years we have delivered patient support programs offering reminders, education and nurse assistance. While effective, we questioned if this traditional approach fully meets patients' needs.</p>
              <p className="fs-20 lh-sm">Patient support should aim to maximise treatment success and be delivered equitably and cost-effectively nationwide. A support program should enable clinicians to individualise the support their patients’ access.  </p>
              <p className="fs-20 lh-sm">Our innovative solution offers a patient- and clinician-centric model providing individualised allied health support aligned with therapeutic goals. It is scalable, sustainable, national, compliant, auditable, and enables clinicians to leverage their allied health network.</p>
              {/* <p className="fs-20 lh-sm"> </p> */}
              <div className="spacer-5"></div>
            </div>
          </div>
          <div className="row gx-4">
            <div className="col-md-6 mb-3 mb-xl-0">
              <div
                className="card rounded-0 border-0 p-4"
                style={{
                  backgroundImage: `url(${HealthcareSupportProffestionalImg})`,
                }}
              >
                <div className="entry-content d-flex px-1 flex-column h-100">
                  <div className="entry-title">
                    <div className="spacer-3"></div>
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="fw-bold text-white">
                          Healthcare <br /> Professionals{" "}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="entry-action mt-auto">
                    <div className="row">
                      <div className="width-lg-30">
                        <span
                          className="btn btn-blue btn-default btn-block border-white border-1"
                          onClick={(e) => toggleLogin()}
                        >
                          Login
                        </span>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="width-lg-30">
                        <span
                          className="btn btn-blue btn-default btn-block border-white border-1"
                          onClick={(e) => toggleRegister()}
                        >
                          Register
                        </span>
                      </div>
                    </div>
                    <div className="spacer-2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-xl-0">
              <div
                className="card rounded-0 border-0 p-4"
                style={{ backgroundImage: `url(${SupportPatientImg})` }}
              >
                <div className="entry-content d-flex px-1 flex-column h-100">
                  <div className="entry-title">
                    <div className="spacer-3"></div>
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="fw-bold text-white">Patients </h2>
                      </div>
                    </div>
                  </div>
                  <div className="entry-action mt-auto">
                    <div className="row">
                      <div className="width-lg-30">
                        <span
                           onClick={(e) => togglePatinetLogin()}
                          className="btn btn-blue btn-default btn-block border-white border-1"
                        >
                          Login
                        </span>
                      </div>
                    </div>
                    <div className="spacer-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-10"></div>
            <div className="row">
               <div className="col-sm-12 text-center">
               <Link to="/patient-support-welcome" className="btn btn-blue btn-default">METRICS</Link>
               </div>
            </div>
        </section>
      </main>
    <Footer/>
    </>
  )
}

export default PatientSupportWelcome