import emailService from "../services/EmailService";
import smsTemplateService from "../services/smsTemplateService";

//Will retrieve the SMS from SMSTemplates and replace the params
export const getSMSText = async(id_descriptive, params)=>{
 return await smsTemplateService.findBySmsText({id_descriptive}).then(
    (response)=>{ 
      console.log(response,"res")
      if(response.data)
      {
        let sms = response.data.sms
        //Replace the items in the params
        params.map(aParam =>{
          let key = Object.keys(aParam)[0];
          sms = sms.replace(new RegExp("<"+key+">", 'g'), aParam[key]);
          return true
        });
        return sms;
      }
      // return null;//Could not find SMS
      
  },(error) => {
    console.log(error,"error")
    return null
  })
}

export const getEmailText = async(code, params)=>{
  return await emailService.getEmail({code}).then(
     (response)=>{ 
       console.log(response,"res")
       if(response.data)
       {
         let html = response.data.html
         //Replace the items in the params
         params.map(aParam =>{
           let key = Object.keys(aParam)[0];
           html = html.replace(new RegExp("<"+key+">", 'g'), aParam[key]);
           return true
         });
         response.data.html= html
         return response.data;
       }
       // return null;//Could not find email template
       
   },(error) => {
     console.log(error,"error")
     return null
   })
 }

