import React from "react";
import CopayHeader from "../CopayHeader";
import Footer from "../Footer";

const HcpProduct = () => {
  return (
    <>
      <CopayHeader />
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-uppercase">Product</h1>
              <div className="spacer-1"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div
                className="card bg-teal rounded-0 border-0 text-center p-4 h-100 d-flex justify-content-center"
                style={{ minHeight: "500px" }}
              >
                <p className="mb-0 fs-24 fw-bold text-white lh-sm">
                  Information about the Product <br /> for Healthcare
                  Professionals
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default HcpProduct;
