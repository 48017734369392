import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useModal from "../components/modal/useModal";
import chatAvatarImage from "../images/chat-avatar-device.png";
import chatAvatarDeviceImage from "../images/chat-avatar-1.png";
import chatAvatarTwoImage from "../images/chat-avatar-2.png";
import chatbotTypeIconImage from "../images/chatbot-type-icon.svg";
import ourSolutionIconImage from "../images/our-solution-icon.svg";
import letsChatIconImage from "../images/lets-chat-icon.png";
import oppHealthcareIconImage from "../images/opportunity-healthcare-icon.svg";
import complianceIconImage from "../images/compliace-icon.svg";
import ChatBotModal from "../components/modal/ChatBotModal";

const ChatBotComponent = () => {
  const { isShowing: isShowingChatbot, toggle: toggleChatbot } = useModal();
  const [activeTab, setActiveTab] = useState("v-pills-type");
  return (
    <>
      <Header />
      <ChatBotModal
        isShowing={isShowingChatbot}
        hide={toggleChatbot}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <main className="inner-page">
        <section className="container">
          <div className="row justify-content-center">
            <div className="width-lg-95 text-center">
              <h1 className="text-uppercase">
                Innovators in Healthcare Chatbots
              </h1>
              <div className="spacer-3"></div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="row gx-lg-5">
                <div className="col">
                  <div className="chatbot-avatar">
                    <img
                      src={chatAvatarDeviceImage}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col text-center">
                  <div className="chatbot-avatar">
                    <img src={chatAvatarImage} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="col">
                  <div className="chatbot-avatar">
                    <img
                      src={chatAvatarTwoImage}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-6"></div>
          <div className="row gx-3">
            <div className="col-md-6 col-lg-4 order-2 order-lg-1">
              <div className="row">
                <div className="col-sm-12">
                  <div className="chat-card bg-turquoise border-0 d-flex align-items-center justify-content-center rounded-4 position-relative">
                    <div className="entry-icon">
                      <img src={chatbotTypeIconImage} alt="" />
                    </div>
                    <div className="entry-title ms-4">
                      <h2 className="fs-30 fw-bold text-white mb-0 lh-1">
                        Types of <br />
                        Chatbots
                      </h2>
                    </div>
                    <div className="entry-action">
                      <Link
                        to="#"
                        className="stretched-link custom-modal"
                        id="v-pills-type-tab"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleChatbot();
                          setActiveTab("v-pills-type-tab");
                        }}
                      ></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer-2 spacer-lg-4"></div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="chat-card bg-turquoise border-0 d-flex align-items-center justify-content-center rounded-4 position-relative">
                    <div className="entry-icon">
                      <img src={ourSolutionIconImage} alt="" />
                    </div>
                    <div className="entry-title ms-4">
                      <h2 className="fs-30 fw-bold text-white mb-0 lh-1">
                        Our solutions
                      </h2>
                    </div>
                    <div className="entry-action">
                      <Link
                        to="#"
                        className="stretched-link custom-modal"
                        id="v-pills-solutions-tab"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleChatbot();
                          setActiveTab("v-pills-solutions-tab");
                        }}
                      ></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer-2 spacer-lg-0"></div>
            </div>

            <div className="col-lg-4 order-1 order-lg-2 mb-3 mb-lg-0">
              <div className="row justify-content-center h-100">
                <div className="col-lg-8">
                  <div className="bg-dark-blue border-0 rounded-4 d-flex justify-content-center h-100 align-items-center">
                    <div className="entry-icon p-5 chat-icon">
                      <a href="http://m.me/solhealthbot" target="_blank">
                      <img
                        src={letsChatIconImage}
                        className="img-fluid"
                        alt=""
                      />
                      </a>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 order-3 order-lg-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="chat-card bg-turquoise border-0 d-flex align-items-center justify-content-center rounded-4 position-relative">
                    <div className="entry-icon">
                      <img src={oppHealthcareIconImage} alt="" />
                    </div>
                    <div className="entry-title ms-3">
                      <h2 className="fs-30 fw-bold text-white mb-0 lh-1">
                        The opportunity <br />
                        in healthcare
                      </h2>
                    </div>
                    <div className="entry-action">
                      <Link
                        to="#"
                        className="stretched-link custom-modal"
                        id="v-pills-opportunity-tab"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleChatbot();
                          setActiveTab("v-pills-opportunity-tab");
                        }}
                      ></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer-2 spacer-lg-4"></div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="chat-card bg-turquoise border-0 d-flex align-items-center justify-content-center rounded-4 position-relative">
                    <div className="entry-icon">
                      <img src={complianceIconImage} alt="" />
                    </div>
                    <div className="entry-title ms-4">
                      <h2 className="fs-30 fw-bold text-white mb-0 lh-1">
                      Compliance
                      </h2>
                    </div>
                    <div className="entry-action">
                      <Link
                        to="#"
                        className="stretched-link custom-modal"
                        id="v-pills-compliance-tab"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleChatbot();
                          setActiveTab("v-pills-compliance-tab");
                        }}
                      ></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default ChatBotComponent;
