import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import LogoInnerImg from "../images/logo-inner.svg";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();
  // const [showMenuItem, setMenuItem] = useState("copay");
  // const [localStorageItem, setLocalStorageItem] = useState(localStorage.getItem('myItem') || '');
  
  
  const solHubUser = JSON.parse(localStorage.getItem("solHubUser"));
  
  
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    
    localStorage.removeItem("accessToken");    
    localStorage.removeItem("solHubUser");
    localStorage.removeItem("solHubPage");    
    localStorage.removeItem("solHubPageToRedirect");
  };

  return (
    <>
      <header className="inner fixed-top">
        <nav className="navbar navbar-expand-lg bg-white sample-navigation">
          <section className="container">
            <div className="navigation d-flex align-items-end w-100">
              {localStorage.getItem("accessToken") ? (
                <>
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </>
              ) : null}
              <div
                className={`offcanvas offcanvas-start border-end-0 ${showMenu ? "show" : "hide"
                  }`}
                tabIndex="-1"
              >
                <div className="offcanvas-header d-flex d-lg-none">
                  <img src={LogoInnerImg} className="img-fluid w-75" alt="" />
                  <button
                    type="button"
                    className="btn-close"
                    onClick={toggleMenu}
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav mb-lg-0">
                  {localStorage.getItem("accessToken") ? (
                      <>
                      
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${location.pathname === "/chatbot"
                                ? "active-chatbot"
                                : ""
                              }`}
                            aria-current="page"                            
                            to={solHubUser.isChatbot ? ( "/chatbot") : "#"}
                          >
                            Chatbot
                          </Link>
                        </li>
                      
                      
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${location.pathname === "/co-pay-welcome"
                                ? "active"
                                : ""
                              }`}
                            aria-current="page"                            
                            to={solHubUser.isCopay ? ( "/co-pay-welcome") : "#"}
                          >
                            Co-Pay and Private
                          </Link>
                        </li>
                      
                        <li className="nav-item">
                          <Link className={`nav-link ${location.pathname === "/samples"
                                ? "active-gray"
                                : ""
                              }`}aria-current="page" 
                              to={solHubUser.isSamples? ( "/samples") : "#"}>
                             Access & Engagement
                          </Link>
                        </li>
                        <li className="nav-item">
                         <Link
                            className={`nav-link ${location.pathname === "/patient-support-welcome"
                                ? "active-blue"
                                : ""
                              }`}
                            aria-current="page"                            
                            to={solHubUser.isPatientSupport ? ( "/patient-support-welcome") : "#"}
                          >
                            Patient Support
                          </Link>
                        </li>
                        
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${location.pathname === "/direct-to-consumer"
                                ? "active-orange"
                                : ""
                              }`}
                            aria-current="page"                            
                            to={solHubUser.isDtc ? ( "/direct-to-consumer") : "#"}
                          >
                            Direct to Consumer
                          </Link>
                        </li>
                        
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="/"
                            onClick={handleLogout}
                          >
                            Logout
                          </Link>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </div>
              <Link className="w-50 logo-inner ms-auto" to="/">
                <img src={LogoInnerImg} className="img-fluid" alt="" />
              </Link>
            </div>
          </section>
        </nav>
      </header>
    </>
  );
};
export default Header;
