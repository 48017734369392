import React from "react";
import SamplesIconImg from "../../images/sample-icon.png";
import useModal from "../modal/useModal";
import CoPayLoginModal from "../modal/CoPayLoginModal";

const Samples = () => {
  const { isShowing, toggle } = useModal();
  return (
    <>
    <CoPayLoginModal isShowing={isShowing} hide={toggle} pageToRedirect="/samples" page="isSamples" />
      <div className="card bg-gray rounded-0 border-0 text-center p-4">
        <div className="entry-title">
          <img src={SamplesIconImg} alt="" className="img-fluid entry-icon" />
          <h3 className="fw-bold text-white mt-2">Access & Engagement</h3>
        </div>
        <div className="entry-btn mt-auto">
          <a
            className="btn btn-outline-white btn-default fs-18 text-uppercase"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            href="#"
          >
            Enter
          </a>
        </div>
      </div>
    </>
  );
};
export default Samples;
