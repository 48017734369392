import React, { useEffect, useState, useRef } from "react";
import PatientService from "../../services/PatientService";
import Footer from "../Footer";
import { useParams, useNavigate } from "react-router-dom";
import LogoInnerImg from "../../images/logo-inner.svg";
import {
  minLengthRegex,
  capitalLetterRegex,
  numberRegex,
} from "../../common/commonFun.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PatientValSchema } from "../ValidationForm/validationSchema.js";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";

const CoPayPatient = () => {
  const form = useRef();
  let { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(PatientValSchema),
    defaultValues: { patientId: id },
  });
  const [loading, setLoading] = useState(true);

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  // States for Password Validation
  const [passwordValidation, setPasswordValidation] = useState({
    eightCharacters: false,
    capitalLetter: false,
    oneNumber: false,
    passwordMatch: false,
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await PatientService.getPatientStatus(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        navigate("/");
      }
    };

    fetchData();
  }, [id, navigate]);
  
    useEffect(() => {
    if (password || confirmPassword) {
      validatePassword(password, confirmPassword);
    }
  }, [password, confirmPassword]);

  const validatePassword = (password, confirmPassword) => {
    if (!password && !confirmPassword) {
      setPasswordValidation(false);
      return;
    }

    setPasswordValidation({
      ...passwordValidation,
      eightCharacters: minLengthRegex.test(password) ? true : false,
      capitalLetter: capitalLetterRegex.test(password) ? true : false,
      oneNumber: numberRegex.test(password) ? true : false,
      passwordMatch: password === confirmPassword ? true : false,
    });
  };

  const handleCreate = (data) => {
    setLoading(true);
    console.log(data, "data");
    if (
      passwordValidation.eightCharacters === true &&
      passwordValidation.capitalLetter === true &&
      passwordValidation.oneNumber === true &&
      passwordValidation.passwordMatch === true
    ) {
      PatientService.createPatientBankDetails(data).then(
        (response) => {
          setLoading(false);

          localStorage.setItem("copayPatientId", response.data.copayPatientId);
          localStorage.setItem(
            "copayPatientMobile",
            response.data.copayPatientMobile
          );
          localStorage.setItem(
            "copayHcp",
            JSON.stringify(response.data.copayHcp)
          );
          localStorage.setItem(
            "copayPatientToken",
            response.data.copayPatientToken
          );
          reset();
          navigate("/co-pay-patient-dashboard");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage);
        }
      );
    }
  };

  return (
    <>
      <header className="inner fixed-top">
        <nav className="navbar navbar-expand-lg bg-white">
          <section className="container">
            <div className="navigation d-flex align-items-end w-100">
              <a href="/" className="w-50 logo-inner ms-auto">
                <img src={LogoInnerImg} className="img-fluid" alt="" />
              </a>
            </div>
          </section>
        </nav>
      </header>
      {loading && <Loader />}
      <main className="inner-page">
        <section className="container">
          <div className="row gx-3 justify-content-center">
            <div className="width-lg-53">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-uppercase">
                    Welcome to the Solutionary Health Co-Pay Program
                  </h1>
                  <p className="fs-20 lh-sm">
                    We need a few more of your details to complete your
                    enrolment. Please have your phone nearby as we will be
                    sending you an SMS to authenticate this information when you
                    click CONFIRM.
                  </p>
                </div>
              </div>
              <form
                className="custom-form"
                autoComplete="off"
                onSubmit={handleSubmit(handleCreate)}
                ref={form}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="fs-18 fw-bold text-dark-gray mb-2">
                      Please provide your bank details as we pay your co-pay
                      directly into your nominated bank account
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account Name"
                      name="accName"
                      {...register("accName")}
                    />
                  </div>
                  {errors.accName && <span>{errors.accName.message}</span>}
                  <div className="col-sm-12 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="BSB"
                      name="bsb"
                      {...register("bsb")}
                    />
                    {errors.bsb && <span>{errors.bsb.message}</span>}
                  </div>
                  <div className="col-sm-12 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account Number"
                      name="accNumber"
                      {...register("accNumber")}
                    />
                    {errors.accNumber && (
                      <span>{errors.accNumber.message}</span>
                    )}
                  </div>
                </div>
                <div className="spacer-3"></div>
                <div className="row gx-2">
                  <div className="col-sm-12">
                    <h4 className="fs-18 fw-bold text-dark-gray mb-2">
                      Please set a password
                    </h4>
                  </div>
                  <div className="col-sm-6 mb-2 mb-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      {...register("password")}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                  </div>
                  <div className="col-sm-6 mb-2 mb-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      {...register("confirmPassword")}
                    />
                    {errors.confirmPassword && (
                      <span>{errors.confirmPassword.message}</span>
                    )}
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="mb-1 fs-18 text-dark-gray">
                      Please choose a secure password
                    </p>
                    <ul className="list-unstyled password-instructions text-dark-gray mb-0">
                      <li
                        className={
                          passwordValidation.eightCharacters ? "true" : "false"
                        }
                      >
                        At least 8 characters
                      </li>
                      <li
                        className={
                          passwordValidation.capitalLetter ? "true" : "false"
                        }
                      >
                        One capital letter
                      </li>
                      <li
                        className={
                          passwordValidation.oneNumber ? "true" : "false"
                        }
                      >
                        One number
                      </li>
                      <li
                        className={
                          passwordValidation.passwordMatch ? "true" : "false"
                        }
                      >
                        Password match
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="spacer-3 spacer-md-5"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <button className="btn btn-primary btn-default btn-block d-md-inline-block">
                    CONFIRM
                    </button>
                  </div>
                </div>
                <div className="spacer-5"></div>
              </form>
            </div>
            {/* <div className="width-lg-43">
              <div className="card bg-teal rounded-0 border-0 text-center p-4 h-100 d-flex justify-content-center">
                <p className="mb-0 fs-24 fw-bold text-white lh-sm">
                  Topline overview of the <br /> Program and Benefits
                </p>
              </div>
            </div> */}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default CoPayPatient;
