import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AdminService from "../../../services/AdminService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AdminAccessSchema } from "../../ValidationForm/validationSchema";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import $ from 'jquery'; // Import jQuery
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import { currentDate } from '../../../common/commonFun';

const handleLogout = () => {
  localStorage.removeItem("adminAccessToken");
  localStorage.removeItem("adminId");
  localStorage.removeItem("adminSearchPatientId");
  localStorage.removeItem("copayHistoryDetail");
};


const AdminAccessComponent = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,setValue,clearErrors
  } = useForm({
    resolver: yupResolver(AdminAccessSchema),
  });

  useEffect(() => {
  
    // Initialize datepicker
    $('#dob').datepicker({
      format: 'dd/mm/yyyy', 
      startView: 2,
      startDate: '01/01/1900', 
      endDate: currentDate(),
      autoclose: true,
      defaultViewDate: { year: 1955, month: 4, day: 25 },
    }).on('changeDate', function (e) {
      const formattedDate = e.format('yyyy-mm-dd');
      setValue("dob", formattedDate);
      if (errors.dob) {
        clearErrors("dob");
      }
    });
  
    // Set default value from URL params if available
    const urlParams = new URLSearchParams(window.location.search);
    const birthday = urlParams.get('birthday');
    if (birthday) {
      setValue("dob", birthday);
    }
  }, [setValue, errors, clearErrors]);
  
 

  const handleSearchPatient = (data) => {
    setLoading(true);
    AdminService.adminSearchPatient(data).then(
      (response) => {
        setLoading(false);

        localStorage.setItem(
          "adminSearchPatientId",
          response.data.data.patientId
        );
        reset();
        navigate("/solutionaryhub-admin-portal/patient-overview");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        reset();
        setLoading(false);

        toast.error(resMessage);
      }
    );
  };




  return (
    <>
      <>
        <AdminHeader />
        {loading && <Loader />}
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1 className="text-uppercase">
                  Access Solutionary Health <br /> Co-pay Patient
                </h1>
              </div>
            </div>
            <div className="spacer-3"></div>
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-9">
                <form
                  action=""
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={handleSubmit(handleSearchPatient)}
                  ref={form}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Patient Mobile"
                        autoComplete="false"
                        name="mobile"
                        {...register("mobile")}
                      />
                      {errors.mobile && <span>{errors.mobile.message}</span>}
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row">
                    <div className="col-sm-12">
                      <p className="mb-0 fs-18 text-dark-gray text-center fw-bold">
                        OR
                      </p>
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row">
                    <div className="col-sm-2 d-flex align-items-center">
                      <label htmlFor="" className="form-label fw-bold">
                        First Name:
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        placeholder="First Name"
                        name="firstname"
                        {...register("firstname")}
                      />
                      {errors.firstname && (
                        <span>{errors.firstname.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row">
                    <div className="col-sm-2 d-flex align-items-center">
                      <label htmlFor="" className="form-label fw-bold">
                        Last Name:
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        autoComplete="false"
                        name="lastname"
                        {...register("lastname")}
                      />
                      {errors.lastname && (
                        <span>{errors.lastname.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row">
                    <div className="col-sm-2 d-flex align-items-center">
                      <label htmlFor="" className="form-label fw-bold">
                        DOB:
                      </label>
                    </div>
                    <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="dd/mm/yyyy"
                      id="dob"
                      name="dob"
                      readOnly // Ensure the input field is read-only
                      {...register("dob")}
                    />
                      {errors.dob && <span>{errors.dob.message}</span>}
                    </div>
                  </div>
                  <div className="spacer-6"></div>
                  <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                      <button className="btn btn-primary btn-default btn-block w-100">
                        Enter
                      </button>
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                      <Link
                        className="btn btn-outline-gray btn-default btn-block"
                        to="/solutionaryhub-admin-portal"
                        onClick={handleLogout}
                      >
                        Logout
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    </>
  );
};

export default AdminAccessComponent;
