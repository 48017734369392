import * as yup from "yup";
import { startsWith04 } from "../../common/commonFun";

export const HcpWelcomeValSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),

  lastname: yup.string().required("This field is required."),

  dob: yup.string().required("This field is required."),

  gender: yup.string().required("This field is required."),

  mobile: yup
    .string()
    .required("This field is required.")
    .test("startsWith04", "Mobile number must start with 04.", startsWith04)
    .matches(/^\d{10}$/, "Please use 10 numbers."),

  indication: yup.string().required("This field is required."),

  flexRadioDisabled: yup.string().required("This field is required."),
  Consent: yup.string().required("This field is required."),
});

export const CoPayLoginModalValSchema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required.")
    .email("Please enter a valid email address."),

  password: yup.string().required("This field is required."),
});

export const HcpLoginModalValSchema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required.")
    .email("Please enter a valid email address."),

  password: yup.string().required("This field is required."),
});
export const PatinetLoginModalValSchema = yup.object().shape({
  mobile: yup
    .string()
    .required("This field is required.")
    .test("startsWith04", "Mobile number must start with 04.", startsWith04)
    .matches(/^\d{10}$/, "Please use 10 numbers."),

  password: yup.string().required("This field is required."),
});

export const HcpSignupModalValSchema = yup.object().shape({
  state: yup.string().required("This field is required."),
  suburb: yup.string().required("This field is required."),
  name: yup.string().required("This field is required."),
  email: yup
    .string()
    .required("This field is required.")
    .email("Please enter a valid email address."),
  code: yup.string().required("This field is required."),
  password: yup.string().required("Password is required."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  privacyAndTerm: yup
    .boolean()
    .oneOf(
      [true],
      "Please accept the Privacy Policy and Terms and Conditions."
    ),
  consent: yup.boolean().oneOf([true], "Please accept."),
});

export const HcpDetailsValSchema = yup.object().shape({
  email: yup.string().email().required("This field is required."),
});

export const PatienteContactUsValSchema = yup.object().shape({
  query: yup.string().required("This field is required."),
  queryAbout: yup.string().required("This field is required."),
});
export const SupportPatientContactUsValSchema = yup.object().shape({
  queryAbout: yup.string().required("This field is required."),
});

export const PatientValSchema = yup.object().shape({
  accName: yup.string().required("This field is required."),
  bsb: yup
    .string()
    .required("This field is required.")
    .matches(/^\d+$/, "BSB must be numeric."),
  accNumber: yup
    .string()
    .required("This field is required.")
    .matches(/^\d+$/, "Account number must be numeric."),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const CoPayPatientMyDetailsMobileValSchema = yup.object().shape({
  mobile: yup
    .string()
    .required("This field is required.")
    .test("startsWith04", "Mobile number must start with 04.", startsWith04)
    .matches(/^\d{10}$/, "Please use 10 numbers."),
});

export const CoPayPatientMyDetailsBankDetailsValSchema = yup.object().shape({
  accName: yup.string().required("This field is required."),
  bsb: yup
    .string()
    .required("This field is required.")
    .matches(/^\d+$/, "BSB must be numeric.")
    .length(6, "BSB must be exactly 6 digits."),
  accNumber: yup
    .string()
    .required("This field is required.")
    .matches(/^\d+$/, "Account number must be numeric.")
    .length(6, "Account number must be exactly 6 digits."),
});

export const CoPayPatientMyDetailsPasswordValSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const AdminLoginSchema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required.")
    .email("Please enter a valid email address."),
  password: yup.string().required("This field is required."),
});

export const AdminAccessSchema = yup.object().shape({
  mobile: yup.lazy(() =>
    yup.string().when(["firstname", "lastname", "dob"], {
      is: (firstname, lastname, dob) => !firstname && !lastname && !dob,
      then: () => yup.string().required("This field is required."),
      otherwise: () => yup.string(),
    })
  ),
  firstname: yup.lazy(() =>
    yup.string().when("mobile", {
      is: (mobile) => !mobile,
      then: () => yup.string().required("This field is required."),
      otherwise: () => yup.string(),
    })
  ),
  lastname: yup.lazy(() =>
    yup.string().when("mobile", {
      is: (mobile) => !mobile,
      then: () => yup.string().required("This field is required."),
      otherwise: () => yup.string(),
    })
  ),
  dob: yup.lazy(() =>
    yup.string().when("mobile", {
      is: (mobile) => !mobile,
      then: () => yup.string().required("This field is required."),
      otherwise: () => yup.string(),
    })
  ),
});

export const AdminAEReportSchema = yup.object().shape({
  date: yup.string().required("This field is required."),
  source: yup.string().required("This field is required."),
  reportNumber: yup.string().required("This field is required."),
});

export const AdminInvoceEntrySchema = yup.object().shape({
  supplyDate: yup.string().required("This field is required."),
  amount: yup.string().required("This field is required."),
  pharmacyState:yup.string().required("This field is required."),
  pharmacySuburb:yup.string().required("This field is required."),
  pharmacyName:yup.string().required("This field is required."),
  doctorState:yup.string().required("This field is required."),
  doctorSuburb:yup.string().required("This field is required."),
  doctorName:yup.string().required("This field is required."),
  isProduct: yup.boolean().oneOf([true], "Please accept the Product."),
  isPharmacistSignature: yup
    .boolean()
    .oneOf([true], "Please accept the Pharmacist Signature."),
});
export const AdminAlliedInvoceEntrySchema = yup.object().shape({
  supplyDate: yup.string().required("This field is required."),
  practitionerSuburb: yup.string().required("This field is required."),
  practitionerName: yup.string().required("This field is required."),
  amount: yup.string().required("This field is required."),
  isAlliedHealthTaxInvoice: yup
    .boolean()
    .oneOf([true], "Please accept the Allied Health Tax Invoice."),
});

export const GlobalLoginModalValSchema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required.")
    .email("Please enter a valid email address."),

  password: yup.string().required("This field is required."),
});

export const AdminBankDetailsSchema = yup.object().shape({
  makePayment: yup.boolean().oneOf([true], "Please select make payment."),
  payment: yup.boolean().oneOf([true], "Please select payment."),
});
