import authHeader from "./auth-header";
import axios from "axios";

const supportPatientLogin = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/patient-support/patient-signin", data,{ headers: authHeader() });
};

const getPatientStatus = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/${id}`);
};

const getSupportPatientDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/patient-details/${id}`, { headers: authHeader() });
};

// // Auth Token
const createPatientEnroll = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/patient-support/patient-enroll", data, { headers: authHeader() });
};

const createSupportPatientBankDetails = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/patient-support/patient-bank-details", data);
};

// // Auth Token
const requestToUpdateSupportPatientMobile = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/patient-support/request-support-patient-update-mobile/${id}`, data, {
    headers: authHeader(),
  });
};
const verifySupportPatientMobileNumber = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/verify-support-patient-mobile-number/${tokenId}`);
};
const notVerifySupportPatientMobileNumber = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/not-verify-support-patient-mobile-number/${tokenId}`);
};

const getSupportPatientBankDetails = (supportpatientId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/patient-bank-details/${supportpatientId}`);
};
// Auth Token
const requestToUpdateSupportPatientBankDetails = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/patient-support/request-support-patient-update-bank-details/${id}`, data, {
    headers: authHeader(),
  });
};
const verifySupportPatientBankDetails = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/verify-support-patient-bank-details/${tokenId}`);
};
const notVerifySupportPatientBankDetails = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/not-verify-support-patient-bank-details/${tokenId}`);
};

// // Auth Token
const requestToUpdateSupportPatientPassword = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/patient-support/request-support-patient-update-password/${id}`, data, {
    headers: authHeader(),
  });
};
const verifySupportPatientPassword = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/verify-support-patient-password/${tokenId}`);
};
const notVerifySupportPatientPassword = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/patient-support/not-verify-support-patient-password/${tokenId}`);
};

// // Auth Token
const SupportPatientContactUs = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/patient-support/patient-contact-us", data, {
    headers: authHeader(),
  });
};

const SupportPatientService = {
  supportPatientLogin,
  getPatientStatus,
  getSupportPatientDetails,
  createPatientEnroll,
  createSupportPatientBankDetails,
  requestToUpdateSupportPatientMobile,
  verifySupportPatientMobileNumber,
  notVerifySupportPatientMobileNumber,
  getSupportPatientBankDetails,
  requestToUpdateSupportPatientBankDetails,
  verifySupportPatientBankDetails,
  notVerifySupportPatientBankDetails,
  requestToUpdateSupportPatientPassword,
  verifySupportPatientPassword,
  notVerifySupportPatientPassword,
  SupportPatientContactUs,
};

export default SupportPatientService;
