import React, { useState } from 'react'
import smsTemplateService from '../services/smsTemplateService';

const TestSendSMS = () => {
    const [state, setState] = useState('');
    const onsendSMS=()=>{
    console.log(state,'state')
        smsTemplateService.sendSMS({to:state, msg:'Hello this is test sms...',userType:"copay-patient",userId:'1'});
    }
     
  return (

    <div className='m-4'>
        <input type="text" id="toNum" placeholder='TO Number' onChange={(e)=>setState(e.target.value)}></input>
        <button className="btn-primary btn" onClick={()=>onsendSMS()}>sendSMS</button>
        </div>
  )
}

export default TestSendSMS