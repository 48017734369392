import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CloseBtnImg from "../../images/Close_Btn.svg";
import CoPayService from "../../services/CoPayService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CoPayLoginModalValSchema } from "../../components/ValidationForm/validationSchema";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";

const CoPayLoginModal = ({ isShowing, hide, pageToRedirect = "", page= "" }) => {
  let navigate = useNavigate();
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const redirectTo = pageToRedirect !== "" ? pageToRedirect : "/co-pay-welcome";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(    
    { defaultValues: { page: page }, 
    resolver: yupResolver(CoPayLoginModalValSchema) }
  );
  
  const handleLogin = async (data) => {
    setLoading(true);

    CoPayService.login(data).then(
      (response) => {
        if (response.data.accessToken) {
          localStorage.setItem("accessToken", response.data.accessToken);          
          localStorage.setItem("solHubUser",JSON.stringify(response.data));
          localStorage.setItem("solHubPage",page);
          localStorage.setItem("solHubPageToRedirect",pageToRedirect);
          
        }
        reset();
        navigate(redirectTo);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="login"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-primary border-0 rounded-0">
            {loading && <Loader />}
            <div className="modal-body">
              <div className="spacer-12"></div>
              <span
                type="button"
                className="position-absolute end-0 top-0 m-4"
                onClick={() => hide()}
              >
                <img src={CloseBtnImg} alt="" />
              </span>
              <div className="row justify-content-center">
                <div className="col-sm-9">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(handleLogin)}
                    ref={form}
                  >
                    <div className="row">
                      <div className="col-sm-12 mb-4">
                        <input
                          type="text"
                          className="form-control form-control-lg fw-bold ps-4"
                          autoComplete="false"
                          name="email"
                          placeholder="Email"
                          {...register("email")}
                        />
                        {errors.email && <span>{errors.email.message}</span>}
                      </div>
                      <div className="col-sm-12">
                        <input
                          type="password"
                          className="form-control form-control-lg fw-bold ps-4"
                          name="password"
                          placeholder="Password"
                          {...register("password")}
                        />
                        {errors.password && (
                          <span>{errors.password.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="spacer-4"></div>
                      <div className="col-sm-6">
                        <div className="entry-action d-flex flex-column">
                          {/* <a href="#" className="lh-sm text-decoration-none text-secondary fs-18">Forgot Password</a>
                          <a href="#" className="lh-sm text-decoration-none text-secondary fs-18">Can't login? Click here to register</a> */}
                        </div>
                      </div>
                      <div className="col-sm-6 text-center text-md-end mt-4 mt-md-0">
                        <button className="btn btn-outline-white btn-default fs-22 btn-block d-md-inline-block">
                          Enter
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="spacer-10"></div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};
export default CoPayLoginModal;
