import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import { useEffect, useRef, useState } from "react";
import { formatDate } from "../../../common/commonFun";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AdminAlliedInvoceEntrySchema } from "../../ValidationForm/validationSchema";
import AdminService from "../../../services/AdminService";
import { toast } from "react-toastify";
import { useToast } from "../../../common/ToastContext";
import Loader from "../../../common/Loader";
import SupportInvoiceService from "../../../services/SupportInvoiceService";
import IqviaService from "../../../services/IqviaService";

const AdminAlliedInvoiceEntryComponent = () => {
  const navigate = useNavigate();
  const supportPatientId = localStorage.getItem("adminSearchSupportPatientId");

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await AdminService.adminGetSupportPatientDetails(supportPatientId);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-support-portal");
    }
  };

  const invoiceDetail = localStorage.getItem("alliedInvoiceDetail");
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [invoiceData] = useState(JSON.parse(invoiceDetail));
  const [practitionerSuburbOptions, setPractitionerSuburbOptions] = useState([]);
  const [practitionerNameOptions, setPractitionerNameOptions] = useState([]);
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors }, setValue,
    reset,
    watch,
  } = useForm({ resolver: yupResolver(AdminAlliedInvoceEntrySchema) });

  const selectedAlliedHealthType = watch("alliedHealthType");
  const selectedPractitionerSuburb= watch("practitionerSuburb");

  const findByStatePractitioner = (value) => {
    setLoading(true);
    setPractitionerSuburbOptions([]);
    IqviaService.findByPractitionerType({ selectedType: selectedAlliedHealthType }).then(
      (response) => {
        setLoading(false);
        setPractitionerSuburbOptions(response.data.Suburbs);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };
  const selectedSuburbPractitioner = (value) => {
    setLoading(true);
    setPractitionerNameOptions([]);
    IqviaService.findBySuburb({ City: value }).then(
      (response) => {
        setLoading(false);
        setPractitionerNameOptions(response.data.Names);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  
  useEffect(() => {
    if (selectedAlliedHealthType) {
      setValue('practitionerName', '')
      setValue('practitionerSuburb', '')
      findByStatePractitioner(selectedAlliedHealthType);
    }
  }, [selectedAlliedHealthType]);

  useEffect(() => {
    if (selectedPractitionerSuburb) {
      selectedSuburbPractitioner(selectedPractitionerSuburb);
    }
  }, [selectedPractitionerSuburb]);

  const handleInvoice = async (data) => {
    data.isSubmitStatus = true;
    data.id = invoiceData.id;
    setLoading(true);

    SupportInvoiceService.updateAllied(data).then(
      (response) => {
        reset();
        setLoading(false);
        showToast(response.data.message);
        navigate("/solutionaryhub-admin-support-portal/patient-dashboard");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <AdminHeader />
      {loading && <Loader />}
      {invoiceData ? (
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1 className="text-uppercase">ALLIED HEALTH SERVICE INVOICE ENTRY</h1>
              </div>
            </div>
            <div className="spacer-3 spacer-lg-6"></div>

            <form
              className="custom-form patient-support"
              autoComplete="off"
              onSubmit={handleSubmit(handleInvoice)}
              ref={form}
            >
              <div className="row gx-lg-5">
                <div className="col-md-6 d-flex  align-items-center mb-2 mb-lg-0">
                  <label htmlFor="" className="form-label me-2 mb-0">
                    Invoice Id:
                  </label>
                  <p className="text-dark-gray fw-bold mb-0">
                    {" "}
                    {invoiceData.id}
                  </p>
                </div>
                <div className="col-md-6 d-flex flex-column flex-lg-row align-items-lg-center">
                  <label htmlFor="" className="form-label me-2 mb-md-0">
                    Click to view invoice:
                  </label>
                  <a
                    href={invoiceData.filename}
                    target="_blank"
                    className="btn btn-default btn-blue btn-block d-md-inline-block"
                  >
                    show
                  </a>
                  <div className="col-sm-12 text-center text-md-start"></div>
                </div>
              </div>
              <div className="spacer-3 spacer-lg-2"></div>
              <div className="row gx-lg-5">
                <div className="col-md-6 d-flex  align-items-center mb-2 mb-lg-0">
                  <label htmlFor="" className="form-label me-2 mb-0">
                    Submission Date:
                  </label>
                  <p className="text-dark-gray fw-bold mb-0">
                    {" "}
                    {formatDate(invoiceData.invoiceDate)}
                  </p>
                </div>
                <div className="col-md-6 d-flex flex-column flex-lg-row align-items-lg-center validated-input">
                  <label htmlFor="" className="form-label me-2 mb-md-0">
                    Supply Date:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="supplyDate"
                    id="supplyDate"
                    {...register("supplyDate")}
                  />
                  {errors.supplyDate && (
                    <span>{errors.supplyDate.message}</span>
                  )}
                </div>
              </div>
              <div className="spacer-3 spacer-lg-2"></div>
              <div className="row gx-lg-5">
                <div className="col-md-6 mb-4 mb-lg-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="fs-22 mb-3">Allied Health</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <select
                        name="alliedHealthType"
                        id="alliedHealthType"
                        className="form-select drop-blue"
                        {...register("alliedHealthType")}>

                        <option value="">Allied Health Type</option>
                        <option value="Physiotherapist" selected> Physiotherapist</option>
                        <option value="Dietician">Dietician</option>
                        <option value="Occupational Therapist">Occupational Therapist</option>
                      </select>
                    </div>
                    <div className="spacer-1"></div>
                    <div className="col-sm-12">
                    <select
                        name="practitionerSuburb"
                        id="practitionerSuburb"
                        className="form-select drop-blue"
                        {...register("practitionerSuburb")}
                      >
                        <option value="">Select Practitioner Suburb</option>
                        {practitionerSuburbOptions.length !== 0
                          ? practitionerSuburbOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                          : ""}
                      </select>
                      {errors.practitionerSuburb && <span>{errors.practitionerSuburb.message}</span>}

                    </div>
                    <div className="spacer-1"></div>
                    <div className="col-sm-12">
                    <select
                        name="practitionerName"
                        id="practitionerName"
                        className="form-select drop-blue"
                        {...register("practitionerName")}
                      >
                        <option value="">Select Practitioner Name</option>
                        {practitionerNameOptions.length !== 0
                          ? practitionerNameOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                          : ""}
                      </select>
                      {errors.practitionerName && <span>{errors.practitionerName.message}</span>}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="spacer-2"></div>
                  <div className="row mt-lg-4 mt-md-4 mt-sm-0">
                    <div className="col-sm-12 d-flex align-items-center validated-input">
                      <label htmlFor="" className="form-label me-2 mb-0">
                        Amount paid:
                      </label>
                      <div className="position-relative w-100 amount-paid">
                        <input
                          type="text"
                          className="form-control ps-4"
                          name="amount"
                          id="amount"
                          {...register("amount")}
                        />
                        {errors.amount && <span>{errors.amount.message}</span>}
                        <span className="position-absolute start-0 top-0 py-2 px-3 text-dark-gray">
                          $
                        </span>
                      </div>
                    </div>
                    <div className="spacer-2"></div>
                    <div className="col-sm-12">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="isAlliedHealthTaxInvoice"
                          id="isAlliedHealthTaxInvoice"
                          {...register("isAlliedHealthTaxInvoice")}
                        />
                        <label
                          className="form-check-label ps-2"
                          htmlFor="isAlliedHealthTaxInvoice"
                        >
                          Allied Health Tax Invoice
                        </label>
                      </div>
                      {errors.isAlliedHealthTaxInvoice && (
                        <span>{errors.isAlliedHealthTaxInvoice.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="spacer-3"></div>
                  <div className="row mt-lg-5  mt-md-5 mt-sm-2">
                    <div className="col-sm-12 text-center text-md-start">
                      <button className="btn btn-default btn-blue btn-block d-md-inline-block">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </main>
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

export default AdminAlliedInvoiceEntryComponent;
