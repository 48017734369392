import { Link } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import { useEffect, useState } from "react";
import AdminService from "../../../services/AdminService";
import CopayHistoryService from "../../../services/CopayHistoryService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import SupportInvoiceService from "../../../services/SupportInvoiceService";
import moment from 'moment';

const AdminSupportPatientDashboardComponent = () => {
  const adminSearchSupportPatientId = localStorage.getItem("adminSearchSupportPatientId");
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [alliedInvoiceData, setAlliedInvoiceData] = useState([]);
  const [pharmacyInvoiceData, setPharmacyInvoiceData] = useState([]);
  
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchinvoiceData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await AdminService.adminGetSupportPatientDetails(
        adminSearchSupportPatientId
      );
      setPatientData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };
  const fetchinvoiceData = async () => {
    try {
      const response = await SupportInvoiceService.getAdminSupportInvoice(
        adminSearchSupportPatientId
      );
      setPharmacyInvoiceData(response.data.pharmacyInvoiceData);
      setAlliedInvoiceData(response.data.alliedInvoiceData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };
  const handlePayBtn =(item)=>{
    const pharmacyItem = pharmacyInvoiceData.find((pitem) => pitem.uniqueInvoiceId === item.uniqueInvoiceId);
    const alliedItem = alliedInvoiceData.find((aitem) => aitem.uniqueInvoiceId === item.uniqueInvoiceId);
  
    if (pharmacyItem && alliedItem && pharmacyItem.isSubmitStatus && alliedItem.isSubmitStatus) {
      localStorage.setItem("alliedInvoiceDetail", JSON.stringify(item));
      navigate("/solutionaryhub-admin-support-portal/allied-bank-details");
    } else {
      toast.error('Please add both invoice.')
    }
  }

  return (
    <>
      <>
        <AdminHeader />
        {loading && <Loader />}
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-md-8 col-lg-5">
                <table className="table table-borderless fs-18">
                  <thead>
                    <tr>
                      <th width="60%" className="fw-bold text-blue">
                        Patient Id
                      </th>
                      <th width="40%" className="fw-bold text-blue">
                        {patientData.id}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-dark-gray">Name:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.firstname} {patientData.lastname}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">Mobile:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.mobile}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="spacer-2"></div>
            <div className="row">
              <div className="col-sm-12">
              <h1 className="text-uppercase mb-3">ALLIED HEALTH SERVICE  INVOICE ENTRY</h1>
                <div className="table-padding">
                  <table className="table table-style-2 table-bordered text-nowrap">
                    <thead className="table-blue">
                      <tr className="text-center">
                        <th>Number<br/> &nbsp;</th>
                        <th>Invoice <br/>ID</th>
                        <th>Date <br/>Received</th>
                        <th>Supply <br/>Date</th>
                        <th>Allied<br/>Health Type</th>
                        <th>Practitioner<br/> Name</th>
                        <th>Practitioner <br/>Suburb</th>
                        <th>Amount <br/>Paid</th>
                        <th>Rebate<br/> Paid</th>
                        <th>Action<br/> &nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alliedInvoiceData.length !== 0 ? (
                        alliedInvoiceData.map((item, i) => {
                          return (
                            <tr key={i+1}>
                              <td className="text-end" valign="middle">
                                {i+1}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.id}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.invoiceDate && moment(item.invoiceDate).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                               {item.supplyDate && moment(item.supplyDate).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.alliedHealthType}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.practitionerName}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.practitionerSuburb}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.amount && (
                                  <span className="float-start">$</span>
                                )}{" "}
                                {item.amount}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.rebatePaid && moment(item.rebatePaid).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.isSubmitStatus === false && (
                                  <Link
                                    className="btn btn-red-maroon btn-sm btn-default"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "alliedInvoiceDetail",
                                        JSON.stringify(item)
                                      );
                                    }}
                                    to="/solutionaryhub-admin-support-portal/allied-invoice-entry"
                                  >
                                    Invoice 
                                  </Link>
                                )}
                                {!item.rebatePaid && (
                                  <>
                                    &nbsp;
                                    <span className="btn btn-outline-blue btn-sm btn-default" onClick={()=>handlePayBtn(item)}>
                                      Pay
                                    </span>
                                  </>
                                )}
                                {item.isSubmitStatus && item.rebatePaid && "COMPLETE"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            className="text-center"
                            valign="middle"
                            colSpan={12}
                          >
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="spacer-6"></div>
            <div className="row">
              <div className="col-sm-12">
              <h1 className="text-uppercase mb-3">PHARMACY INVOICE ENTRY</h1>
                <div className="">
                  <table className="table table-style-2 table-bordered text-nowrap">
                    <thead className="table-blue">
                      <tr className="text-center">
                        <th>Number <br/> &nbsp;</th>
                        <th>Invoice<br/> ID</th>
                        <th>Date <br/>Received</th>
                        <th>Supply<br/> Date</th>
                        <th>Pharmacy<br/> Name</th>
                        <th>Pharmacy <br/>Suburb</th>
                        <th>Pharmacy<br/> State</th>
                        <th>Amount<br/> Paid</th>
                        <th>Action<br/> &nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pharmacyInvoiceData.length !== 0 ? (
                        pharmacyInvoiceData.map((item, i) => {
                          return (
                            <tr key={i+1}>
                              <td className="text-end" valign="middle">
                                {i+1}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.id}
                              </td>
                              <td className="text-end" valign="middle">
                               {item.invoiceDate && moment(item.invoiceDate).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                              {item.supplyDate && moment(item.supplyDate).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.pharmacyName}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.pharmacySuburb}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.pharmacyState}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.amount && (
                                  <span className="float-start">$</span>
                                )}{" "}
                                {item.amount}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.isSubmitStatus === false && (
                                  <Link
                                    className="btn btn-red-maroon btn-sm btn-default"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "pharmacyInvoiceDetail",
                                        JSON.stringify(item)
                                      );
                                    }}
                                    to="/solutionaryhub-admin-support-portal/pharmacy-invoice-entry"
                                  >
                                    Invoice 
                                  </Link>
                                )}
                                {item.isSubmitStatus && "COMPLETE"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            className="text-center"
                            valign="middle"
                            colSpan={12}
                          >
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="spacer-2"></div>
            <div className="spacer-6"></div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <Link
                  className="btn btn-default btn-blue"
                  to="/solutionaryhub-admin-support-portal/patient-overview"
                >
                  Close
                </Link>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    </>
  );
};

export default AdminSupportPatientDashboardComponent;
