import React from "react";
import CoPayLoginModal from "../modal/CoPayLoginModal";
import useModal from "../modal/useModal";
import CoPayIconImg from "../../images/co-pay-icon.png";

const Copay = () => {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <CoPayLoginModal isShowing={isShowing} hide={toggle} pageToRedirect="/co-pay-welcome" page="isCopay"  />

      <div className="card bg-teal rounded-0 border-0 text-center p-4">
        <div className="entry-title">
          <img src={CoPayIconImg} alt="" className="img-fluid entry-icon" />
          <h3 className="fw-bold text-white mt-2">Co-Pay <br/>Private Market</h3>
        </div>
        <div className="entry-btn mt-auto">
          <a
            className="btn btn-outline-white btn-default fs-18 text-uppercase"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            href="#"
          >
            Enter
          </a>
        </div>
      </div>
    </>
  );
};
export default Copay;
