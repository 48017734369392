import React, { useState, useRef, useEffect } from "react";
import AdminService from "../../services/AdminService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AdminLoginSchema } from "../ValidationForm/validationSchema";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import LogoInnerImg from "../../images/logo.svg";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";

const AdminLoginComponent = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const adminAccessToken = localStorage.getItem("adminAccessToken");
    if (adminAccessToken) {
      navigate("/solutionaryhub-admin-portal/choice");
    }
  }, [navigate]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(AdminLoginSchema),
  });

  const handleLogin = (data) => {
    setLoading(true);

    AdminService.adminLogin(data).then(
      (response) => {
        setLoading(false);
        toast.success(response.data.message);

        localStorage.setItem("adminId", response.data.id);
        localStorage.setItem(
          "adminAccessToken",
          response.data.adminAccessToken
        );
        reset();
        navigate("/solutionaryhub-admin-portal/choice");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <header>
        <div className="spacer-5"></div>
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <img
                src={LogoInnerImg}
                className="w-50 img-fluid logo-main"
                alt=""
              />
            </div>
          </div>
        </section>
      </header>
      {loading && <Loader />}
      <main className="front-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="text-uppercase">
                Welcome to the <br /> Solutionary Health Hub
              </h1>
            </div>
          </div>
          <div className="spacer-3"></div>

          <div className="row justify-content-center">
            <div className="col-sm-9">
              <form
                autoComplete="off"
                className="custom-form"
                onSubmit={handleSubmit(handleLogin)}
                ref={form}
              >
                <div className="row">
                  <div className="col-sm-12 mb-4">
                    <input
                      type="text"
                      className="form-control form-control-sm fw-bold ps-3"
                      placeholder="EMAIL"
                      autoComplete="false"
                      name="email"
                      {...register("email")}
                    />
                    {errors.email && <span>{errors.email.message}</span>}
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="password"
                      className="form-control form-control-sm fw-bold ps-3"
                      placeholder="PASSWORD"
                      name="password"
                      {...register("password")}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                  </div>
                </div>
                <div className="spacer-4"></div>
                <div className="row">
                  <div className="col-sm-12 text-center mt-4 mt-md-0">
                    <button className="btn btn-primary btn-default text-white fs-22 text-uppercase rounded-2">
                      Enter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AdminLoginComponent;
