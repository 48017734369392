import authHeader from "./auth-header";
import axios from "axios";

const findBySmsText = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/sms/getSmsText`,data,{ headers: authHeader() });
};
const sendSMS = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/sms/sendSMS`,data,{ headers: authHeader() });
};
const smsTemplateService = {
  findBySmsText,
  sendSMS
};

export default smsTemplateService;
