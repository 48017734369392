import React, { useState } from "react";
import useModal from "../../modal/useModal";
import CloseBtnImg from "../../../images/Close_Btn.svg";
import InvoiceAliiedHelthIcon from "../../../images/icon-health-service-invoice.png";
import InvoicePharmacyIcon from "../../../images/icon-pharmacy-invoice.png";
import { MAX_FILE_SIZE } from "../../../common/constants";
import { toast } from "react-toastify";
import Loader from "./../../../common/Loader";
import axios from "axios";
import SupportPharmacyInvoiceThankyouModal from "./SupportPharmacyInvoiceThankyouModal";
import SupportInvoiceService from "../../../services/SupportInvoiceService";
import { getEmailText } from "../../../common/utilities";
import emailService from "../../../services/EmailService";

const SupportPharmacyInvoiceModal = ({ isShowing, hide }) => {
  const { isShowing: isShowingThankyou, toggle: toggleThankyou } = useModal();
  const [alliedFile, setAlliedFile] = useState(null);
  const [pharmacyFile, setPharmacyFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const supportPatientId = localStorage.getItem("supportPatientId");
  const supportPatientMobile = localStorage.getItem("supportPatientMobile");
  const supportHcp = JSON.parse(localStorage.getItem("supportHcp"));
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!alliedFile && !pharmacyFile) {
      // setMessage('Please select a file.');
      toast.error("Please select a file.",{ autoClose: false, closeOnClick: false});
      return;
    } else if (!alliedFile || !pharmacyFile) {
      // setMessage('Please upload your second invoice.');
      toast.error("Please upload your second invoice.",{ autoClose: false, closeOnClick: false});
      return;
    }

    try {
      setLoading(true);
      const alliedFileType = alliedFile.type.split('/')[1];
      const pharmacyFileType = alliedFile.type.split('/')[1];

      await SupportInvoiceService.PreSign({ alliedFileType: alliedFileType, pharmacyFileType: pharmacyFileType, supportPatientId: supportPatientId }).then(async (response) => {

        const { presignedUrls } = response.data;

        const uploadAlliedPromise = axios.put(presignedUrls.allied, alliedFile, {
          headers: { 'Content-Type': alliedFileType === 'pdf' ? 'application/pdf' : alliedFileType },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Allied file upload progress: ${progress}%`);
          }
        });

        const uploadPharmacyPromise = axios.put(presignedUrls.pharmacy, pharmacyFile, {
          headers: { 'Content-Type': pharmacyFileType === 'pdf' ? 'application/pdf' : pharmacyFileType },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Pharmacy file upload progress: ${progress}%`);
          }
        });
        
        if(await Promise.all([uploadAlliedPromise, uploadPharmacyPromise])){
          let emailText = await getEmailText('email_support_invoice_upload',[{patinet_mobile:supportPatientMobile}])
          if (emailText) {
            emailService.sendEmail({from:'noreply@solutionaryhub.com.au',to:supportHcp.email, subject:emailText.subject,html:emailText.html,emailId:emailText.id});
          }
          hide();
          setAlliedFile(null);
          setPharmacyFile(null);
          toggleThankyou();
          setLoading(false);
        }
       
      })
      hide()
      toggleThankyou();

    } catch (err) {
      console.error('Error uploading file', err);
    }

  };

  const handleAlliedDrop = (e) => {
    const files = e.dataTransfer.files;

    if (files.length > 1) {
      // setMessage('Please select only one file.');
      toast.error("Please select only one file.",{ autoClose: false, closeOnClick: false});
      return;
    }

    const file = files[0];
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    // Allowed file types
    const allowedFileTypes = ['png', 'jpeg', 'jpg', 'pdf', 'gif'];

    if (!allowedFileTypes.includes(fileExtension)) {
      // setMessage('Invalid file type. Only PNG, JPEG, PDF, and GIF files are allowed.');
      toast.error("Invalid file type. Only PNG, JPEG, PDF, and GIF files are allowed.",{ autoClose: false, closeOnClick: false});
      return;
    }

    if (file.size <= MAX_FILE_SIZE) {
      setAlliedFile(file);
    } else {
      // setMessage(`File "${file.name}" exceeds the maximum allowed size of 20 MB.`)
      toast.error(`File "${file.name}" exceeds the maximum allowed size of 20 MB.`,{ autoClose: false, closeOnClick: false} );
    }
  };
  const handlePharmacyDrop = (e) => {
    const files = e.dataTransfer.files;

    if (files.length > 1) {
      // setMessage('Please select only one file.');
      toast.error("Please select only one file.",{ autoClose: false, closeOnClick: false});
      return;
    }

    const file = files[0];
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    // Allowed file types
    const allowedFileTypes = ['png', 'jpeg', 'jpg', 'pdf', 'gif'];

    if (!allowedFileTypes.includes(fileExtension)) {
      // setMessage('Invalid file type. Only PNG, JPEG, PDF, and GIF files are allowed.');
      toast.error("Invalid file type. Only PNG, JPEG, PDF, and GIF files are allowed.",{ autoClose: false, closeOnClick: false});
      return;
    }

    if (file.size <= MAX_FILE_SIZE) {
      setPharmacyFile(file);
    } else {
      // setMessage(`File "${file.name}" exceeds the maximum allowed size of 20 MB.`)
      toast.error(`File "${file.name}" exceeds the maximum allowed size of 20 MB.`,{ autoClose: false, closeOnClick: false});
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleAlliedIconClick = () => {
    document.getElementById("alliedfileInput").click();
  };
  const handlePharmcyIconClick = () => {
    document.getElementById("pharmacyfileInput").click();
  };

  const handleAlliedInputChange = (e) => {
    setMessage('')
    const file = e.target.files[0];
    handleAlliedDrop({ dataTransfer: { files: [file] } });
  };
  const handlePharmacyInputChange = (e) => {
    setMessage('')
    const file = e.target.files[0];
    handlePharmacyDrop({ dataTransfer: { files: [file] } });
  };

  return (
    <>
      <SupportPharmacyInvoiceThankyouModal
        isShowing={isShowingThankyou}
        hide={toggleThankyou}
      />
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="uploadInvoice"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content bg-blue border-0 rounded-0">
            {loading && <Loader />}
            <div className="modal-body">
              <div className="spacer-5"></div>
              <span
                type="button"
                className="position-absolute end-0 top-0 m-4"
                onClick={() => {
                  hide();
                  setAlliedFile(null);
                  setPharmacyFile(null);
                }}
              >
                <img src={CloseBtnImg} alt="" />
              </span>
              <div className="row justify-content-center">
                <div className="col-sm-9">
                  <h2 className="fw-bold text-white text-center fs-26">
                    Each month you are eligible for support for one Allied Health Service whilst on treatment.
                  </h2>
                  <h2 className="fw-bold text-white text-center fs-26 pt-3">
                    You will need to upload a clear photo or scan of your Allied Health service invoice; AND
                    a clear photo or scan of your pharmacy invoice.
                  </h2>

                  <div className="spacer-3"></div>
                </div>
              </div>
              <div className="row justify-content-center gx-8">
                <div className="width-lg-45" onClick={handleAlliedIconClick}>
                  <div className="border border-white border-3 rounded-3 d-flex align-items-center justify-content-center p-3 text-decoration-none my-3" onDrop={handleAlliedDrop} onDragOver={handleDragOver}>
                    <div className="button-icon me-3">
                      <img src={InvoicePharmacyIcon} className="img-fluid" alt="" />
                    </div>
                    <div className="button-title">
                      <p className="fs-26 fw-bold text-white lh-sm mb-0 ps-4">Upload <br />Pharmacy <br />Invoice</p>
                    </div>
                  </div>
                  <input type="file"
                    id="alliedfileInput"
                    style={{ display: "none" }}
                    onChange={handleAlliedInputChange} />
                </div>
                <div className="width-lg-45" onDrop={handlePharmacyDrop} onDragOver={handleDragOver} onClick={handlePharmcyIconClick}>
                  <div className="border border-white border-3 rounded-3 d-flex align-items-center justify-content-center p-3 text-decoration-none my-3">
                    <div className="button-icon">
                      <img src={InvoiceAliiedHelthIcon} className="img-fluid" alt="" />
                    </div>
                    <div className="button-title">
                      <p className="fs-26 fw-bold text-white lh-sm mb-0 ps-4">Upload <br />Allied Health  <br />Service Invoice</p>
                    </div>
                  </div>
                  <input type="file"
                    id="pharmacyfileInput"
                    style={{ display: "none" }}
                    onChange={handlePharmacyInputChange} />
                </div>
              </div>
              <div className="spacer-3"></div>
              <div className="row justify-content-center text-white">
                <div className="col-sm-9 text-center">
                  <div className="entry-action d-flex flex-column text-center">
                    <span className="mb-0 lh-sm fs-18">
                      Drag and drop a file here or click the icon to upload.
                      <br />
                      Maximum size of file: 20 MB. File types accepted: pdf,
                      jpeg, gif, png
                    </span>
                  </div>
                  <div className="fs-18 mb-3">
                    {alliedFile && "Filename : " + alliedFile.name} <br />
                    {pharmacyFile && pharmacyFile.name}
                  </div>
                  {/* <div className="text-white text-center fs-18">{message}</div> */}
                  <div className="spacer-3"></div>
                  <span
                    className="btn btn-outline-white btn-default fs-22"
                    onClick={handleSubmit}
                  >
                    Submit Invoice
                  </span>
                </div>
              </div>
              <div className="spacer-5"></div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};

export default SupportPharmacyInvoiceModal;
