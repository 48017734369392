import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import AdminService from "../../../services/AdminService";
import { AdminBankDetailsSchema } from "../../ValidationForm/validationSchema";
import { toast } from "react-toastify";
import { useToast } from "../../../common/ToastContext";
import Loader from "../../../common/Loader";
import SupportPatientService from "../../../services/SupportPatientService";

const AdminAlliedBankDetailsComponent = () => {
  const navigate = useNavigate();
  const adminSearchSupportPatientId = localStorage.getItem("adminSearchSupportPatientId");
  const [loading, setLoading] = useState(false);
  const [patientBankDetailsData, setPatientBankDetailsData] = useState({});
  const form = useRef();
  const invoiceDetail = localStorage.getItem("alliedInvoiceDetail");

  //const [invoiceData, setinvoiceData] = useState(JSON.parse(invoiceDetail));
  const alliedId = JSON.parse(invoiceDetail).id;
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { alliedId: alliedId },
    resolver: yupResolver(AdminBankDetailsSchema),
  });

  useEffect(() => {
    setLoading(true);
    // fetchData();
    fetchPatientBankDetailsData();
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await AdminService.adminGetSupportPatientDetails(
  //       adminSearchSupportPatientId
  //     );

  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     navigate("/solutionaryhub-admin-portal");
  //   }
  // };
  const fetchPatientBankDetailsData = async () => {
    try {
      const response = await SupportPatientService.getSupportPatientBankDetails(
        adminSearchSupportPatientId
      );

      setLoading(false);
      setPatientBankDetailsData(response.data.patientBankDetailsData);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };

  const handlePatientPayment = async (data) => {
    setLoading(true);
    AdminService.adminAlliedMakePatientPayment(data).then(
      (response) => {
        setLoading(false);
        showToast(response.data.message);
        reset();
        navigate("/solutionaryhub-admin-support-portal/patient-dashboard");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        reset();
        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <AdminHeader />
      {loading && <Loader />}
      {patientBankDetailsData ? (
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1 className="text-uppercase">Bank Details</h1>
              </div>
            </div>
            <div className="spacer-3 spacer-lg-6"></div>
            <form
              autoComplete="off"
              className="custom-form patient-support"
              onSubmit={handleSubmit(handlePatientPayment)}
              ref={form}
            >
              <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-6">
                  <div className="row">
                    <div className="width-sm-30 d-inline-flex align-items-center">
                      <label htmlFor="" className="form-label mb-0">
                        Account Name:
                      </label>
                    </div>
                    <div className="width-sm-70">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Account Name"
                        value={patientBankDetailsData.accName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row">
                    <div className="width-sm-30 d-inline-flex align-items-center">
                      <label htmlFor="" className="form-label mb-0">
                        BSB:
                      </label>
                    </div>
                    <div className="width-sm-70">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="BSB"
                        value={patientBankDetailsData.bsb}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="spacer-2"></div>
                  <div className="row">
                    <div className="width-sm-30 d-inline-flex align-items-center">
                      <label htmlFor="" className="form-label mb-0">
                        Account Number:
                      </label>
                    </div>
                    <div className="width-sm-70">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Account Number"
                        value={patientBankDetailsData.accNumber}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="spacer-3 spacer-lg-6"></div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="makePayment"
                          {...register("makePayment")}
                        />
                        <label
                          className="form-check-label ps-2"
                          htmlFor="flexRadioDisabled"
                        >
                          Make Payment
                        </label>
                      </div>
                      {errors.makePayment && (
                        <span>{errors.makePayment.message}</span>
                      )}
                    </div>
                    <div className="col-6">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          name="payment"
                          {...register("payment")}
                        />
                        <label
                          className="form-check-label ps-2"
                          htmlFor="flexRadioDisabled"
                        >
                          Payment $50
                        </label>
                      </div>
                      {errors.payment && <span>{errors.payment.message}</span>}
                    </div>
                  </div>
                  <div className="spacer-6"></div>
                  <div className="row justify-content-center">
                    <div className="col-sm-4 text-center">
                      <button className="btn btn-default btn-blue btn-block d-md-inline-block w-100">
                      CONFIRM
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </main>
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

export default AdminAlliedBankDetailsComponent;
