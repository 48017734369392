import authHeader from "./auth-header";
import axios from "axios";

const patientLogin = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/copay/patient-signin", data,{ headers: authHeader() });
};

const getPatientStatus = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/patient/${id}`);
};

const getPatientDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/patient-details/${id}`, { headers: authHeader() });
};

// Auth Token
const createPatientEnroll = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/copay/patient-enroll", data, { headers: authHeader() });
};

const createPatientBankDetails = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/copay/patient-bank-details", data);
};

// Auth Token
const requestToUpdatePatientMobile = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/copay/request-patient-update-mobile/${id}`, data, {
    headers: authHeader(),
  });
};
const verifyPatientMobileNumber = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/verify-patient-mobile-number/${tokenId}`);
};
const notVerifyPatientMobileNumber = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/not-verify-patient-mobile-number/${tokenId}`);
};

const getPatientBankDetails = (patientId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/patient-bank-details/${patientId}`);
};
// Auth Token
const requestToUpdatePatientBankDetails = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/copay/request-patient-update-bank-details/${id}`, data, {
    headers: authHeader(),
  });
};
const verifyPatientBankDetails = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/verify-patient-bank-details/${tokenId}`);
};
const notVerifyPatientBankDetails = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/not-verify-patient-bank-details/${tokenId}`);
};

// Auth Token
const requestToUpdatePatientPassword = (id, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/copay/request-patient-update-password/${id}`, data, {
    headers: authHeader(),
  });
};
const verifyPatientPassword = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/verify-patient-password/${tokenId}`);
};
const notVerifyPatientPassword = (tokenId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/copay/not-verify-patient-password/${tokenId}`);
};

// Auth Token
const PatientContactUs = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/copay/patient-contact-us", data, {
    headers: authHeader(),
  });
};

const PatientService = {
  patientLogin,
  getPatientStatus,
  getPatientDetails,
  createPatientEnroll,
  createPatientBankDetails,
  requestToUpdatePatientMobile,
  verifyPatientMobileNumber,
  notVerifyPatientMobileNumber,
  getPatientBankDetails,
  requestToUpdatePatientBankDetails,
  verifyPatientBankDetails,
  notVerifyPatientBankDetails,
  requestToUpdatePatientPassword,
  verifyPatientPassword,
  notVerifyPatientPassword,
  PatientContactUs,
};

export default PatientService;
