import axios from "axios";


const findByState = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/solIqvia/findByState`,data);
};
const findByPractitionerType = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/solIqvia/findByPractitionerType`,data);
};
const findBySuburb = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/solIqvia/findBySuburb`,data);
};

const IqviaService = {
  findByState,
  findBySuburb,
  findByPractitionerType,
};

export default IqviaService;
