import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import PatientService from "../../services/PatientService";
import CopayPatientHeader from "../CopayPatientHeader";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import {
  minLengthRegex,
  capitalLetterRegex,
  numberRegex,
} from "../../common/commonFun";
import { getSMSText } from "../../common/utilities";
import { ABSOLUTE_URL } from "../../common/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { CoPayPatientMyDetailsMobileValSchema } from "../ValidationForm/validationSchema";
import { CoPayPatientMyDetailsBankDetailsValSchema } from "../ValidationForm/validationSchema";
import { CoPayPatientMyDetailsPasswordValSchema } from "../ValidationForm/validationSchema";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";
import smsTemplateService from "../../services/smsTemplateService";
import PatientMessageModal from "../modal/Patient/PatientMessageModal";
import useModal from "../modal/useModal";

const CoPayPatientMyDetails = () => {
  const form = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isShowing: isShowMessage, toggle: toggleShowMessage } = useModal();
  const [message, setMessage] = useState('');
  const copayPatientId = localStorage.getItem("copayPatientId");
  const [displayBsb, setDisplayBsb] = useState('');
  const [displayMobile, setDisplayMobile] = useState('');
  const [displayAccountNumber, setDisplayAccountNumber] = useState('');

  const {
    handleSubmit: handleSubmitMobile,
    register: registerMobile,
    setValue: setValueMobile,
    formState: { errors: errorsMobile },
    reset: resetMobile,
  } = useForm({
    resolver: yupResolver(CoPayPatientMyDetailsMobileValSchema),
    defaultValues: { patientId: copayPatientId },
  });

  const {
    handleSubmit: handleSubmitBankDetails,
    register: registerBankDetails,
    setValue: setValueBankDetails,
    formState: { errors: errorsBankDetails },
    reset: resetBankDetails,
  } = useForm({
    resolver: yupResolver(CoPayPatientMyDetailsBankDetailsValSchema),
    defaultValues: { patientId: copayPatientId },
  });
  
  const handleChangeMobile = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, '');
    let formattedInput = input.slice(0, 10);

    if (formattedInput.length === 10) {
      const firstPart = formattedInput.slice(0, 7);
      const secondPart = formattedInput.slice(7);
      setValueMobile("mobile", `${firstPart}${secondPart}`);
      setDisplayMobile(`xxxxxxx${secondPart}`); // Mask the first 3 digits
    } else {
      setDisplayMobile(formattedInput);
      setValueMobile("mobile", formattedInput);
    }

  };
  const handleChangeBsb = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, '');
    let formattedInput = input.slice(0, 6);

    if (formattedInput.length === 6) {
      const firstPart = formattedInput.slice(0, 3);
      const secondPart = formattedInput.slice(3);
      setValueBankDetails("bsb", `${firstPart}${secondPart}`);
      setDisplayBsb(`xxx-${secondPart}`); // Mask the first 3 digits
    } else {
      setDisplayBsb(formattedInput);
      setValueBankDetails("bsb", formattedInput);
    }

  };
  const handleChangeAccount = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, '');
    let formattedInput = input.slice(0, 6);

    if (formattedInput.length === 6) {
      const firstPart = formattedInput.slice(0, 3);
      const secondPart = formattedInput.slice(3);
      setValueBankDetails("accNumber", `${firstPart}${secondPart}`);
      setDisplayAccountNumber(`xxx-${secondPart}`); // Mask the first 3 digits
    } else {
      setDisplayAccountNumber(formattedInput);
      setValueBankDetails("accNumber", formattedInput);
    }
  };

  const {
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    watch: watchPassword,
    formState: { errors: errorsPassword },
    reset: resetPassword,
  } = useForm({
    resolver: yupResolver(CoPayPatientMyDetailsPasswordValSchema),
    defaultValues: { patientId: copayPatientId },
  });

  const password = watchPassword("password");
  const confirmPassword = watchPassword("confirmPassword");

  // States for Password Validation
  let [passwordValidation, setPasswordValidation] = useState({
    eightCharacters: false,
    capitalLetter: false,
    oneNumber: false,
    passwordMatch: false,
  });

  useEffect(() => {
    if (password || confirmPassword) {
      validatePassword(password, confirmPassword);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const bankDetailsResponse = await PatientService.getPatientBankDetails(
          copayPatientId
        );
        const detailsResponse = await PatientService.getPatientDetails(
          copayPatientId
        );

        localStorage.setItem(
          "copayPatientMobile",
          detailsResponse.data.data.mobile
        );

        const { patientBankDetailsData } = bankDetailsResponse.data;
        resetMobile();
        resetBankDetails();
        resetPassword();
        setValueMobile("mobile", localStorage.getItem("copayPatientMobile"));
        setValueBankDetails("accName", patientBankDetailsData.accName);
        setValueBankDetails("bsb", patientBankDetailsData.bsb);
        setValueBankDetails("accNumber", patientBankDetailsData.accNumber);
        setDisplayBsb(`xxx-${patientBankDetailsData.bsb.toString().slice(-3)}`);
        setDisplayMobile(`xxxxxxx${localStorage.getItem("copayPatientMobile").slice(-3)}`);
        setDisplayAccountNumber(`xxx-${patientBankDetailsData.accNumber.toString().slice(-3)}`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate("/");
      }
    };

    fetchData();
  }, [copayPatientId, navigate]);

  const validatePassword = (password, confirmPassword) => {
    if (!password && !confirmPassword) {
      setPasswordValidation(false);
      return;
    }

    setPasswordValidation({
      ...passwordValidation,
      eightCharacters: minLengthRegex.test(password) ? true : false,
      capitalLetter: capitalLetterRegex.test(password) ? true : false,
      oneNumber: numberRegex.test(password) ? true : false,
      passwordMatch: password === confirmPassword ? true : false,
    });
  };

  const handleUpdateMobile = (data) => {
    setLoading(true);

    PatientService.requestToUpdatePatientMobile(copayPatientId, data).then(
      async (response) => {
        setLoading(false);

        // toast.success(response.data.message);
        setMessage(response.data.message);
        toggleShowMessage();
        try {
          let link_confirm =
            ABSOLUTE_URL +
            "verify-co-pay-patient-mobile-number/" +
            response.data.data.token;
          let link_block =
            ABSOLUTE_URL +
            "not-verify-co-pay-patient-mobile-number/" +
            response.data.data.token;
          let smsText = await getSMSText("sms_update_copay_patient_mobile", [
            { patient_first_name: response.data.data.firstname },
            { link_confirm: link_confirm },
            { link_block: link_block },
          ]);
          // console.log(link_confirm,"link_confirm bank")
          if (smsText !== null && smsText !== undefined) {
            // smsTemplateService.sendSMS({to:"+918511367064", msg:smsText,userType:"copay-patient",userId:copayPatientId});
            smsTemplateService.sendSMS({ to: response.data.data.newMobile, msg: smsText, userType: "copay-patient", userId: copayPatientId });
          }
        } catch (error) {
          console.error("Error while fetching SMS text:", error);
          toast.error("Error while fetching SMS text:", error);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);

        toast.error(resMessage);
      }
    );
  };

  const handleUpdateBankDetails = (data) => {
    setLoading(true);

    PatientService.requestToUpdatePatientBankDetails(copayPatientId, data).then(
      async (response) => {
        setLoading(false);
        // toast.success(response.data.message);
        setMessage(response.data.message);
        toggleShowMessage();
        try {
          let link_confirm =
            ABSOLUTE_URL +
            "verify-co-pay-patient-bank-details/" +
            response.data.data.token;
          let link_block =
            ABSOLUTE_URL +
            "not-verify-co-pay-patient-bank-details/" +
            response.data.data.token;
          let smsText = await getSMSText("sms_update_copay_patient_bank", [
            { patient_first_name: response.data.data.firstname },
            { link_confirm: link_confirm },
            { link_block: link_block },
          ]);
          // console.log(link_confirm,"link_confirm mobile")
          if (smsText !== null && smsText !== undefined) {
            // smsTemplateService.sendSMS({to:"+918511367064", msg:smsText,userType:"copay-patient",userId:copayPatientId});
            smsTemplateService.sendSMS({ to: response.data.data.mobile, msg: smsText, userType: "copay-patient", userId: copayPatientId });
          }
        } catch (error) {
          console.error("Error while fetching SMS text:", error);
          toast.error("Error while fetching SMS text:", error);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  const handleUpdatePassword = (data) => {
    if (
      passwordValidation.eightCharacters === true &&
      passwordValidation.capitalLetter === true &&
      passwordValidation.oneNumber === true &&
      passwordValidation.passwordMatch === true
    ) {
      setLoading(true);
      PatientService.requestToUpdatePatientPassword(copayPatientId, data).then(
        async (response) => {
          setLoading(false);

          // toast.success(response.data.message);
          setMessage(response.data.message);
          toggleShowMessage();
          resetPassword();

          setPasswordValidation({
            ...passwordValidation,
            eightCharacters: false,
            capitalLetter: false,
            oneNumber: false,
            passwordMatch: false,
          });

          try {
            let link_confirm =
              ABSOLUTE_URL +
              "verify-co-pay-patient-password/" +
              response.data.data.token;
            let link_block =
              ABSOLUTE_URL +
              "not-verify-co-pay-patient-password/" +
              response.data.data.token;
            let smsText = await getSMSText("sms_update_copay_patient_password", [
              { patient_first_name: response.data.data.firstname },
              { link_confirm: link_confirm },
              { link_block: link_block },
            ]);

            if (smsText !== null && smsText !== undefined) {
              // smsTemplateService.sendSMS({to:"+918511367064", msg:smsText,userType:"copay-patient",userId:copayPatientId});
              smsTemplateService.sendSMS({ to: response.data.data.mobile, msg: smsText, userType: "copay-patient", userId: copayPatientId });
            }
          } catch (error) {
            console.error("Error while fetching SMS text:", error);
            toast.error("Error while fetching SMS text:", error);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);

          toast.error(resMessage);
          resetPassword();
        }
      );
    }
  };

  return (
    <>
      <PatientMessageModal isShowing={isShowMessage} hide={toggleShowMessage} message={message} />
      <CopayPatientHeader />
      {loading && <Loader />}
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-lg-10">
              <h1 className="text-uppercase">My Details</h1>
              <p className="fs-18 text-dark-gray lh-sm">
                Please check your details and update anything that has changed.
                Please have your phone nearby as we will be sending you an SMS
                to authenticate this information when you click CONFIRM.
              </p>
              <div className="spacer-1"></div>
            </div>
          </div>
          <div className="row gx-3">
            <div className="col-lg-10">
              <form
                autoComplete="off"
                className="custom-form"
                onSubmit={handleSubmitMobile(handleUpdateMobile)}
                ref={form}
              >
                <div className="row">
                  <div className="col-md-9 d-flex flex-column flex-md-row align-items-md-center mb-3">
                    <label htmlFor="" className="form-label me-3 mb-md-0">
                      Mobile:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number"
                      name="mobile"
                      value={displayMobile}
                      maxLength={10}
                      onChange={handleChangeMobile}
                    />
                  </div>
                  <div className="col-md-3">
                    <button className="btn btn-primary btn-default btn-block">
                      CONFIRM
                    </button>
                  </div>
                  {errorsMobile.mobile && (
                    <span>{errorsMobile.mobile.message}</span>
                  )}
                </div>
              </form>
              <form
                autoComplete="off"
                ref={form}
                className="custom-form"
                onSubmit={handleSubmitBankDetails(handleUpdateBankDetails)}
              >
                <div className="spacer-3"></div>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <h4 className="fs-22 text-uppercase text-dark-gray mb-2">
                      Current Bank Details
                    </h4>
                    <p className="fs-18 text-dark-gray lh-sm">
                      We pay your co-pay directly into your nominated bank
                      account. <br />
                      <br />
                      Please check the details and update anything that has
                      changed. Please have your phone nearby as we will be
                      sending you an SMS to authenticate this information when
                      you click CONFIRM.
                    </p>
                  </div>
                  <div className="col-md-8 mb-2">
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Name"
                          name="accName"
                          {...registerBankDetails("accName")}
                        />
                        {errorsBankDetails.accName && (
                          <p>{errorsBankDetails.accName.message}</p>
                        )}
                      </div>
                      <div className="col-sm-12 mb-2">
                        <input
                          type="text"
                          className="form-control"
                          value={displayBsb}
                          placeholder="BSB"
                          name="bsb"
                          maxLength={7}
                          onChange={handleChangeBsb}
                        />
                        {errorsBankDetails.bsb && (
                          <p>{errorsBankDetails.bsb.message}</p>
                        )}
                      </div>
                      <div className="col-sm-12 mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Number"
                          name="accNumber"
                          value={displayAccountNumber}
                          maxLength={7}
                          onChange={handleChangeAccount}
                        />
                        {errorsBankDetails.accNumber && (
                          <p>{errorsBankDetails.accNumber.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button className="btn btn-primary btn-default btn-block">
                      CONFIRM
                    </button>
                  </div>
                </div>
              </form>
              <form
                autoComplete="off"
                ref={form}
                className="custom-form"
                onSubmit={handleSubmitPassword(handleUpdatePassword)}
              >
                <div className="spacer-2"></div>
                <div className="row gx-2">
                  <div className="col-sm-12">
                    <h4 className="fs-18 fw-bold text-dark-gray mb-2">
                      Please set a password
                    </h4>
                  </div>
                  <div className="col-md-5 mb-2 mb-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      {...registerPassword("password")}
                    />
                    {errorsPassword.password && (
                      <p>{errorsPassword.password.message}</p>
                    )}
                  </div>
                  <div className="col-md-5 mb-3 mb-md-0">
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      {...registerPassword("confirmPassword")}
                    />
                    {errorsPassword.confirmPassword && (
                      <p>{errorsPassword.confirmPassword.message}</p>
                    )}
                  </div>
                  <div className="col-md-2">
                    <button className="btn btn-primary btn-default btn-block">
                      CONFIRM
                    </button>
                  </div>
                </div>
                <div className="spacer-2"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="mb-1 fs-18 text-dark-gray">
                      Please choose a secure password
                    </p>
                    <ul className="list-unstyled password-instructions text-dark-gray mb-0">
                      <li
                        className={
                          passwordValidation.eightCharacters ? "true" : "false"
                        }
                      >
                        At least 8 characters
                      </li>
                      <li
                        className={
                          passwordValidation.capitalLetter ? "true" : "false"
                        }
                      >
                        One capital letter
                      </li>
                      <li
                        className={
                          passwordValidation.oneNumber ? "true" : "false"
                        }
                      >
                        One number
                      </li>
                      <li
                        className={
                          passwordValidation.passwordMatch ? "true" : "false"
                        }
                      >
                        Password match
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default CoPayPatientMyDetails;