import React, { useEffect } from "react";
import HealthcareProffestionalImg from "../../images/healthcare-proffestional.jpg";
import PatientImg from "../../images/patient.jpg";
import Header from "../Header";
import Footer from "../Footer";
import useModal from "../modal/useModal";
import { Link, useNavigate } from "react-router-dom";
import HcpLoginModal from "../modal/Hcp/HcpLoginModal";
import HcpSignupModal from "../modal/Hcp/HcpSignupModal";
import HcpThankyouModal from "../modal/Hcp/HcpThankyouModal";
import PatientLoginModal from "../modal/Hcp/PatientLoginModal";

const CoPayWelcome = () => {
  const { isShowing: isShowingLogin, toggle: toggleLogin } = useModal();
  const { isShowing: isShowingPatientLogin, toggle: togglePatinetLogin } = useModal();
  const { isShowing: isShowingRegister, toggle: toggleRegister } = useModal();
  const { isShowing: isShowingThankyou, toggle: toggleThankyou } = useModal();
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("copayhcpToken")) {
      navigate("/co-pay-hcp-enrol");
    }

    if (localStorage.getItem("copayPatientToken")) {
      navigate("/co-pay-patient-dashboard");
    }
  }, []);

  return (
    <>
      <HcpLoginModal isShowing={isShowingLogin} hide={toggleLogin} />
      <PatientLoginModal isShowing={isShowingPatientLogin} hide={togglePatinetLogin} />
      <HcpSignupModal isShowing={isShowingRegister} hide={toggleRegister} />

      <HcpThankyouModal isShowing={isShowingThankyou} hide={toggleThankyou} />

      <Header />
      <main className="inner-page co-pay">
        <section className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="text-uppercase">WELCOME TO OUR CO-PAY AND PRIVATE MARKET MODEL</h1>
              <div className="spacer-2"></div>
              <p className="fs-20 lh-sm">
                An innovative, patient centric rebate model that enables efficient and effective cost sharing between the company and patients.
                Ideal when patients meet the TGA requirements, but do not qualify for PBS listing or for a Private Market. This is scalable, sustainable, national, 
                compliant & auditable, and free of supply chain and stock on shelf challenges that more traditional models may experience.
              </p>
              <div className="spacer-5"></div>
            </div>
          </div>
          <div className="row gx-4">
            <div className="col-md-6 mb-3 mb-xl-0">
              <div
                className="card rounded-0 border-0 p-4"
                style={{
                  backgroundImage: `url(${HealthcareProffestionalImg})`,
                }}
              >
                <div className="entry-content d-flex px-1 flex-column h-100">
                  <div className="entry-title">
                    <div className="spacer-3"></div>
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="fw-bold text-white">
                          Healthcare <br /> Professionals{" "}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="entry-action mt-auto">
                    <div className="row">
                      <div className="width-lg-30">
                        <span
                          className="btn btn-primary btn-default btn-block border-white border-1"
                          onClick={(e) => toggleLogin()}
                        >
                          Login
                        </span>
                      </div>
                      <div className="spacer-2"></div>
                      <div className="width-lg-30">
                        <span
                          className="btn btn-primary btn-default btn-block border-white border-1"
                          onClick={(e) => toggleRegister()}
                        >
                          Register
                        </span>
                      </div>
                    </div>
                    <div className="spacer-2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-xl-0">
              <div
                className="card rounded-0 border-0 p-4"
                style={{ backgroundImage: `url(${PatientImg})` }}
              >
                <div className="entry-content d-flex px-1 flex-column h-100">
                  <div className="entry-title">
                    <div className="spacer-3"></div>
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="fw-bold text-white">Patients </h2>
                      </div>
                    </div>
                  </div>
                  <div className="entry-action mt-auto">
                    <div className="row">
                      <div className="width-lg-30">
                        <span
                           onClick={(e) => togglePatinetLogin()}
                          className="btn btn-primary btn-default btn-block border-white border-1"
                        >
                          Login
                        </span>
                      </div>
                    </div>
                    <div className="spacer-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-10"></div>
            <div className="row">
               <div className="col-sm-12 text-center">
               <Link to="/co-pay-dashboard" className="btn btn-primary btn-default">METRICS</Link>
               </div>
            </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default CoPayWelcome;
