import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import HcpService from "../../services/HcpService";
import CopayHeader from "../CopayHeader";
import Footer from "../Footer";
import Loader from "./../../common/Loader";

import {
  minLengthRegex,
  capitalLetterRegex,
  numberRegex,
} from "../../common/commonFun";
import { yupResolver } from "@hookform/resolvers/yup";
import { HcpDetailsValSchema } from "../ValidationForm/validationSchema";

const HcpDetails = () => {
  const form = useRef();
  const copayhcpId = localStorage.getItem("copayhcpId");
  const copayhcpEmail = localStorage.getItem("copayhcpEmail");
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(HcpDetailsValSchema),
    defaultValues: { email: copayhcpEmail },
  });
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  // States for Password Validation
  const [passwordValidation, setPasswordValidation] = useState({
    eightCharacters: false,
    capitalLetter: false,
    oneNumber: false,
    passwordMatch: false,
  });

  useEffect(() => {
    if (password || confirmPassword) {
      validatePassword(password, confirmPassword);
    }
  }, [password, confirmPassword]);

  const validatePassword = (password, confirmPassword) => {
    if (!password && !confirmPassword) {
      setPasswordValidation(false);
      return;
    }

    setPasswordValidation({
      ...passwordValidation,
      eightCharacters: minLengthRegex.test(password) ? true : false,
      capitalLetter: capitalLetterRegex.test(password) ? true : false,
      oneNumber: numberRegex.test(password) ? true : false,
      passwordMatch: password === confirmPassword ? true : false,
    });
  };
  const HcpServiceHandle = (data) => {
    setLoading(true);
    HcpService.update(copayhcpId, data).then(
      (response) => {
        setLoading(false);

        toast.success(response.data.message);
        localStorage.setItem("copayhcpEmail", data.email);
        reset();
        setValue("email", data.email);
        setPasswordValidation({
          ...passwordValidation,
          eightCharacters: false,
          capitalLetter: false,
          oneNumber: false,
          passwordMatch: false,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);

        reset();
        setValue("email", localStorage.getItem("copayhcpEmail"));
      }
    );
  };
  const handleUpdate = (data) => {
    if (!data.password && !data.confirmPassword && data.email) {
      HcpServiceHandle(data);
    } else if (
      passwordValidation.eightCharacters === true &&
      passwordValidation.capitalLetter === true &&
      passwordValidation.oneNumber === true &&
      passwordValidation.passwordMatch === true
    ) {
      HcpServiceHandle(data);
    }
  };

  return (
    <>
      <CopayHeader />
      {loading && <Loader />}
      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-uppercase">My Details</h1>
              <p className="fs-18 text-dark-gray">
                Please check your details and update anything that has changed.
              </p>
              <div className="spacer-1"></div>
            </div>
          </div>
          <div className="row gx-3 justify-content-between">
            <div className="width-lg-53">
              <form
                className="custom-form"
                autoComplete="off"
                onSubmit={handleSubmit(handleUpdate)}
                ref={form}
              >
                <div className="row">
                  <div className="col-sm-12 mb-2">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row gx-2">
                  <div className="col-sm-6 mb-2">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      {...register("password")}
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                  </div>
                  <div className="col-sm-6 mb-2">
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      {...register("confirmPassword")}
                    />
                    {errors.confirmPassword && (
                      <p>{errors.confirmPassword.message}</p>
                    )}
                  </div>
                </div>
                <div className="spacer-1"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="mb-1 fs-18 text-dark-gray">
                      Please choose a secure password
                    </p>
                    <ul className="list-unstyled password-instructions text-dark-gray mb-0">
                      <li
                        className={
                          passwordValidation.eightCharacters ? "true" : "false"
                        }
                      >
                        At least 8 characters
                      </li>
                      <li
                        className={
                          passwordValidation.capitalLetter ? "true" : "false"
                        }
                      >
                        One capital letter
                      </li>
                      <li
                        className={
                          passwordValidation.oneNumber ? "true" : "false"
                        }
                      >
                        One number
                      </li>
                      <li
                        className={
                          passwordValidation.passwordMatch ? "true" : "false"
                        }
                      >
                        Password match
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="spacer-5"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <button className="btn btn-primary btn-default btn-block d-md-inline-block">
                      CONFIRM
                    </button>
                  </div>
                </div>
                <div className="spacer-5"></div>
              </form>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default HcpDetails;
