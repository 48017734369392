import React, { useEffect, useState } from "react";
import useModal from "../modal/useModal";
import CopayPatientHeader from "../CopayPatientHeader";
import Footer from "../Footer";
import addIcon from "../../images/add-icon.png";
import PharmacyInvoiceModal from "../modal/Patient/PharmacyInvoiceModal";
import CopayHistoryService from "../../services/CopayHistoryService";
import { toast } from "react-toastify";
import Loader from "./../../common/Loader";
import moment from 'moment';
const CoPayPatientDashboard = () => {
  const { isShowing, toggle } = useModal();
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const copayPatientId = localStorage.getItem("copayPatientId");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const copayHistoryDetailResponse =
          await CopayHistoryService.getCopayHistoryDetails(copayPatientId);
        if (copayHistoryDetailResponse.data.data.length === 0) {
          // toast.success(copayHistoryDetailResponse.data.message);
        } else {
          setInvoiceData(copayHistoryDetailResponse.data.data);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    };

    fetchData();
  }, [copayPatientId]);

  return (
    <>
      <PharmacyInvoiceModal isShowing={isShowing} hide={toggle} />

      <CopayPatientHeader />
      {loading && <Loader />}

      <main className="inner-page">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-uppercase mb-3">Co-Pay History</h1>
            </div>

            <div className="col-sm-12">
              <div className="table-padding">
                <table className="table table-bordered text-center table-style-2 text-nowrap">
                  <thead className="table-primary">
                    <tr>
                      <th className="text-white lh-sm" valign="middle">
                        Number<br/> &nbsp;
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Invoice <br/>Submitted
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Pharmacy <br/>Name
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Pharmacy <br/>Suburb
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Amount<br/> &nbsp;
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Co-Pay <br/>Due
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Co-Pay <br/>Amount
                      </th>
                      <th className="text-white lh-sm" valign="middle">
                        Co-Pay <br/>Paid
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.length !== 0 ? (
                      invoiceData.map((item, i) => {
                        return item.copayHistory !== null ? (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.copayHistory.invoiceDate && moment(item.copayHistory.invoiceDate).format('DD/MM/YYYY')}</td>
                            <td>{item.copayHistory.pharmacyName}</td>
                            <td>{item.copayHistory.pharmacySuburb}</td>
                            <td>${item.copayHistory.amount}</td>
                            <td>{item.copayHistory.copayDue ? "YES" : "NO"}</td>
                            <td>{item.copayHistory.copayAmount ? '$'+item.copayHistory.copayAmount :'N/A'}</td>
                            <td>{item.copayHistory.copayPaid ? moment(item.copayHistory.copayPaid).format('DD/MM/YYYY'):'N/A'}</td>
                          </tr>
                        ) : (
                          ""
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" valign="middle" colSpan={8}>
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-sm-12">
              <div
                className="btn-group mt-2"
                data-bs-toggle="modal"
                data-bs-target="#uploadInvoice"
              >
                <span className="add-btn rounded-2 rounded-end-0">
                  <img src={addIcon} alt="" />
                </span>
                <span
                  className="btn btn-outline-primary btn-default"
                  onClick={toggle}
                >
                  {" "}
                  Upload Pharmacy Invoice
                </span>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default CoPayPatientDashboard;
