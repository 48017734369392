import axios from "axios";

const loginGlobal = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/auth/signin-global", data);
};

const GlobalService = {
  loginGlobal,
};

export default GlobalService;
