import React from "react";
import DirectCustomersIconImg from "../../images/direct-to-customer-icon.png";
import CoPayLoginModal from "../modal/CoPayLoginModal";
import useModal from "../modal/useModal";

const DirectToConsumer = () => {
  const { isShowing, toggle } = useModal();
  return (
    <>
    <CoPayLoginModal isShowing={isShowing} hide={toggle} pageToRedirect="/direct-to-consumer" page="isDtc" />
      <div className="card bg-orange rounded-0 border-0 text-center p-4">
        <div className="entry-title">
          <img
            src={DirectCustomersIconImg}
            alt=""
            className="img-fluid entry-icon"
          />
          <h3 className="fw-bold text-white lh-1 mt-2">
            Direct to <br /> Consumer
          </h3>
        </div>
        <div className="entry-btn mt-auto">
          
          <a
            className="btn btn-outline-white btn-default fs-18 text-uppercase"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            href="#"
          >
            Enter
          </a>
        </div>
      </div>
    </>
  );
};
export default DirectToConsumer;
