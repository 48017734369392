import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import LogoInnerImg from "../images/logo-inner.svg";

const CopayPatientHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleLogout = () => {
    localStorage.removeItem("copayPatientMobile");
    localStorage.removeItem("copayHcp");
    localStorage.removeItem("copayPatientId");
    localStorage.removeItem("copayPatientToken");
  };

  return (
    <>
      <header className="inner fixed-top">
        <nav className="navbar navbar-expand-lg bg-white">
          <section className="container">
            <div className="navigation d-flex align-items-end w-100">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`offcanvas offcanvas-start border-end-0 ${
                  showMenu ? "show" : "hide"
                }`}
                tabIndex="-1"
              >
                <div className="offcanvas-header d-flex d-lg-none">
                  <img src={LogoInnerImg} className="img-fluid w-75" alt="" />
                  <button
                    type="button"
                    className="btn-close"
                    onClick={toggleMenu}
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav mb-lg-0">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/co-pay-patient-dashboard"
                            ? "active"
                            : ""
                        }`}
                        aria-current="page"
                        to="/co-pay-patient-dashboard"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/co-pay-patient-program"
                            ? "active"
                            : ""
                        }`}
                        aria-current="page"
                        to="/co-pay-patient-program"
                      >
                        PROGRAM
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/co-pay-patient-product"
                            ? "active"
                            : ""
                        }`}
                        aria-current="page"
                        to="/co-pay-patient-product"
                      >
                        Product
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/co-pay-patient-my-details"
                            ? "active"
                            : ""
                        }`}
                        aria-current="page"
                        to="/co-pay-patient-my-details"
                      >
                        My details
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === "/co-pay-patient-contact-us"
                            ? "active"
                            : ""
                        }`}
                        aria-current="page"
                        to="/co-pay-patient-contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/" onClick={handleLogout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Link
                to="/co-pay-patient-dashboard"
                className="w-50 logo-inner ms-auto"
              >
                <img src={LogoInnerImg} className="img-fluid" alt="" />
              </Link>
            </div>
          </section>
        </nav>
      </header>
    </>
  );
};
export default CopayPatientHeader;
