import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import HcpService from "../../../services/HcpService";
import useModal from "../../modal/useModal";
import HcpThankyouModal from "./HcpThankyouModal";
import IqviaService from "../../../services/IqviaService";
import {
  stateOptions,
  minLengthRegex,
  capitalLetterRegex,
  numberRegex,
} from "../../../common/commonFun";
import { HcpSignupModalValSchema } from "../../../components/ValidationForm/validationSchema";
import { toast } from "react-toastify";
import Loader from "./../../../common/Loader";

const HcpSignupModal = ({ isShowing, hide }) => {
  const { isShowing: isShowingThankyou, toggle: toggleThankyou } = useModal();
  const solHubUser = JSON.parse(localStorage.getItem("solHubUser"));
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('Physician');
  const [suburbOptions, setSuburbOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [agencyId ] = useState(solHubUser.agencyId);
  const [solHubId ] = useState(solHubUser.id);


  let navigate = useNavigate();

    const {
    register,
    handleSubmit,
    formState: { errors }, setValue,
    reset,
    watch,
  } = useForm({ resolver: yupResolver(HcpSignupModalValSchema) });
  const selectedStateOption = watch("state");
  const selectedSuburbsOption = watch("suburb");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  // States for Password Validation
  const [passwordValidation, setPasswordValidation] = useState({
    eightCharacters: false,
    capitalLetter: false,
    oneNumber: false,
    passwordMatch: false,
  });

  const findByState = (value) => {
    setLoading(true);
    setSuburbOptions([]);
    IqviaService.findByState({ State: value ,selectedType:selectedType}).then(
      (response) => {
        setLoading(false);
        setSuburbOptions(response.data.Suburbs);
        toast.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  const findBySuburb = (value) => {
    setLoading(true);
    setNameOptions([]);
    IqviaService.findBySuburb({ City: value }).then(
      (response) => {
        setLoading(false);
        setNameOptions(response.data.Names);
        toast.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage);
      }
    );
  };
  useEffect(() => {
    if (selectedStateOption) {
      setValue('suburb', '')
      setValue('name', '')
      findByState(selectedStateOption);
    }
  }, [selectedStateOption]);

  useEffect(() => {
    if (selectedSuburbsOption) {
      findBySuburb(selectedSuburbsOption);
    }
  }, [selectedSuburbsOption]);

  useEffect(() => {
    if (password || confirmPassword) {
      validatePassword(password, confirmPassword);
    }
  }, [password, confirmPassword]);

  const validatePassword = (password, confirmPassword) => {
    if (!password && !confirmPassword) {
      setPasswordValidation(false);
      return;
    }

    setPasswordValidation({
      ...passwordValidation,
      eightCharacters: minLengthRegex.test(password) ? true : false,
      capitalLetter: capitalLetterRegex.test(password) ? true : false,
      oneNumber: numberRegex.test(password) ? true : false,
      passwordMatch: password === confirmPassword ? true : false,
    });
  };

  const handleSignup = (data) => {
    data.agencyId = agencyId;
    data.solHubId = solHubId;
    if (
      passwordValidation.eightCharacters === true &&
      passwordValidation.capitalLetter === true &&
      passwordValidation.oneNumber === true &&
      passwordValidation.passwordMatch === true
    ) {
      setLoading(true);
      HcpService.create(data).then(
        (response) => {
          setLoading(false);
          localStorage.setItem("copayhcpId", response.data.id);
          localStorage.setItem("copayhcpEmail", response.data.email);
          localStorage.setItem("copayhcpToken", response.data.copayhcpToken);
          reset();
          hide();
          navigate("/co-pay-hcp-enrol");

          
          
          //toggleThankyou();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage);
        }
      );
    }
  };

  return (
    <>
      <HcpThankyouModal isShowing={isShowingThankyou} hide={toggleThankyou} />
      <div
        className={"modal modal-lg fade" + (isShowing ? " show" : "")}
        id="login"
        tabIndex="-1"
        style={isShowing ? { display: "block" } : { display: "none" }}
        backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content border-0 rounded-0">
            {loading && <Loader />}
            <div className="modal-body">
              <div className="spacer-5"></div>
              <a
                type="button"
                className="position-absolute end-0 top-0 m-4 btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                  hide()
                }}
              ></a>
              <div className="row justify-content-center">
                <div className="col-sm-11">
                  <h2 className="fw-bold mb-3">Register</h2>

                  <form
                    autoComplete="off"
                    className="custom-form"
                    onSubmit={handleSubmit(handleSignup)}
                    ref={form}
                  >
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <p className="mb-2 text-dark-gray mb-0 lh-sm">
                          If you work at multiple clinics, choose one to be
                          automatically enrolled across all the clinics you
                          practice at.
                        </p>
                        <select
                          name="state"
                          className="form-select fs-18 text-dark-gray"
                          {...register("state")}
                        >
                          <option value="">Select State or Territory</option>
                          {stateOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.state && <span>{errors.state.message}</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <select
                          name="suburb"
                          className="form-select fs-18 text-dark-gray"
                          {...register("suburb")}
                        >
                          <option value="">Select Suburb</option>
                          {suburbOptions.length !== 0
                            ? suburbOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))
                            : ""}
                        </select>
                        {errors.suburb && <span>{errors.suburb.message}</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <select
                          name="name"
                          className="form-select fs-18 text-dark-gray"
                          {...register("name")}
                        >
                          <option value="">Select Name</option>
                          {nameOptions.length !== 0
                            ? nameOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))
                            : ""}
                        </select>
                        {errors.name && <span>{errors.name.message}</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <input
                          type="text"
                          className="form-control fs-18"
                          autoComplete="false"
                          name="email"
                          placeholder="Email"
                          {...register("email")}
                        />
                        {errors.email && <span>{errors.email.message}</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <input
                          type="text"
                          className="form-control fs-18"
                          autoComplete="false"
                          name="code"
                          placeholder="Access Code"
                          {...register("code")}
                        />
                        {errors.code && <span>{errors.code.message}</span>}
                      </div>
                    </div>
                    <div className="spacer-1"></div>
                    <div className="row gx-2">
                      <div className="col-sm-12">
                        <h4 className="fs-18 fw-bold text-dark-gray mb-2">
                          Please Set a Password
                        </h4>
                      </div>
                      <div className="col-sm-6 mb-2">
                        <input
                          type="password"
                          className="form-control fs-18"
                          name="password"
                          placeholder="Password"
                          {...register("password")}
                        />
                        {errors.password && (
                          <span>{errors.password.message}</span>
                        )}
                      </div>
                      <div className="col-sm-6 mb-2">
                        <input
                          type="password"
                          className="form-control fs-18"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          {...register("confirmPassword")}
                        />
                        {errors.confirmPassword && (
                          <span>{errors.confirmPassword.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="mb-1 fs-18 text-dark-gray">
                          Please choose a secure password
                        </p>
                        <ul className="list-unstyled password-instructions text-dark-gray">
                          <li
                            className={
                              passwordValidation.eightCharacters
                                ? "true"
                                : "false"
                            }
                          >
                            At least 8 characters
                          </li>
                          <li
                            className={
                              passwordValidation.capitalLetter
                                ? "true"
                                : "false"
                            }
                          >
                            One capital letter
                          </li>
                          <li
                            className={
                              passwordValidation.oneNumber ? "true" : "false"
                            }
                          >
                            One number
                          </li>
                          <li
                            className={
                              passwordValidation.passwordMatch
                                ? "true"
                                : "false"
                            }
                          >
                            Password match
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="spacer-1"></div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-check d-flex align-items-center">
                          <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            name="privacyAndTerm"
                            {...register("privacyAndTerm")}
                          />
                          <label
                            className="form-check-label ps-2 lh-sm text-wrap"
                            htmlFor="privacyAndTerm"
                          >
                            By ticking the box I agree to the Privacy Policy and
                            Terms and Conditions
                          </label>
                        </div>
                        {errors.privacyAndTerm && (
                          <span>{errors.privacyAndTerm.message}</span>
                        )}
                        <div className="form-check d-flex align-items-center mt-3">
                          <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            name="consent"
                            id="consent"
                            {...register("consent")}
                          />
                          <label
                            className="form-check-label ps-2 lh-sm text-wrap"
                            htmlFor="consent"
                          >
                            By ticking the box I e-Consent for electronic
                            communication
                          </label>
                        </div>
                        {errors.consent && (
                          <span>{errors.consent.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="spacer-4"></div>
                    <div className="row">
                      <div className="col-sm-12 text-center text-md-end mt-4 mt-md-0">
                        <button className="btn btn-outline-primary btn-default fs-22 btn-block d-md-inline-block">
                          Signup
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="spacer-5"></div>
            </div>
          </div>
        </div>
      </div>

      {isShowing ? <div className="modal-backdrop fade show" /> : ""}
    </>
  );
};
export default HcpSignupModal;
