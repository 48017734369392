import React from 'react'
import { Link } from 'react-router-dom'
import LogoInnerImg from "../../images/logo.svg";
import Footer from '../Footer';
const AdminChoiceComponents = () => {
  return (
    <>
      <section className="centered d-flex flex-column justify-content-center h-100">
        <header>
          <div className="spacer-5"></div>
          <section className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <img
                  src={LogoInnerImg}
                  className="w-50 img-fluid logo-main"
                  alt=""
                />
              </div>
            </div>
          </section>
        </header>
        <main className="front-page">
          <section className="container">
            <div className="spacer-12"></div>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <Link to="/solutionaryhub-admin-portal/access"><button className="btn btn-primary btn-default w-100 mb-3 py-4">Co-pay/Private Program</button></Link>
                <Link to="/solutionaryhub-admin-support-portal/access"> <button className="btn btn-blue btn-default w-100 mb-3 py-4">Patient Support Program</button></Link>
              </div>
            </div>
          </section>
        </main>
      </section>
      <Footer />
    </>
  )
}

export default AdminChoiceComponents