import authHeader from "./auth-header";
import axios from "axios";

const adminLogin = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/signin", data);
};

const adminSearchPatient = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/search-patient", data, { headers: authHeader() });
};

const adminGetPatientDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/admin/patient-details/${id}`, { headers: authHeader() });
};

const adminAddAEReport = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/add-ae-report", data, { headers: authHeader() });
};

const changePatientStatus = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/change-patient-status", data, {
    headers: authHeader(),
  });
};

const adminGetPatientAEReportHistory = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/admin/patient-ae-reports-history/${id}`, {
    headers: authHeader(),
  });
};

const adminMakePatientPayment = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/make-patient-payment", data, {
    headers: authHeader(),
  });
};

const adminSearchSupportPatient = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/search-support-patient", data, { headers: authHeader() });
};

const adminGetSupportPatientDetails = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/admin/support-patient-details/${id}`, { headers: authHeader() });
};

const changeSupportPatientStatus = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/change-support-patient-status", data, {
    headers: authHeader(),
  });
};

const adminAlliedMakePatientPayment = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/allied-make-patient-payment", data, {
    headers: authHeader(),
  });
};

const adminAddSupportAEReport = (data) => {
  return axios.post(process.env.REACT_APP_API_URL + "/api/admin/add-support-ae-report", data, { headers: authHeader() });
};

const adminGetSupportPatientAEReportHistory = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/admin/support-patient-ae-reports-history/${id}`, {
    headers: authHeader(),
  });
};

const AdminService = {
  adminLogin,
  adminSearchPatient,
  adminSearchSupportPatient,
  adminGetSupportPatientDetails,
  adminGetPatientDetails,
  adminAddAEReport,
  adminAddSupportAEReport,
  changePatientStatus,
  changeSupportPatientStatus,
  adminGetPatientAEReportHistory,
  adminMakePatientPayment,
  adminAlliedMakePatientPayment,
  adminGetSupportPatientAEReportHistory,
};

export default AdminService;
