import { Link } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import { useEffect, useState } from "react";
import AdminService from "../../../services/AdminService";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/Loader";

const AdminAEReportHistoryComponent = () => {
  const adminSearchPatientId = localStorage.getItem("adminSearchPatientId");
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [aereportdata, setAEReportData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchAEReportHistory();
  }, []);

  const fetchData = async () => {
    try {
      const response = await AdminService.adminGetPatientDetails(
        adminSearchPatientId
      );
      setPatientData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };
  const fetchAEReportHistory = async () => {
    try {
      const response = await AdminService.adminGetPatientAEReportHistory(
        adminSearchPatientId
      );
      setAEReportData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <AdminHeader />
      {loading && <Loader />}
      {patientData && (
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-md-8 col-lg-5">
                <table className="table table-borderless fs-18">
                  <thead>
                    <tr>
                      <th width="60%" className="fw-bold text-primary">
                        Patient Id
                      </th>
                      <th width="40%" className="fw-bold text-primary">
                        {patientData.id}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-dark-gray">Name:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.firstname} {patientData.lastname}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">Mobile:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.mobile}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="spacer-2"></div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-padding">
                  <table className="table table-style-2 table-bordered text-nowrap text-center">
                    <thead>
                      <tr>
                        <th>Date Received</th>
                        <th>Source</th>
                        <th>Report Number</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {aereportdata.length !== 0 ? (
                        aereportdata.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="text-center" valign="middle">
                                {item.date}
                              </td>
                              <td className="text-center" valign="middle">
                                {item.source}
                              </td>
                              <td className="text-center" valign="middle">
                                {item.reportNumber}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            className="text-center"
                            valign="middle"
                            colSpan={12}
                          >
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="spacer-6"></div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <Link
                  className="btn btn-default btn-primary"
                  to="/solutionaryhub-admin-portal/patient-overview"
                >
                  Close
                </Link>
              </div>
            </div>
          </section>
        </main>
      )}
      <Footer />
    </>
  );
};

export default AdminAEReportHistoryComponent;
