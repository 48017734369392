import { Link } from "react-router-dom";
import Footer from "../../Footer";
import AdminHeader from "../../AdminHeader";
import { useEffect, useState } from "react";
import AdminService from "../../../services/AdminService";
import CopayHistoryService from "../../../services/CopayHistoryService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import moment from 'moment'

const AdminPatientDashboardComponent = () => {
  const adminSearchPatientId = localStorage.getItem("adminSearchPatientId");
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [invoiceData, setInvoiceData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchinvoiceData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await AdminService.adminGetPatientDetails(
        adminSearchPatientId
      );
      setPatientData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };
  const fetchinvoiceData = async () => {
    try {
      const response = await CopayHistoryService.getAdminCopayHistory(
        adminSearchPatientId
      );
      setInvoiceData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };

  return (
    <>
      <>
        <AdminHeader />
        {loading && <Loader />}
        <main className="inner-page">
          <section className="container">
            <div className="row">
              <div className="col-md-8 col-lg-5">
                <table className="table table-borderless fs-18">
                  <thead>
                    <tr>
                      <th width="60%" className="fw-bold text-primary">
                        Patient Id
                      </th>
                      <th width="40%" className="fw-bold text-primary">
                        {patientData.id}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-dark-gray">Name:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.firstname} {patientData.lastname}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">Mobile:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.mobile}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="spacer-2"></div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-padding">
                  <table className="table table-style-2 table-bordered text-nowrap">
                    <thead>
                      <tr className="text-center">
                        <th>Number<br/> &nbsp;</th>
                        <th>Invoice <br/>ID</th>
                        <th>Date <br/>Received</th>
                        <th>Supply <br/>Date</th>
                        <th>Pharmacy <br/>Name</th>
                        <th>Pharmacy <br/>Suburb</th>
                        <th>Pharmacy <br/>State</th>
                        <th>Amount <br/>Paid</th>
                        <th>Co-Pay<br/> Due</th>
                        <th>Co-Pay<br/> Amount</th>
                        <th>Co-Pay<br/> Paid</th>
                        <th>Action<br/> &nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData.length !== 0 ? (
                        invoiceData.map((item, i) => {
                          return (
                            <tr key={i+1}>
                              <td className="text-end" valign="middle">
                                {i+1}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.id}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.invoiceDate && moment(item.copayHistory.invoiceDate).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.supplyDate && moment(item.copayHistory.supplyDate).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.pharmacyName}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.pharmacySuburb}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.pharmacyState}
                              </td>
                              <td className="text-end" valign="middle">
                                {/* {item.copayHistory.amount && (
                                  <span className="float-start">$</span>
                                )}{" "} */}
                                {item.copayHistory.amount && '$'+ item.copayHistory.amount}
                              </td>
                              <td className="text-end" valign="middle">
                              { item.copayHistory.copayDue ?(item.copayHistory.copayDue) && <b>Yes</b> : ((i + 1) % 3 === 0) ? <b>Yes</b> : "No"}
                                {/* {item.copayHistory.copayDue  && i == i+3? "Yes" : "No"} */}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.copayAmount ? '$'+item.copayHistory.copayAmount : ((i + 1) % 3 === 0) ? "$325" : ""}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.copayPaid&& moment(item.copayHistory.copayPaid).format('DD/MM/YYYY')}
                              </td>
                              <td className="text-end" valign="middle">
                                {item.copayHistory.isSubmitStatus === false && (
                                  <Link
                                    className="btn btn-red-maroon btn-sm btn-default"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "invoiceDetail",
                                        JSON.stringify(item)
                                      );
                                    }}
                                    to="/solutionaryhub-admin-portal/patient-invoice-entry"
                                  >
                                    Invoice {item.copayHistory.isSubmitStatus}
                                  </Link>
                                )}
                                { ((i + 1) % 3 === 0) && !item.copayHistory.copayPaid && (
                                  <>
                                    &nbsp;
                                    <Link
                                      className="btn btn-outline-primary btn-sm btn-default"
                                      to="/solutionaryhub-admin-portal/bank-details"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "invoiceDetail",
                                          JSON.stringify(item)
                                        );
                                      }}
                                    >
                                      Pay
                                    </Link>
                                  </>
                                )}
                                {item.copayHistory.isSubmitStatus &&  item.copayHistory.copayPaid && ((i + 1) % 3 === 0) && 'COMPLETE'}
                                {item.copayHistory.isSubmitStatus  && ((i + 1) % 3!== 0) && 'COMPLETE'}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            className="text-center"
                            valign="middle"
                            colSpan={12}
                          >
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="spacer-6"></div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <Link
                  className="btn btn-default btn-primary"
                  to="/solutionaryhub-admin-portal/patient-overview"
                >
                  Close
                </Link>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </>
    </>
  );
};

export default AdminPatientDashboardComponent;