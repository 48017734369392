import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./css/fonts.css";
import "./scss/main.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CoPayWelcome from "./components/CoPayComponent/CoPayWelcome";
import HcpWelcome from "./components/HcpComponent/HcpWelcome";
import HcpDetails from "./components/HcpComponent/HcpDetails";
import HcpProgram from "./components/HcpComponent/HcpProgram";
import HcpProduct from "./components/HcpComponent/HcpProduct";
import CoPayPatient from "./components/CoPayPatientComponent/CoPayPatient";
import CoPayPatientDashboard from "./components/CoPayPatientComponent/CoPayPatientDashboard";
import CoPayPatientProgram from "./components/CoPayPatientComponent/CoPayPatientProgram";
import CoPayPatientProduct from "./components/CoPayPatientComponent/CoPayPatientProduct";
import CoPayPatientMyDetails from "./components/CoPayPatientComponent/CoPayPatientMyDetails";
import CoPayPatientContactus from "./components/CoPayPatientComponent/CoPayPatientContactus";
import CoPayPatientVerifyMobile from "./components/CoPayPatientComponent/CoPayPatientVerifyMobile";
import CoPayPatientNotverifyMobile from "./components/CoPayPatientComponent/CoPayPatientNotverifyMobile";
import CoPayPatientVerifyBankDetails from "./components/CoPayPatientComponent/CoPayPatientVerifyBankDetails";
import CoPayPatientNotverifyBankDetails from "./components/CoPayPatientComponent/CoPayPatientNotverifyBankDetails";
import CoPayPatientVerifyPassword from "./components/CoPayPatientComponent/CoPayPatientVerifyPassword";
import CoPayPatientNotverifyPassword from "./components/CoPayPatientComponent/CoPayPatientNotverifyPassword";

import LandingComponent from "./components/LandingComponent";
import DirectToConsumerComponent from "./components/DirectToConsumerComponent";
import ChatBotComponent from "./components/ChatBotComponent";
import PageNotFoundComponent from "./components/PageNotFoundComponent";

/* Admin Components Starts */
import AdminLoginComponent from "./components/AdminComponent/AdminLoginComponent";
import AdminAccessComponent from "./components/AdminComponent/AdminCopay/AdminAccessComponent";
import AdminPatientOverviewComponent from "./components/AdminComponent/AdminCopay/AdminPatientOverviewComponent";
import AdminPatientDashboardComponent from "./components/AdminComponent/AdminCopay/AdminPatientDashboardComponent";
import AdminAEReportComponent from "./components/AdminComponent/AdminCopay/AdminAEReportComponent";
import AdminPatientInvoiceEntryComponent from "./components/AdminComponent/AdminCopay/AdminPatientInvoiceEntryComponent";
import AdminBankDetailsComponent from "./components/AdminComponent/AdminCopay/AdminBankDetailsComponent";
import AdminAEReportHistoryComponent from "./components/AdminComponent/AdminCopay/AdminAEReportHistoryComponent";
import AdminChoiceComponents from "./components/AdminComponent/AdminChoiceComponents";
/* Admin Components Ends */

import PrivateRoute from "./common/PrivateRoute";
import PrivateRouteHcp from "./common/PrivateRouteHcp";
import PrivateRoutePatient from "./common/PrivateRoutePatient";
import PrivateRouteAdmin from "./common/PrivateRouteAdmin";

import AuthProvider from "./common/AuthProvider"; // Import AuthContext

import { toast } from "react-toastify";
import { useToast } from "./common/ToastContext";
import TestSendSMS from "./components/TestSendSMS";
import CoPayDashboard from "./components/CoPayComponent/CoPayDashboard";
import PatientSupportWelcome from "./components/PatientSupport/PatientSupportWelcome";
import SupportHcpWelcome from "./components/SupportHcpComponent/SupportHcpWelcome";
import PrivateRouteSupportHcp from "./common/PrivateRouteSupportHcp";
import SupportHcpProduct from "./components/SupportHcpComponent/SupportHcpProduct";
import SupportHcpProgram from "./components/SupportHcpComponent/SupportHcpProgram";
import SupportHcpDetails from "./components/SupportHcpComponent/SupportHcpDetails";
import SupportPatient from "./components/SupportPatientComponent/SupportPatient";
import PrivateRouteSupportPatient from "./common/PrivateRouteSupportPatient";
import SupportPatientDashboard from "./components/SupportPatientComponent/SupportPatientDashboard";
import SupportPatientContactus from "./components/SupportPatientComponent/SupportPatientContactus";
import SupportPatientMyDetails from "./components/SupportPatientComponent/SupportPatientMyDetails";
import SupportPatientProduct from "./components/SupportPatientComponent/SupportPatientProduct";
import SupportPatientProgram from "./components/SupportPatientComponent/SupportPatientProgram";
import SupportPatientVerifyMobile from "./components/SupportPatientComponent/SupportPatientVerifyMobile";
import SupportPatientNotverifyMobile from "./components/SupportPatientComponent/SupportPatientNotverifyMobile";
import SupportPatientVerifyBankDetails from "./components/SupportPatientComponent/SupportPatientVerifyBankDetails";
import SupportPatientNotverifyBankDetails from "./components/SupportPatientComponent/SupportPatientNotverifyBankDetails";
import SupportPatientNotverifyPassword from "./components/SupportPatientComponent/SupportPatientNotverifyPassword";
import SupportPatientVerifyPassword from "./components/SupportPatientComponent/SupportPatientVerifyPassword";
import AdminSupportAccessComponent from "./components/AdminComponent/AdminSupport/AdminSupportAccessComponent";
import AdminSupportPatientOverviewComponent from "./components/AdminComponent/AdminSupport/AdminSupportPatientOverviewComponent";
import AdminSupportPatientDashboardComponent from "./components/AdminComponent/AdminSupport/AdminSupportPatientDashboardComponent";
import AdminPharmacyInvoiceEntryComponent from "./components/AdminComponent/AdminSupport/AdminPharmacyInvoiceEntryComponent";
import AdminAlliedInvoiceEntryComponent from "./components/AdminComponent/AdminSupport/AdminAlliedInvoiceEntryComponent";
import AdminAlliedBankDetailsComponent from "./components/AdminComponent/AdminSupport/AdminAlliedBankDetailsComponent";
import AdminSupportAEReportComponent from "./components/AdminComponent/AdminSupport/AdminSupportAEReportComponent";
import AdminSupportAEReportHistoryComponent from "./components/AdminComponent/AdminSupport/AdminSupportAEReportHistoryComponent";
import SamplesComponent from "./components/SamplesComponent";


function App() {
  const { toastMessage } = useToast();

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
    }
  }, [toastMessage]);

  const solHubUser = JSON.parse(localStorage.getItem("solHubUser"));

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthProvider>
        <Routes>
          <Route path="/TestSMS" element={<TestSendSMS />} />
          <Route path="/" element={<LandingComponent />} />
          <Route path="/*" element={<PageNotFoundComponent />} />

          <Route
            path="/co-pay-welcome"
            element={
              <PrivateRoute page={solHubUser && solHubUser.isCopay}>
                <CoPayWelcome />
              </PrivateRoute>
            }
          />
           <Route
            path="/samples"
            element={
              <PrivateRoute page={solHubUser && solHubUser.isSamples}>
                <SamplesComponent />
              </PrivateRoute>
            }
          />
          <Route
            path="/co-pay-dashboard"
            element={
              <PrivateRoute page={solHubUser && solHubUser.isCopay}>
                <CoPayDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/direct-to-consumer"
            element={
              <PrivateRoute page={solHubUser && solHubUser.isDtc}>
                <DirectToConsumerComponent />
              </PrivateRoute>
            }
          />
          <Route path="/chatbot" element={
            <PrivateRoute page={solHubUser && solHubUser.isChatbot}>
              <ChatBotComponent />
            </PrivateRoute>
          } />

          <Route
            path="/patient-support-welcome"
            element={
              <PrivateRoute page={solHubUser && solHubUser.isPatientSupport}>
                <PatientSupportWelcome />
              </PrivateRoute>
            }
          />
          {/* Copay HCP Components Starts */}
          <Route
            path="/co-pay-hcp-enrol"
            element={
              <PrivateRouteHcp>
                <HcpWelcome />
              </PrivateRouteHcp>
            }
          />
          <Route
            path="/co-pay-hcp-details"
            element={
              <PrivateRouteHcp>
                <HcpDetails />
              </PrivateRouteHcp>
            }
          />
          <Route
            path="/co-pay-hcp-program"
            element={
              <PrivateRouteHcp>
                <HcpProgram />
              </PrivateRouteHcp>
            }
          />
          <Route
            path="/co-pay-hcp-product"
            element={
              <PrivateRouteHcp>
                <HcpProduct />
              </PrivateRouteHcp>
            }
          />
          {/* Copay HCP Components Ends */}

          {/* Patient Support HCP Components Starts */}
          <Route
            path="/support-hcp-enrol"
            element={
              <PrivateRouteSupportHcp>
                <SupportHcpWelcome />
              </PrivateRouteSupportHcp>
            }
          />
          <Route
            path="/support-hcp-details"
            element={
              <PrivateRouteSupportHcp>
                <SupportHcpDetails />
              </PrivateRouteSupportHcp>
            }
          />
          <Route
            path="/support-hcp-program"
            element={
              <PrivateRouteSupportHcp>
                <SupportHcpProgram />
              </PrivateRouteSupportHcp>
            }
          />
          <Route
            path="/support-hcp-product"
            element={
              <PrivateRouteSupportHcp>
                <SupportHcpProduct />
              </PrivateRouteSupportHcp>
            }
          />
          {/* Patient Support HCP Components Ends */}

          {/* Patient Components Starts */}
          <Route path="/co-pay-patient/:id" element={<CoPayPatient />} />
          <Route
            path="/verify-co-pay-patient-mobile-number/:tokenId"
            element={<CoPayPatientVerifyMobile />}
          />
          <Route
            path="/not-verify-co-pay-patient-mobile-number/:tokenId"
            element={<CoPayPatientNotverifyMobile />}
          />
          <Route
            path="/verify-co-pay-patient-bank-details/:tokenId"
            element={<CoPayPatientVerifyBankDetails />}
          />
          <Route
            path="/not-verify-co-pay-patient-bank-details/:tokenId"
            element={<CoPayPatientNotverifyBankDetails />}
          />
          <Route
            path="/verify-co-pay-patient-password/:tokenId"
            element={<CoPayPatientVerifyPassword />}
          />
          <Route
            path="/not-verify-co-pay-patient-password/:tokenId"
            element={<CoPayPatientNotverifyPassword />}
          />
          <Route
            path="/co-pay-patient-dashboard"
            element={
              <PrivateRoutePatient>
                <CoPayPatientDashboard />
              </PrivateRoutePatient>
            }
          />
          <Route
            path="/co-pay-patient-program"
            element={
              <PrivateRoutePatient>
                <CoPayPatientProgram />
              </PrivateRoutePatient>
            }
          />
          <Route
            path="/co-pay-patient-product"
            element={
              <PrivateRoutePatient>
                <CoPayPatientProduct />
              </PrivateRoutePatient>
            }
          />
          <Route
            path="/co-pay-patient-my-details"
            element={
              <PrivateRoutePatient>
                <CoPayPatientMyDetails />
              </PrivateRoutePatient>
            }
          />
          <Route
            path="/co-pay-patient-contact-us"
            element={
              <PrivateRoutePatient>
                <CoPayPatientContactus />
              </PrivateRoutePatient>
            }
          />
          {/* Patient Components Ends */}

          {/* Support Patient Components Starts */}

          <Route path="/support-patient/:id" element={<SupportPatient />} />

          <Route
            path="/verify-support-patient-mobile-number/:tokenId"
            element={<SupportPatientVerifyMobile />}
          />
          <Route
            path="/not-verify-support-patient-mobile-number/:tokenId"
            element={<SupportPatientNotverifyMobile />}
          />
          <Route
            path="/verify-support-patient-bank-details/:tokenId"
            element={<SupportPatientVerifyBankDetails />}
          />
          <Route
            path="/not-verify-support-patient-bank-details/:tokenId"
            element={<SupportPatientNotverifyBankDetails />}
          />
          <Route
            path="/verify-support-patient-password/:tokenId"
            element={<SupportPatientVerifyPassword />}
          />
          <Route
            path="/not-verify-support-patient-password/:tokenId"
            element={<SupportPatientNotverifyPassword />}
          />

          <Route
            path="/support-patient-dashboard"
            element={
              <PrivateRouteSupportPatient>
                <SupportPatientDashboard />
              </PrivateRouteSupportPatient>
            }
          />
          <Route
            path="/support-patient-program"
            element={
              <PrivateRouteSupportPatient>
                <SupportPatientProgram />
              </PrivateRouteSupportPatient>
            }
          />
          <Route
            path="/support-patient-product"
            element={
              <PrivateRouteSupportPatient>
                <SupportPatientProduct />
              </PrivateRouteSupportPatient>
            }
          />
          <Route
            path="/support-patient-my-details"
            element={
              <PrivateRouteSupportPatient>
                <SupportPatientMyDetails />
              </PrivateRouteSupportPatient>
            }
          />
          <Route
            path="/support-patient-contact-us"
            element={
              <PrivateRouteSupportPatient>
                <SupportPatientContactus />
              </PrivateRouteSupportPatient>
            }
          />

          {/* Support Patient Components Ends */}

          {/* Admin Components Starts */}
          <Route
            path="/solutionaryhub-admin-portal"
            element={<AdminLoginComponent />}
          />
          <Route
            path="/solutionaryhub-admin-portal/choice"
            element={
              <PrivateRouteAdmin>
                <AdminChoiceComponents />
              </PrivateRouteAdmin>}
          />
          <Route
            path="/solutionaryhub-admin-portal/access"
            element={
              <PrivateRouteAdmin>
                <AdminAccessComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-portal/patient-overview"
            element={
              <PrivateRouteAdmin>
                <AdminPatientOverviewComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-portal/patient-dashboard"
            element={
              <PrivateRouteAdmin>
                <AdminPatientDashboardComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-portal/ae-report"
            element={
              <PrivateRouteAdmin>
                <AdminAEReportComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-portal/ae-report-history"
            element={
              <PrivateRouteAdmin>
                <AdminAEReportHistoryComponent />
              </PrivateRouteAdmin>
            }
          />

          <Route
            path="/solutionaryhub-admin-portal/patient-invoice-entry"
            element={
              <PrivateRouteAdmin>
                <AdminPatientInvoiceEntryComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-portal/bank-details"
            element={
              <PrivateRouteAdmin>
                <AdminBankDetailsComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-support-portal"
            element={<AdminLoginComponent />}
          />
            <Route
            path="/solutionaryhub-admin-support-portal/access"
            element={
              <PrivateRouteAdmin>
                <AdminSupportAccessComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-support-portal/patient-overview"
            element={
              <PrivateRouteAdmin>
                <AdminSupportPatientOverviewComponent />
              </PrivateRouteAdmin>
            }
          />
           <Route
            path="/solutionaryhub-admin-support-portal/patient-dashboard"
            element={
              <PrivateRouteAdmin>
                <AdminSupportPatientDashboardComponent />
              </PrivateRouteAdmin>
            }
          />
           <Route
            path="/solutionaryhub-admin-support-portal/pharmacy-invoice-entry"
            element={
              <PrivateRouteAdmin>
                <AdminPharmacyInvoiceEntryComponent />
              </PrivateRouteAdmin>
            }
          />
           <Route
            path="/solutionaryhub-admin-support-portal/allied-invoice-entry"
            element={
              <PrivateRouteAdmin>
                <AdminAlliedInvoiceEntryComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-support-portal/allied-bank-details"
            element={
              <PrivateRouteAdmin>
                <AdminAlliedBankDetailsComponent />
              </PrivateRouteAdmin>
            }
          />
           <Route
            path="/solutionaryhub-admin-support-portal/ae-report"
            element={
              <PrivateRouteAdmin>
                <AdminSupportAEReportComponent />
              </PrivateRouteAdmin>
            }
          />
          <Route
            path="/solutionaryhub-admin-support-portal/ae-report-history"
            element={
              <PrivateRouteAdmin>
                <AdminSupportAEReportHistoryComponent />
              </PrivateRouteAdmin>
            }
          />
        </Routes>
        {/* Admin Components Ends */}
      </AuthProvider>
    </>
  );
}

export default App;
