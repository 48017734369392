export default function authHeader() {
  const tokens = [
    "accessToken",
    "copayPatientToken",
    "copayhcpToken",
    "adminAccessToken",
  ];

  for (let i = 0; i < tokens.length; i++) {
    if (localStorage.getItem(tokens[i])) {
      const token = localStorage.getItem(tokens[i]);
      if (token) {
        return { Authorization: "Bearer " + token };
      } else {
        return {};
      }
    }
  }
}
