import React from 'react'
import Header from './Header'
import Footer from './Footer'
import IconEmailSent from "../images/icon-email-sent.png";
import IconEmailOpenRate from "../images/icon-email-open-rate.png"
import IconEmailOpen from "../images/icon-email-open.png"
import IconRepVisit from "../images/icon-representative-visit.png"
import IconIniPackOrder from "../images/icon-inititiation-pack-order.png"
import IconMeth from "../images/icon-methodology.png"
import ImgOurMeth from "../images/img-our-methodology.png"
import IconKeyMet from "../images/icon-keymetrics.png"
import ImgKeyMet from '../images/img-keymetrics.png';

const SamplesComponent = () => {
    return (
        <>
            <Header />{" "}
            <main class="inner-page">
                <section class="container">
                    <div class="row justify-content-center">
                        <div class="width-lg-95 text-center">
                            <h1 class="text-uppercase">Delivering Representative and Medical Education</h1>
                            <p class="fs-20 lh-sm">Our agile customised omnichannel solution has created ongoing engagement <span class="fw-bold">over two years</span>  with a specialty group (650) <br></br> who are notoriously difficult to access. </p>
                            <div class="spacer-4"></div>
                        </div>
                    </div>
                    <div class="row justify-content-center gx-2">
                        <div class="col-md-6 col-lg-4">
                            <div class="entry-section bg-purple rounded-pill d-flex align-items-center justify-content-center mh-128 mb-4">
                                <div class="entry-icon px-2">
                                    <img src={IconEmailSent} class="img-fluid me-2" alt=""/>
                                </div>
                                <div class="entry-item px-2">
                                    <h2 class="mb-0 fw-bold fs-40 text-white lh-1">5,872</h2>
                                    <p class="mb-0 fs-20 fw-bold text-white lh-1">emails sent</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="entry-section bg-purple rounded-pill d-flex align-items-center justify-content-center mh-128 mb-4">
                                <div class="entry-icon px-2">
                                    <img src={IconEmailOpenRate} class="img-fluid me-2" alt=""/>
                                </div>
                                <div class="entry-item px-2">
                                    <h2 class="mb-0 fw-bold fs-40 text-white lh-1">&gt;60.2%</h2>
                                    <p class="mb-0 fs-20 fw-bold text-white lh-1">email open rate</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="entry-section bg-purple rounded-pill d-flex align-items-center justify-content-center mh-128 mb-4">
                                <div class="entry-icon px-2">
                                    <img src={IconEmailOpen} class="img-fluid me-2" alt=""/>
                                </div>
                                <div class="entry-item px-2">
                                    <h2 class="mb-0 fw-bold fs-40 text-white lh-1">&gt;3,536</h2>
                                    <p class="mb-0 fs-20 fw-bold text-white lh-1">emails opened</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="entry-section bg-purple rounded-pill d-flex align-items-center justify-content-center mh-128 mb-4">
                                <div class="entry-icon px-2">
                                    <img src={IconRepVisit} class="img-fluid me-2" alt=""/>
                                </div>
                                <div class="entry-item px-2">
                                    <h2 class="mb-0 fw-bold fs-40 text-white lh-1">274</h2>
                                    <p class="mb-0 fs-20 fw-bold text-white lh-1">requests for <br></br>representative visits</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="entry-section bg-purple rounded-pill d-flex align-items-center justify-content-center mh-128 mb-4">
                                <div class="entry-icon px-2">
                                    <img src={IconIniPackOrder} class="img-fluid me-2" alt=""/>
                                </div>
                                <div class="entry-item px-2">
                                    <h2 class="mb-0 fw-bold fs-40 text-white lh-1">249</h2>
                                    <p class="mb-0 fs-20 fw-bold text-white lh-1">initiation pack <br></br>orders</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spacer-11"></div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="testimonial py-5 rounded-5">
                                <div class="row justify-content-center">
                                    <div class="col-10">
                                        <div class="entry-content text-center">
                                            <p class="text-white fs-20 fw-bold fst-italic lh-sm">Solutionary Health exceeded expectations by delivering an exceptional bespoke website that integrated omnichannel features with our CRM and created an effective hub for customer engagement and brand experience. Their solutions-focused mindset, prompt communication, technical expertise and testing rigor enabled the success of the project. I would without hesitation recommend Solutionary Health for their partnership approach, digital capabilities, and vested interest in client success.</p>
                                        </div>
                                        <div class="spacer-1"></div>
                                        <div class="entry-info text-center">
                                            <p class="mb-0 text-white fw-bold lh-sm">Product Manager Top 15 Biopharmaceutical Company</p>
                                            <p class="mb-0 text-white lh-sm">Agile Patient and HCP Custom-Built Omnichannel Hub</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spacer-11"></div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                                <div class="entry-icon">
                                    <img src={IconMeth} class="img-fluid" alt=""/>
                                </div>
                                <div class="entry-content ps-lg-3">
                                    <div class="entry-title text-center text-lg-start mt-3">
                                        <h2 class="fw-bold text-purple lh-sm">Our methodology </h2>
                                        <p class="fs-20 lh-sm">Mapping engagements and communications across the customer journey focused on engineering representative access to leverage brand engagement.  </p>
                                    </div>
                                    <div class="spacer-3"></div>
                                    <div class="entry-image">
                                        <img src={ImgOurMeth} class="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <hr class="border-2 my-5" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="entry-section d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                                <div class="entry-icon">
                                    <img src={IconKeyMet} class="img-fluid" alt=""/>
                                </div>
                                <div class="entry-content ps-lg-3">
                                    <div class="entry-title text-center text-lg-start mt-3">
                                        <h2 class="fw-bold text-purple lh-sm">Key metrics power our omnichannel solutions </h2>
                                    </div>
                                    <div class="spacer-3"></div>
                                    <div class="entry-image webiste-campaign">
                                        <img src={ImgKeyMet} class="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-5"></div>
                        </div>
                    </div>
                    <div class="spacer-11"></div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="testimonial py-5 rounded-5">
                                <div class="row justify-content-center">
                                    <div class="col-10">
                                        <div class="entry-content text-center">
                                            <p class="text-white fs-20 fw-bold fst-italic lh-sm">Solutionary Health has honestly created a best-practice product and provided services that have made me look like a high achiever in my company… 100% would recommend.</p>
                                        </div>
                                        <div class="spacer-1"></div>
                                        <div class="entry-info text-center">
                                            <p class="mb-0 text-white fw-bold lh-sm">Product Manager Top 15 Biopharmaceutical Company</p>
                                            <p class="mb-0 text-white lh-sm">Standards of Excellence</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spacer-11"></div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export default SamplesComponent