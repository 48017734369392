import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import AdminService from "../../../services/AdminService";
import AdminHeader from "../../AdminHeader";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../common/commonFun";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

const AdminPatientOverviewComponent = () => {
  const adminSearchPatientId = localStorage.getItem("adminSearchPatientId");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [patientData, setPatientData] = useState({});

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await AdminService.adminGetPatientDetails(
        adminSearchPatientId
      );
      setPatientData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/solutionaryhub-admin-portal");
    }
  };
  const handleCheckboxChange = (e, field) => {
    setLoading(true);
    if (field === "sms") {
      patientData.smsEnabled = e.target.checked;
    } else {
      patientData.isActive = e.target.checked ? false : true;
    }
    var data = {
      patientId: adminSearchPatientId,
      field: field,
      status: field === "sms" ? e.target.checked : e.target.checked ? false : true
    };
        AdminService.changePatientStatus(data).then(
      (response) => {
        setLoading(false);
        toast.success(response.data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <AdminHeader />
      {loading && <Loader />}
      {patientData && patientData.hcp ? (
        <main className="inner-page">
          <section className="container">
            <div className="row justify-content-between">
              <div className="col-lg-5 d-flex flex-column mb-5 mb-lg-0">
                <table className="table table-borderless fs-18">
                  <thead>
                    <tr>
                      <th width="60%" className="fw-bold text-primary">
                        Patient Id
                      </th>
                      <th width="40%" className="fw-bold text-primary">
                        {patientData.id}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-dark-gray">Register Date:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.createdAt}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">Name:</td>
                      <td className="text-dark-gray fw-bold">
                        {capitalizeFirstLetter(patientData.firstname)}{" "}
                        {capitalizeFirstLetter(patientData.lastname)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">Mobile:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.mobile}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">DOB:</td>
                      <td className="text-dark-gray fw-bold">
                        {patientData.dob}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-dark-gray">Gender:</td>
                      <td className="text-dark-gray fw-bold">
                        {capitalizeFirstLetter(patientData.gender)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="spacer-3"></div>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <Link
                      className="btn btn-primary btn-default btn-block"
                      to="/solutionaryhub-admin-portal/patient-dashboard"
                    >
                      Dashboard
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 d-flex flex-column">
                <div className="row">
                  <div className="col-auto col-sm-4 mb-2">
                    <p className="mb-0 fs-18 text-dark-gray">Dr:</p>
                  </div>
                  <div className="col-auto col-sm-8 mb-2">
                    <p className="mb-0 fs-18 text-dark-gray fw-bold">
                      {patientData.hcp.name}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto col-sm-4 mb-2">
                    <p className="mb-0 fs-18 text-dark-gray">
                      State / Territory:
                    </p>
                  </div>
                  <div className="col-auto col-sm-8 mb-2">
                    <p className="mb-0 fs-18 text-dark-gray fw-bold">
                      {patientData.hcp.state}
                    </p>
                  </div>
                </div>
                <div className="spacer-3"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-check custom-form-check d-flex align-items-center mb-3">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        name="sms"
                        id="sms"
                        defaultChecked={patientData.smsEnabled}
                        //checked={patientData.smsEnabled}
                        onChange={(e) => handleCheckboxChange(e, "sms")}
                      />
                      <label
                        className="form-check-label ps-2"
                        htmlFor="smsEnabled"
                      >
                        Receive SMS Notifications?
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-check custom-form-check d-flex align-items-center mb-3">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        name="enroll"
                        id="enroll"
                        defaultChecked={patientData.isActive?false:true}
                        //checked={patientData.isActive}
                        onChange={(e) => handleCheckboxChange(e, "enroll")}
                      />
                      <label
                        className="form-check-label ps-2 text-dark-gray lh-1 fs-18 text-dark-gray"
                        htmlFor="isActive"
                      >
                        I no longer wish to be enroled
                      </label>
                    </div>
                  </div>
                </div>
                <div className="spacer-3"></div>
                <div className="row justify-content-center mt-auto">
                  <div className="col-md-6 mb-2">
                    <Link
                      className="btn btn-primary btn-default btn-block"
                      to="/solutionaryhub-admin-portal/ae-report"
                    >
                      Enter AE
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <Link
                      className="btn btn-primary btn-default btn-block"
                      to="/solutionaryhub-admin-portal/ae-report-history"
                    >
                      History
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer-6"></div>
            <div className="row justify-content-between">
              <div className="col-lg-10 d-flex flex-column mb-5 mb-lg-0">
                <div className="row justify-content-center">
                  <div className="col-md-3">
                    <Link
                      className="btn btn-outline-gray btn-default btn-block"
                      to="/solutionaryhub-admin-portal/access"
                    >
                      Close
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        ""
      )}

      <Footer />
    </>
  );
};

export default AdminPatientOverviewComponent;
